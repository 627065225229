import styled from 'styled-components';

const Container = styled.div`
    max-width: 600px;
    width: 85vw;
    margin: 1em 0 5em;
    @media only screen and (max-width: 375px) {
        width: 100%;
    }
`;

const Tabs = styled.div`
    margin-bottom: 2.5em;
`;

Tabs.Tab = styled.button`
    border: none;
    font-family: 'Roboto',sans-serif;
    font-weight: 500;
    font-size: 0.75em;
    border-radius: 3px;
    min-width: 44px;
    margin-right: 1em;
    padding: 0.2em 0.5em;
    color: ${props => (props.active ? '#3D7BF2' : '#B8B8B8')};
    background-color: ${props => (props.active ? '#F1F1F1' : 'transparent')};
    @media only screen and (max-width: 375px) {
        margin-right: 0.5em;
    }
`;

export { Container, Tabs }