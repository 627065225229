import styled from 'styled-components';
import { Main } from '../containers';
import { TextOpenSans } from '../elements';

const Footer = styled.footer`
  background-color: #252525;
  color: #CAC7C7;
  font-family: 'Roboto',sans-serif;
  font-size: 1.125em;
  img: {
    margin-bottom: 1em;
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.875em;
    padding-top: 8%;
  }
`;

Footer.Inner = styled(Main.Inner)`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 6% 0 9%;
`;

Footer.Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 5%;
  min-width: 470px;
  @media only screen and (max-width: 600px) {
    min-width: 100%;
  }
`;

Footer.Nav = styled.div`
  display: flex;
  margin-bottom: 3rem;
  justify-content: space-between;
  a {
    display: block;
    padding: 0.125em 0;
  }
  @media only screen and (max-width: 600px) {
    a {
      font-size: 1.125em;
      padding: 0.25em 0;
    }
  }
  @media only screen and (max-width: 375px) {
    flex-direction: column;
  }
`;

Footer.Text = styled(TextOpenSans)`
  color: #fff;
  margin-top: 1em;
  margin-bottom: 2rem;
  max-width: 400px;
  @media only screen and (max-width: 1023px) {
    max-width: 100%;
  }
`;

Footer.Title = styled.p`
  font-weight: 700;
  color: #FFF;
  @media only screen and (max-width: 1023px) {
    margin-bottom: 0.5em;
    margin-top: 1em;
  }
`;

Footer.Note = styled.p`
color: #8d8d8d;
a:hover {
  text-decoration: underline;
}
`;

export default Footer;
