import React from 'react';
import { useLocation } from 'react-router-dom';
import { Search } from './style';

const SearchOptions = ({ results, handleSubmit }) => {
    const path = useLocation().pathname;

    return (
        <Search.Options>

            {results.slice(0, 4).map(option => (
                <li key={option.symbol}>
                    <Search.Options.Link to={`${path}/${option.symbol}`}>
                        {option.name}
                        <span> {option.symbol}</span>
                    </Search.Options.Link>
                </li>
            ))}

            <Search.ViewAll>
                <button onClick={handleSubmit}>
                    View All Results
                </button>
            </Search.ViewAll>
          
        </Search.Options>
    )
}

export default SearchOptions
