import React from 'react';
import { Info, Input } from '../../components';
import { ReportCard as Styled } from './style';

const ReportCard = ({ label, info, value, grade, explanation, name }) => {

  return (
    <Styled>
      <Styled.LabelContainer>
        <Styled.Label htmlFor={name}>{label}</Styled.Label>
        <Info info={info} />
      </Styled.LabelContainer>
      <Styled.Row>
        <Input label={label} name={name} value={value} readOnly />
        <Styled.Grade className={grade ? grade.toLowerCase() : 'no-grade'}>{grade}</Styled.Grade>
      </Styled.Row>
      {explanation && <p>{explanation}</p>}
    </Styled>
  );
};

export default ReportCard;
