import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    width: 100%;
    > :first-child {
        max-width: 510px;
        margin-right: 5%;
    }
    > :last-child {
        width: calc(95% - 510px);
    }
    @media only screen and (max-width: 1366px) {
        flex-direction: column;
        > :last-child {
            width: auto;
            max-width: 800px;
            margin-top: 30px;
        }
    }
`;


const GraphContainer = styled.div`
    label {
        color: transparent;
        height: 1px;
        width: 1px;
        display: inline;
    }
`;

GraphContainer.Note = styled.p`
    margin-top: 3em;
    font-size: 0.875em;
    &:before {
        content: '* ';
        color: #75FFBC;
    }
`;

export { Container, GraphContainer };