import React from 'react';
import { H2, TextOpenSans } from '../../components/elements';
import Home from './style';
import {
  background,
  accurate,
  effective,
  convenient,
} from '../../assets/images';

const Section2 = () => (
  <Home.Section2 img={background} center>
    <Home.Inner>
      <Home.TextWrapper>
        <H2 white>Why Valuatr?</H2>
        <TextOpenSans white>
          Valuatr uses standardized valuation models to provide you with easy,
          timely, and accurate valuations of businesses and stocks. Make informed
          investment decisions without having to learn complex models.
        </TextOpenSans>
      </Home.TextWrapper>
      <Home.Blocks>
        <Home.Block>
          <img src={accurate} alt="" />
          <Home.Blocks.Title>Accurate</Home.Blocks.Title>
          <TextOpenSans small>
            We use detailed financial models to accurately generate enterprise
            value for companies and intrinsic value of shares.
          </TextOpenSans>
        </Home.Block>
        <Home.Block>
          <img src={effective} alt="" />
          <Home.Blocks.Title>Effective</Home.Blocks.Title>
          <TextOpenSans small>
            We guide retail investors to make highly informed financial
            decisions by providing precise valuation techniques and investment
            support.
          </TextOpenSans>
        </Home.Block>
        <Home.Block>
          <img src={convenient} alt="" />
          <Home.Blocks.Title>Convenient</Home.Blocks.Title>
          <TextOpenSans small>
            Our self-input assisted valuation framework is incredibly user
            friendly. All it takes is a few clicks!
          </TextOpenSans>
        </Home.Block>
      </Home.Blocks>
    </Home.Inner>
  </Home.Section2>
);

export default Section2;
