import styled from 'styled-components';

const Card = styled.div`
  width: 32%;
  margin-right: 2%;
  min-width: 220px;
  max-width: 360px;
  border-radius: 12px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  text-align: center;
  overflow: hidden;
  background-color: #fff;
  &:last-of-type {
    margin-right: 0;
  }
  @media only screen and (max-width: 800px) {
    width: 48%;
    min-width: 250px;
    max-width: 300px;
    margin-right: 0;
    margin-bottom: 2em;
    &:first-of-type {
      margin-right: 4%;
    }
  }
`;

Card.Text = styled.div`
  padding: 8% 10% 5%;
`;

Card.Title = styled.h2`
  font-size: 1em;
  color: #152536;
  font-weight: 500;
  margin-bottom: 0.75em;
`;

Card.Container = styled.div`
  display: flex;
  @media only screen and (max-width: 800px) {
    padding-top: 1em;
    flex-wrap: wrap;
    justify-content: center;
  }
  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;

Card.Image = styled.div`
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 45%;
  background-position: center;
`;

export default Card;
