import React from 'react'
import GraphSchema from './GraphSchema';
import { optionsDefault, tooltipsDefault, scalesDefault, datasetsDefault } from '../../../utils/graphs';

const options = {
    ...optionsDefault,
    tooltips: tooltipsDefault,
    scales: {
        ...scalesDefault,
        xAxes: [
            {
                ...scalesDefault.xAxes[0],
                ticks: {
                    ...scalesDefault.xAxes[0].ticks,
                    callback: function (val, index) {
                        const array = val.split(' ');
                        return index > 0 ? `${array[0]} ${array[1]}` : '';
                    }
                }
            }
        ]
    }
}

const Graph1month = ({ data, setData }) => (

    <GraphSchema
        api='prices-daily?months=1'
        label='1 Month'
        data={data}
        setData={setData}
        options={options}
        datasets={datasetsDefault}
    />
)

export default Graph1month
