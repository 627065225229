import React from 'react';
import { SimpleFormSchema } from '../../';
import { schema } from './schema';

const AccountSettings = () => (

    <SimpleFormSchema schema={schema} />
);

export default AccountSettings;
