import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import {
  CompaniesContextProvider,
  FinancialsContextProvider,
} from '../../context';
import { Aside, SearchCompanies, DashboardHeader } from '../../components';
import FinancialsCompany from '../financialsCompany/FinancialsCompany';
import { DashboardMain as Main } from '../../components/containers';

const Financials = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Aside />
      <DashboardHeader title="Financials" />
      <Main>
        <Main.Inner>
          <Main.Title>Financials</Main.Title>
        </Main.Inner>
        <Main.Inner narrow>
        <CompaniesContextProvider>
          <FinancialsContextProvider>
            <Switch>
              <Route exact path={path}>
                <SearchCompanies model='Financials' />
              </Route>
              <Route path={`${path}/:symbol`} component={FinancialsCompany} />
            </Switch>
          </FinancialsContextProvider>
        </CompaniesContextProvider>
        </Main.Inner>
      </Main>
    </>
  );
};

export default Financials;
