import React from 'react'

const Expand = () => (

    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Expand</title>
        <path d="M7.5 1.5H10.5V4.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.5 10.5H1.5V7.5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10.5 1.5L7 5" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M1.5 10.5L5 7" stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)


export default Expand

