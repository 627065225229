import React from 'react';
import { InputSelect } from '../../components';
import { Container } from '../../components/containers';
import { DiscountDividendModel, DiscountCashflowModel } from './SharePriceValuationModels';
import { models } from '../definitions';
import { Bold } from '../../components/elements';

const SharePriceValuation1 = ({ data, setData, handleChange }) => (

  <>
    <InputSelect
      label="Select type of model"
      name='model'
      required
      options={[models.DDM.label, models.DCM.label]}
      onChange={e => setData({ ...data, model: e.target.value })}
      value={data.model}
      placeholder="Select"
    />

    <Container top='2.5rem'>
      {data.model === models.DDM.name && (
        <DiscountDividendModel data={data} setData={setData} />
      )}
      {data.model === models.DCM.name && (
        <DiscountCashflowModel data={data} onChange={handleChange} setData={setData} />
      )}

      {!data.model && <>
        <div>
          <Bold>{models.DDM.label}</Bold>
          <p>{models.DDM.info}</p>
        </div>
        <div>
          <Bold>{models.DCM.label}</Bold>
          <p>{models.DCM.info}</p>
        </div>
      </>}
    </Container>
  </>
);

export default SharePriceValuation1;
