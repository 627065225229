import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useFinancials } from '../../../context/Financials';
import Link from '../pdfLink/Link';

const DownloadPDF = ({ pdf, statement, title }) => {
  const { reports } = useFinancials();
  const { name, symbol } = reports?.companyQuote;

  const report = {
    document: pdf,
    data: reports[statement],
    title: `${title} for ${name}, ${symbol}`
  };

  const Document = report.document;

  return (
      <PDFDownloadLink
        document={
          <Document 
            data={report.data}
            symbol={symbol}
            name={name}
          />
        }
        fileName={report.title}
      >
        {({ loading }) =>
          loading ? 'Creating pdf...' : <Link />
        }
      </PDFDownloadLink>
  );
};

export default DownloadPDF;
