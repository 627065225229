import React from 'react'

const Info = () => (
    <svg width="2" height="10" viewBox="0 0 2 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 9V5" stroke="#929292" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="1" cy="1" r="1" fill="#929292" />
    </svg>
)


export default Info



