import React, { useState } from 'react'
import { InputSearch } from '../../components';
import { useUserHistory } from '../../context';

const HistorySearch = () => {
    const { setFilterTerm } = useUserHistory();
    const [ input, setInput ] = useState('');
    
    const handleChange = e => {
        setInput(e.target.value);
        setFilterTerm(e.target.value);
    }

    const handleSubmit = e => e.preventDefault()

    return (
        <form onSubmit={handleSubmit}>
            <InputSearch onChange={handleChange} value={input} />
        </form>
    )
}

export default HistorySearch
