import styled from 'styled-components';

const Title = styled.h1`
  background-color: #EDEDED;
  width: 233px;
  padding: 3% 3% 0 5%;
  font-size: 1.75em;
  font-weight: 500;
  color: #152536;
  @media only screen and (max-width: 800px) {
    width: 180px;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  @media only screen and (max-width: 800px) {
    form {
      width: 300px;
    }
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    padding-bottom: 3.5em;
  }
`;

const Tabs = styled.div`
  background-color: #EDEDED;
  width: 233px;
  padding: 30px 3% 0 5%;
  @media only screen and (max-width: 800px) {
    width: 180px;
    padding-left: 2%;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    display: flex;
  }
`;

Tabs.Tab = styled.button`
text-align: left;
  width: 100%;
  cursor: pointer;
  font-size: 1em;
  font-weight: 400;
  color: ${props => (props.active ? '#4D4D4D' : '#7C7C7C')};
  padding: 0.5em 0.875em;
  display: block;
  border: none;
  border-radius: 8px;
  background-color: ${props => (props.active ? '#FFF' : 'transparent')};
  @media only screen and (max-width: 600px) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    text-align: center;
  }
  @media only screen and (max-width: 375px) {
    font-size: 0.875em;
  }
`;

const SignOut = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  text-decoration: underline;
  left: 6%;
  bottom: 1em;
  cursor: pointer;
  font-size: 1em;
  color: #7C7C7C;
`;

export { Title, Container, Tabs, SignOut };
