import styled, { keyframes } from 'styled-components';


const spin = keyframes`
     0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
`;

const BackDrop = styled.div`
  font-family: 'Roboto',sans-serif;
  color: #152536;
  font-size: 2em;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFF;
  opacity: 80%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    font-size: 1.25em;
  }
  @media only screen and (max-width: 600px) {
    font-size: 1em;
  }
`;

const Loader = styled.div`
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: ${spin} 2s linear infinite;
    margin-bottom: 5%;
  @media only screen and (max-width: 1024px) {
    width: 100px;
    height: 100px;
    border-width: 14px; 
  }
  @media only screen and (max-width: 600px) {
    width: 70px;
    height: 70px;
    border-width: 13px; 
  }
`;

export { BackDrop, Loader };