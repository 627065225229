import styled from 'styled-components';
import { Main } from '../../components/containers';
import { Button, TextOpenSans } from '../../components/elements';
import { lineTop, lineBottom } from '../../assets/images';

const Home = {};

Home.Inner = styled(Main.Inner)`
  padding: 5% 0;
  @media only screen and (max-width: 600px) {
    padding: 10% 0;
  }
`;

Home.Row = styled.div`
  background-color: ${props => props.blue && 'rgba(227, 231, 254, 0.2)'};
`;

Home.Button = styled(Button)`
  display: flex;
  align-items: center;
  margin-top: 2em;
  font-family: 'Roboto',sans-serif;
  font-weight: 500;
  font-size: ${props => props.white? '0.875em' : '1.25em'};
  margin-right: 1em;
  background-color: ${props => props.white && '#FFF'};
  color: ${props => props.white && '#252525'};
  svg {
    margin-left: 0.75em;
    width: ${props => props.white && '1.5em'};
  }
`;

Home.TextWrapper = styled.div`
max-width: 700px;
margin: auto;
`;
 
Home.Blocks = styled.div`
margin-top: 6%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

Home.Block = styled.div`
  background-color: #fff;
  border: 1px solid #dadada;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  display: inline-block;
  min-width: 280px;
  width: 31%;
  text-align: left;
  padding: 2.75% 4% 2%;
  @media only screen and (max-width: 1024px) {
    width: 27%;
    height: 230px;
    margin-bottom: 5%;
    img {
      width: 2.25rem;
    }
  }
`;

Home.Blocks.Title = styled.h3`
  font-size: 1.375em;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  margin: 0.5em 0 0.2em;
`;

Home.List = styled.ul`
  li {
    color: #585858;
    margin-bottom: 0.3em;
    margin-left: 1em;
  }
`;

Home.Title = styled.h2`
  font-size: 1em;
  margin-bottom: 0.25em;
  font-weight: 600;
  color: #234292;
  font-family: 'Open Sans', sans-serif;
`;

Home.Subtitle = styled(TextOpenSans)`
  @media only screen and (max-width: 800px) {
    font-size: 1.25em;
    margin-bottom: 5%;
    line-height: 1.4;
  }
`;

Home.TextContainer = styled.div`
  max-width: ${props => props.width? props.width + 'px' : '460px'};
  width: ${props => props.width && '70%'};
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

Home.Banner = styled(Main.SectionBg)`
padding-top: 6vw!important;
  background-size: 43%;
  min-height: 39vw;
  background-position: 105% -7vw;
  @media only screen and (max-width: 1024px) {
    padding-top: 5rem!important;
    padding-bottom: 5vw;
    background-size: 40%;
    background-position: 115% 50%;
  }
  @media only screen and (max-width: 600px) {
    background-position: 115% 90%;
  }
`;

Home.Section3 = styled(Main.SectionBg)`
  background-image: url(${lineTop}), url(${lineBottom});
  background-position: 0px -60%, bottom right;
  margin-bottom: 3%;
  background-size: 100%;
`;

Home.Section2 = styled(Main.SectionBg)`
@media only screen and (max-width: 600px) {
  h2 + p {
    font-size: 1.125em;
    margin-bottom: 2em;
  }
}
`;

export default Home;
