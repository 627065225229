import React from 'react';
import { Tabs } from '../containers';

const TabsPublicInv = ({ option }) => {
  const tabs = {
    'public-investments': [
      'Share Price Valuation',
      'Stock Option Valuation',
      'Financial Strength',
    ],
    'business-valuation': ['Public Companies', 'Private Companies'],
  };

  return (
    <Tabs.Wrapper>
      {tabs[option].map(tab => {
        const route = tab.replaceAll(' ', '-').toLowerCase();
        return (
          <li key={route}>
            <h2>
              <Tabs.Link to={`/dashboard/${option}/${route}`}>{tab}</Tabs.Link>
            </h2>
          </li>
        );
      })}
    </Tabs.Wrapper>
  );
};

export default TabsPublicInv;
