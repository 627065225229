import { noop } from '../../utils';
import ProjectFinance1 from './ProjectFinance1';
import ProjectFinance2 from './ProjectFinance2';
import ProjectFinance3 from './ProjectFinance3';

export const schema = {
  page1: {
    component: ProjectFinance1,
    getPrevious: noop,
    getNext: () => 'page2',
    nextButton: 'Next',
  },
  // page2: {
  //   component: ProjectFinance2,
  //   getPrevious: () => 'page1',
  //   getNext: () => 'page3',
  //   nextButton: 'Calculate WACC',
  //   onNext: async (formData, setFormData, requestHandler, RequestMethods) => {
  //     try {
  //       const { data } = await requestHandler(
  //         RequestMethods.Post,
  //         '/private-company-wacc',
  //         formData
  //       );
  //       console.log(data)
  //       setFormData({ ...formData, ...data });
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   },
  //},
  // page3: {
  //   component: ProjectFinance3,
  //   getPrevious: () => 'page2',
  //   getNext: () => 'page4',
  //   nextButton: 'Next',
  // },
  page2: {
    component: ProjectFinance2,
    getPrevious: () => 'page1',
    getNext: () => 'page3',
    onNext: async (formData, setFormData, requestHandler, RequestMethods) => {
      try {
        const response = await requestHandler(
            RequestMethods.Post,
            '/project-finance',
          formData
        );
        setFormData({ ...formData, ...response?.data });
      } catch (error) {
        console.error(error);
      }
    },
    nextButton: 'Generate Report',
  },
  page3: {
    component: ProjectFinance3,
    getPrevious: () => 'page2',
    getNext: noop,
    nextButton: 'Done',
  },
  hook: 'useProjectFinance',
  history: '',
};
