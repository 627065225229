import React from 'react';
import { createId } from '../../utils';
import { Required } from '../elements';
import Select from './style'

const InputSelect = ({ defaultValue, name, label, options, ...rest }) => {
  const id = name || createId(label);

  return (
    <div>
      <Select.Label htmlFor={id}>
        {label}
        {rest.required && <Required />}
      </Select.Label>
      <Select id={id} name={id} {...rest}>
        {!defaultValue &&
          <option value="" disabled key="default">
            Select...
          </option>
        }
        {options.map(option => {
          const optionValue = createId(option);
          return (
            <option key={optionValue} value={optionValue}>
              {option}
            </option>
          );
        })}
      </Select>
    </div>
  );
};

export default InputSelect;
