import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as context from '../../context';
import { BackNextContainer } from '../';
import { useRequest, RequestMethods } from '../../utils';
import Loading from '../../hoc/loading/Loading';

const FormSchema = ({ setLoading, schema }) => {
  const requestHandler = useRequest();
  const history = useHistory();
  const useContextHook = context[schema.hook];
  const { data, setData } = useContextHook();
  const [activePage, setActivePage] = useState(schema.page1);

  const ActivePage = activePage?.component;

  const handleChange = e =>
    setData({ ...data, [e.target.name]: e.target.value });

  const handleFieldSet = e =>
    setData({
      ...data,
      [e.target.name]: [...data[e.target.name], e.target.value],
    });

  const handleCheckbox = e =>
    setData({ ...data, [e.target.name]: e.target.checked });

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    if (activePage.onNext) {
      await activePage.onNext(
        data, setData, requestHandler, RequestMethods
      );
    }

    setLoading(false);
    const nextPage = activePage.getNext(data);

    setActivePage(schema[nextPage]);
  };

  const handleBack = _ =>
    activePage.getPrevious()
      ? setActivePage(schema[activePage.getPrevious()])
      : history.push(`/dashboard${schema.history}`);

  return activePage.getNext() ? (
    <form onSubmit={handleSubmit}>
      <ActivePage
        data={data}
        setData={setData}
        handleChange={handleChange}
        handleFieldSet={handleFieldSet}
        handleCheckbox={handleCheckbox}
      />
      <BackNextContainer
        next={activePage.nextButton}
        disableNext={data.disableNext}
        handleBack={handleBack}
      />
    </form>
  ) : (
    <div>
      <ActivePage data={data} setData={setData} />
      <BackNextContainer
        next='Done'
        handleBack={handleBack}
        handleNext={() => history.push(`/dashboard${schema.history}`)}
      />
    </div>
  );
};

export default Loading(FormSchema, `Loading company's reports...`);
