import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { DashboardMain } from '../containers';

const HeaderDesktop = styled.header`
  background-color: ${props => (props.white ? '#FBFBFB' : '#F5F5F5')};
  box-shadow: ${props => props.white && '0px 1px 3px rgba(0, 0, 0, 0.15)'};
  margin-left: 15vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 6rem;
  position: fixed;
  width: 85vw;
  z-index: 30;
  text-transform: capitalize;
  .account {
    cursor: pointer;
    border: none;
    border-radius: 100%;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    span {
      width: 1px;
      height: 1px;
      color: transparent;
      position: absolute;
      overflow: hidden;
    }
  }
  @media only screen and (max-width: 1024px) {
      display: none;
  }
`;

HeaderDesktop.Inner = styled.div`
  padding-left: 5vw;
  padding-right: 5vw;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

HeaderDesktop.Link = styled(Link)`
  position: absolute;
  width: 44px;
  height: 44px;
  left: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderMobile = styled.header`
  font-family: 'Roboto', sans-serif;
  background-color: #376EDA;
  position: fixed;
  z-index: 90;
  width: 100%;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  @media only screen and (min-width: 1025px) {
    display: none;
  }
  > div > button {
    display: block;
  }
  @media only screen and (max-width: 375px) {
    font-size: 0.875em;
  }
`;

HeaderMobile.Inner = styled(DashboardMain.Inner)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  > button span {
    background-color: #fff;
    border-color: #fff;
  }
`;

HeaderMobile.NavList = styled.ul`
  min-width: 75vw;
`;

HeaderMobile.NavItem = styled.li`  
  font-size: 1em;
  color: #656565;
  > :first-child {
    display: flex;
    padding: 1em 1.5em;
    width: 100%;
  }
  svg {
    margin-right: 1em;
    padding: 0.1em;
  }
  path {
    stroke: #656565;
  }
  button {
    border: none;
    background-color: transparent;
    font-size: 1em;
    cursor: pointer;
    svg {
      display: none;
    }
  }
`;

HeaderMobile.Line = styled.hr`
  margin-top: ${props => !props.noTopMargin && '2em' };
  margin-bottom: 1em;
`;

export { HeaderDesktop, HeaderMobile };
