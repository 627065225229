import styled from 'styled-components';

const Input = styled.input`
  display: block;
  padding: 0 0.75em;
  width: 100%;
  text-align: ${props => props.type === 'number' && 'right'}
`;

Input.Container = styled.div`
  position: relative;
  width: 100%;
  text-align: left;
  margin-bottom: 1em;
`;

Input.Label = styled.label`
  font-size: 1em;
  color: #4d4d4d;
  font-weight: 500;
  display: inline-block;
  padding-bottom: 0.3em;
`;

export default Input;
