import React from 'react';
import { Input } from '../../components';
import { FieldsContainer as Container } from '../../components/containers';
import { info } from '../definitions';

const SharePriceValuation2 = ({ data }) => (

  <Container>
    <Input {...info.betaRisk} value={data.betaRisk} readOnly />
    <Input {...info.riskFreeRate} value={data.riskFreeRate} readOnly />
    <Input {...info.marketReturn} value={data.marketReturn} readOnly />
    <Input {...info.weightOfEquity} value={data.weightOfEquity} readOnly />
    <Input {...info.weightOfDebt}  value={data.weightOfDebt} readOnly />
    <Input {...info.costOfEquity} value={data.costOfEquity} readOnly />
    <Input {...info.costOfDebt} value={data.costOfDebt} readOnly />
    <Input {...info.taxRate} value={data.taxRate} readOnly />
    <Input {...info.WACC} value={data.WACC} readOnly />
  </Container>
);

export default SharePriceValuation2;
