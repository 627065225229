import React from 'react';
import { Input, InputCheckbox } from '../../components';
import { Container, Card } from '../login/style';

const FormFields = ({ handleChange, formData }) => {

  const label = <>
    I accept to the&nbsp;
    <Card.Link to="/terms-and-conditions">Terms and Conditions</Card.Link>
    , and agree to the&nbsp;
    <Card.Link to="/privacy-policy">Privacy Policy</Card.Link>
  </>

  return <>

    <Container>
      <Input
        type="text"
        name="name"
        label="Full Name"
        onChange={handleChange}
        value={formData.name}
        placeholder={null}
        required
      />
      <Input
        type="email"
        name="email"
        label="Email Address"
        onChange={handleChange}
        value={formData.email}
        placeholder={null}
        required
      />
      <Input
        type="password"
        name="password"
        label="Password"
        onChange={handleChange}
        value={formData.password}
        placeholder={null}
        required
      />
      <Input
        type="password"
        name="confirmPassword"
        label="Confirm Password"
        onChange={handleChange}
        value={formData.confirmPassword}
        placeholder={null}
        required
      />
    </Container>
    <InputCheckbox
      name="acceptTerms"
      label={label}
      onChange={handleChange}
      value={formData.rememberMe}
      required
    />
  
  </>
};

export default FormFields;
