import React from 'react';
import { NewsContextProvider } from '../../context';
import { Aside, DashboardHeader } from '../../components';
import { DashboardMain as Main } from '../../components/containers';
import NewsSearch from './NewsSearch';
import NewsList from './NewsList';
import { Section } from './style';

const News = () => <>
      
  <Aside />

  <DashboardHeader title="News" />

  <Main>
    <NewsContextProvider>

      <Main.Inner>
        <Main.Title>News</Main.Title>
      </Main.Inner>
      <Section dark center>
        <Main.Inner>
          <NewsSearch />
        </Main.Inner>
      </Section>

      <Section>
        <Main.Inner>
          <NewsList />
        </Main.Inner>
      </Section>

    </NewsContextProvider>
  </Main>

</>


export default News;
