import React, { createContext, useContext, useState, useEffect } from 'react';
import { RequestMethods, useRequest } from '../utils';

const CompaniesContext = createContext();

const useCompanies = _ => useContext(CompaniesContext);

const CompaniesContextProvider = ({ children }) => {
  const requestHandler = useRequest();
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    try {
      const getCompanies = async _ => {
        const response = await requestHandler(RequestMethods.Get, '/companies');
        setCompanies(response?.data || []);
      }
      getCompanies();
    } catch (err) {
      console.error(err);
    }
  // eslint-disable-next-line
  }, []);

  return (
    <CompaniesContext.Provider value={{ companies }}>
      {children}
    </CompaniesContext.Provider>
  );
};

export { useCompanies, CompaniesContextProvider };
