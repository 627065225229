import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { CompanyName } from '..';
import { Label } from '../elements';
import { History } from '../../assets/svgComponents';
import { List as Styled } from './style';

const List = ({ list, search, input }) => {
  const path = useLocation().pathname;

  if( list.length === 0 && search) return (
    <Styled.Note>
      No results for <em>{search}</em>
    </Styled.Note>
  )

  return (
    <section>
      {search && !input
        ? <Styled.Note>Results for <em>{search}</em></Styled.Note>
        : list.length !== 0 &&
          <Styled.Title><History /> From Your History</Styled.Title> 
      }
      <Styled>
        {list.map((company, key )=> {
          const exchange = company.exchange || company.exchangeShortName;
          return (
            <Styled.Item key={key}>
              <Link to={`${path}/${company.symbol}`}>
                <CompanyName name={company.name} symbol={company.symbol} />
              </Link>
              <Label color="#B4B4B4">
                {exchange && `Stock Exchange: ${exchange}`}
              </Label>
            </Styled.Item>
          )
        })}
      </Styled>
    </section>
  );
};

export default List;
