import React from 'react'
import Header from './style';

const HeaderNav = ({ mobile }) => (

    <nav>
        <Header.Menu mobile={mobile}>
          <li>
            <Header.Link to="/">Home</Header.Link>
          </li>
          <li>
            <Header.Link to="/about">About</Header.Link>
          </li>
          <li>
            <Header.Link to="/contact">Contact</Header.Link>
          </li>

          {mobile &&
            <>
              <Header.Mobile.Line />
              <li>
                <Header.Link to='/dashboard'>Dashboard</Header.Link>
              </li>
              <li>
                <Header.Link to='/dashboard'>Account</Header.Link>
              </li>
              <li>
                <Header.Link to='/dashboard'>Invite a Friend</Header.Link>
              </li>
            </>
          }
          
        </Header.Menu>
    </nav>
)

export default HeaderNav
