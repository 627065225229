import React from 'react';
import { Header, Footer } from '../../components';
import { Main } from '../../components/containers';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Signup from './Signup';

const Home = () => <>

  <Header />
  <Main>
    <Section1 />
    <Section2 />
    <Section3 />
    <Signup />
  </Main>
  <Footer />
  
</>

export default Home;
