import styled from 'styled-components';

const FieldSet = styled.fieldset`
label {
    color: transparent;
    height: 1px;
    width: 1px;
    position: absolute;
}
`;

FieldSet.Select = styled.div`
    display: inline-block;
    select {
        background-color: transparent;
        border-bottom: 1px solid #d4d3d3;
        border-radius: 0;
    }
    }
    strong {
        color: transparent;
    }
`;

FieldSet.Legend = styled.legend`
    color: #4d4d4d;
    font-weight: 500;
    padding-top: 0.5em;
    padding-bottom: 0.3em;
    &:after {
        content: '*';
        color: #E03131;
        margin-left: 0.25em;
    }
`;

FieldSet.Container = styled.div`
    display: inline-block;
    div {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        width: auto;
    }
    input {
        width: 75px;
        margin-right: 0.5em;
    }
    strong {
        color: transparent;
    }
    @media only screen and (max-width: 480px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const ReportCard = styled.div`
box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
border-radius: 14px;
padding: 20px 23px 50px;
margin-bottom: 20px;
max-width: 400px;
`;

ReportCard.Container = styled.div`
max-width: 1140px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    @media only screen and (max-width: 1240px) {
        grid-template-columns: 1fr 1fr;
    }
    @media only screen and (max-width: 700px) {
        grid-template-columns: 1fr;
    }
`;

ReportCard.LabelContainer = styled.div`
    .info {
        display: inline-block;
        position: relative;
        left: auto;
    }
`;

ReportCard.Label = styled.label`
font-size: 1em;
color: #4d4d4d;
font-weight: 500;
display: inline-block;
padding-bottom: 0.3em;
margin-right: 12px;
`;

ReportCard.Row = styled.div`
    display: flex;
    align-items: flex-end;
    div:first-of-type {
        margin-bottom: 0;
        margin-right: 8px;
        flex: 1;
        label {
            display: none;
        }
    }
    input {
        text-align: left;
    }
    margin-bottom: 1em;
`;

ReportCard.Grade = styled.div`
    width: 107px;
    height: 38px;
    line-height: 38px;
    text-align: center;
    border-radius: 8px;
    &.poor {
        background-color: #F2B7AB;
        color: #991F17;
    }
    
    &.good {
        background-color: #ABF2D0;
        color: #327140;
    }
    &.average {
        background-color: #FBF7A5;
        color: #87693c;
    }
    &.no-grade {
        visibility: hidden;
    }
`;

export { FieldSet, ReportCard }