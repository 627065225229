import styled from 'styled-components';

const Section = styled.section`
    background-color: #FFF;
    padding: 2% 0 1%;    
    margin-bottom: 3%;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
`;

const Table = styled.table`
    display: block;
    max-width: 837px;
`;

Table.Head = styled.thead`
    display: block;
    background-color: #3D7BF2;
    border-radius: 7px;
    color: #FFF;
    margin-bottom: 11px;
    th {
        font-size: 0.875em;
    };
    * {
        font-weight: 500;
    }
    @media only screen and (max-width: 480px) {
        width: 100%;
        display: flex;
        align-items: center;
        tr {
            width: 100%;
        }
        th:nth-of-type(2) {
            width: auto;
            margin-left: 1em;
        }
        th:nth-of-type(3):before {
            content: '/';
            margin: 0 0.5em;
        }
    }
`;
Table.Body = styled.tbody`
    display: block;
    background-color: #FFF;
    border-radius: 7px;
`;

Table.Row = styled.tr`
    display: flex;
    align-items: center;
    min-height: 40px;
    flex-wrap: wrap;
    padding: 0.2em 0.5em 0.2em 2em;
    > :nth-child(1) {
        width: 7%;
    };
    > :nth-child(2) {
        width: 49%;
    };
    > :nth-child(3) {
        width: 44%;
    };
    @media only screen and (max-width: 480px) {
        > td:nth-child(1) {
            margin-right: 1em;
        }
        > td:nth-child(3) {
            width: 100%;
            margin-left: calc(7% + 1em);
            margin-bottom: 2%;
        }
    }
`;

Table.RowActive = styled.tr`
    display: flex;
    min-height: 40px;
    align-items: center;
    justify-content: space-between;
    padding: 0.2em 1em 0.2em 2em;
`;

Table.RowDate = styled.tr`
    font-size: 1.25em;
    color: #4e4e4e;
    padding: 0.2em 0.5em 0.2em 2em;
    display: flex;
    margin-bottom: 0.5em;
    margin-top: 1em;
    min-height: 54px;
    align-items: center;
    border-bottom: 1px solid #E2e2e2;
    border-top: 1px solid #E2e2e2;
    :first-of-type {
        border-top: none;
    };
`;

Table.HeadCell = styled.th`
    text-align: left;
`;

Table.Cell = styled.td`
    font-weight: ${props => props.dark && 500};
    color: ${props => props.dark? '#4E4E4E' : '#969696'};
    span {
        font-weight: 400;
        color: #969696;
        margin-left: 0.5em;
    }
`;

const Deselect = styled.button.attrs({
    title: 'Deselect All'
})`
    background: transparent;
    display: flex;
    border: none;
    width: 50px;
    cursor: pointer;
`; 

const Delete = styled.button.attrs({
    title: 'Delete All'
})`
    background: transparent;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    > :first-child {
        margin-right: 0.5em;
    }
`;

const Checkmark = styled.button`
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid ${props => props.checked? '#366EDA' : '#ABB5BE'};
    background-color: ${props => props.checked? '#366EDA' : 'transparent'};
    border-radius: 2px;
    height: 1rem;
    width: 1rem;
    path {
        stroke: ${props => props.checked? '#FFF' : 'transparent'};
    }
`;

const Options = styled.button`
    cursor: pointer;
    width: 44px;
    background-color: transparent;
    border: none;
    text-align: right;
`;

export { Section, Table, Deselect, Delete, Checkmark, Options };