import React from 'react'
import GraphSchema from './GraphSchema';
import { optionsDefault, scalesDefault, datasetsDefault, tooltipsDefault } from '../../../utils/graphs';

const options = {
    ...optionsDefault,
    tooltips: tooltipsDefault,
    scales: scalesDefault
}

const Graph1day = ({ data, setData }) => (
        
    <GraphSchema
        api='prices-quarter-hourly'
        label='1 Day'
        data={data}
        setData={setData}
        options={options}
        datasets={datasetsDefault}
    />
)

export default Graph1day
