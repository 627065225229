import React from 'react';
import { Pdf } from '../../../assets/svgComponents';
import { default as Styled } from './style';

const Link = () => (
    
    <Styled>
        <Pdf />
        Download Annual Report
    </Styled>
)

export default Link
