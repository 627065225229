import styled from 'styled-components';

const Modal = styled.div`
  font-family: 'Roboto',sans-serif;
  color: #4D4D4D;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: ${props => props.isOpen? 'visible' : 'hidden'}
`;

Modal.BackDrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 80%;
`;

Modal.Container = styled.div`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  background-color: #FFF;
  transition: all 0.5s;
    transform: translateX(${props => props.isOpen? '0' : '100%' });
   
`;

Modal.ButtonClose = styled.button.attrs({
  title: 'Close',
  type: 'button'
})`
  position: absolute;
  top: 0;
  left: 0.5em;
  font-size: 2.25em;
  color: #928787;
  min-width: 44px;
  min-height: 44px;
  display: block;
  border: none;
  background-color: transparent;
`;

const Button = styled.button`
    border: none;
    background-color: transparent;
    cursor: pointer;
    @media only screen and (min-width: 801px) {
        display: none;
      }
`;

Button.Bars = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 44px;
`;

Button.Bar = styled.span`
    display: block;
    margin: 0.2em 0;
    width: 1.75em;
    border: 0.1em solid #376eda;
    background-color: #376eda;
    border-radius: 5px;
`;

export { Modal, Button };