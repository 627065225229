import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Header, SimpleFormSchema } from '../../components';
import { Main } from '../../components/containers';
import { H1, Text } from '../../components/elements';
import { schema } from './schema';
import { Card, Section } from './style';
import { signUpBg, login } from '../../assets/images';
import { parseStringifiedJSON, StorageKeys } from '../../utils';

const Login = () => {
  const history = useHistory();
  const currentUser = parseStringifiedJSON(localStorage.getItem(StorageKeys.User))
  
  useEffect(() => {
    currentUser && history.push('/dashboard')
    // eslint-disable-next-line
  }, []);


  return <>
  <Header loginPage={{ buttonText: 'Sign up', button: '/sign-up', text:"Don't have an account?"}} />
    <Main>
      <Section img={signUpBg}>
        <Main.Inner>
          <Card>
            <Card.Image img={login} />
            <Card.Content>
              <H1 large grey>
                Welcome Back!
              </H1>
              <Text>Make smarter investments with us.</Text>
              <SimpleFormSchema schema={schema} />
              <Text>
              </Text>
            </Card.Content>
          </Card>
        </Main.Inner>
      </Section>
    </Main>
  </>
};
export default Login;
