import styled from 'styled-components';

const FieldSet = styled.fieldset`
    div {

    }
    div strong {
        color: transparent;
    }
    div * {
        vertical-align: middle;
    }
    div input {
        max-width: 150px;
        display: inline-block;
    }
    div label {
        width: 2em;
        text-align: right;
    }
`;

FieldSet.Legend = styled.legend`
    color: #4d4d4d;
    font-weight: 500;
    padding-top: 0.5em;
    padding-bottom: 1em;
    &:after {
        content: '*';
        color: #E03131;
        margin-left: 0.25em;
    }
`;

const Container = styled.div`
    display: flex;
    width: 100%;
    > :first-child {
        max-width: 510px;
        margin-right: 5%;
    }
    > :last-child {
        width: calc(95% - 510px);
    }
    @media only screen and (max-width: 1366px) {
        flex-direction: column;
        > :last-child {
            width: auto;
            max-width: 800px;
            margin-top: 30px;
        }
    }
`;

export { FieldSet, Container }