import styled from 'styled-components';
import { Search as Icon } from '../../assets/svgComponents';
import { Button } from '../elements';

const Search = {};

Search.Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 1em;
`;

Search.Label = styled.label`
  color: #818181;
  display: inline-block;
  padding-bottom: 0.3em;
`;

Search.Input = styled.input`
  width: 568px;
  max-width: calc(80vw - 86px);
  padding: 0.5em 0.75em 0.5em 55px;
  margin-right: 8px;
  height: auto;
  border: 1px solid transparent;
  &:focus {
    background-color: #fff;
    border: 1px solid #D6d6d6;
  };
  &:focus + ul,
  &:hover + ul {
    display: block;
  }
  @media only screen and (max-width: 1024px) {
    padding-left: 40px;
  }
`;

Search.Icon = styled(Icon)`
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  @media only screen and (max-width: 1024px) {
    padding: 0.15em;
    left: 10px;
}
`;

Search.Button = styled(Button)`
  border-radius: 8px;
`;

export default Search;
