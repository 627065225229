import styled from 'styled-components';

const ResultLabel = styled.span`
    color: ${props =>
        (props.color === 'red' && '#b50000') ||
        (props.color === 'green' && '#27836d') ||
        (props.color === 'yellow' && '#d14500') 
    }

`;

export default ResultLabel;