import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Header, Footer } from '../../components';
import { Main } from '../../components/containers';
import { H1, H2, Text } from '../../components/elements';

const FAQs = () => {
    const [faqs, setFaqs] = useState([]);

    useEffect(() => {

        try {
          const getFaqs = async _ => {
            const response = await axios.get('/api/faqs');
            setFaqs(response?.data || []);
          }
          getFaqs();
        } catch (err) {
          console.error(err);
        }
      }, []);    

    return <Text>

        <Header />
        <Main>
            <Main.Section bottomPadding>
                <Main.Inner>
                    <H1.Underlined margin='1.25em'>FAQs</H1.Underlined>
                    <div>
                        {faqs.length !== 0 ?
                            faqs.map((faq, key) => (
                                <div key={key}>
                                    <H2 small>{faq.question}</H2>
                                    <Text dark>{faq.answer}</Text>
                                </div>
                            ))
                            
                            : <Text dark>Coming soon!</Text>
                        }
                    </div>
                </Main.Inner>
            </Main.Section>
        </Main>
        <Footer />

    </Text>
}

export default FAQs
