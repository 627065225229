import styled from 'styled-components';

const H4 = styled.h4`
    margin-bottom: 0.5em;
    margin-top: 1.5em;
    font-size: 1.25em;
    color: #272727;
    font-weight: 500;
`;

export default H4