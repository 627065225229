import React, { createContext, useContext, useState } from 'react';

const PublicCompanyContext = createContext();

const usePublicCompany = _ => useContext(PublicCompanyContext);

const PublicCompanyContextProvider = ({ children }) => {
  const [data, setData] = useState({
    model: ''
  });

  return (
    <PublicCompanyContext.Provider value={{ data, setData }}>
      {children}
    </PublicCompanyContext.Provider>
  );
};

export { usePublicCompany, PublicCompanyContextProvider };
