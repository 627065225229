import { RequestMethods } from '../../utils';
import ModalForm from './ModalForm';

export const schema = {
  formStyle: { width: 500, padding: '30px 66px 30px 40px' },
  buttonText: 'Send',
  formFields: ModalForm,
  apiCall: async (data, setCurrentUser, requestHandler, currentUser ) => {

    try {
      await requestHandler(
        RequestMethods.Post,
        '/inviteFriend', 
        {...data, name: currentUser.name}
        );
      return 'Your invite has been sent.'; 
    } catch (err) {
      console.error(err);
    }   
  },
  default: {
    friendsEmail: '',
    message: ''
  }
};
