import React, { createContext, useContext, useState } from 'react';

const SPVContext = createContext();

const useSPV = _ => useContext(SPVContext);

const SPVContextProvider = ({ children }) => {
  const [data, setData] = useState({
    model: ''
  });

  return (
    <SPVContext.Provider value={{ data, setData }}>
      {children}
    </SPVContext.Provider>
  );
};

export { useSPV, SPVContextProvider };
