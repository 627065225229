import React from 'react'
import { Close, Delete as Icon } from '../../assets/svgComponents';
import { useAuth, useUserHistory } from '../../context';
import { Table, Delete, Deselect, Checkmark } from './style';

const HistoryTableHead = () => {
    const { selection, handleClick } = useUserHistory();
    const { deleteFromHistory } = useAuth();

    const selected = selection.length;

    return (

        selected
        ?   <Table.RowActive>
                <Table.HeadCell>
                    <Deselect onClick={() => handleClick('clear-selection')}>
                        <Close title="Delete All" />
                    </Deselect>
                </Table.HeadCell>
                <Table.HeadCell>
                    <Delete onClick={() => deleteFromHistory(selection)}>
                        <Icon />
                        Delete
                    </Delete>
                </Table.HeadCell>
            </Table.RowActive>
        :   <Table.Row>
                <Table.HeadCell>
                    <Checkmark onClick={() => handleClick('select-all')}/>
                </Table.HeadCell>
                <Table.HeadCell>Company Name</Table.HeadCell>
                <Table.HeadCell>Type of Valuation</Table.HeadCell>
            </Table.Row>
        
    )
}

export default HistoryTableHead
