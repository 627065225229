import React from 'react';
import SimpleFormSchema from '../schemas/SimpleFormSchema';
import { Title } from './style';
import { schema } from './schema';

const ModalContent = () => {
    return <>
        <Title>
            Invite a friend
        </Title>
        <SimpleFormSchema schema={schema} />
    </>
}

export default ModalContent
