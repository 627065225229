import { RequestMethods } from '../../utils';
import FormFields from './FormFields';

export const schema = {
    buttonText: 'Send Message',
    formFields: FormFields,
    formStyle: {},
    apiCall: async (formData, _, requestHandler) => {
        try {
            await requestHandler(RequestMethods.Post, '/contactForm', formData)
    
            return 'Your message has been sent.'
        } catch (err) {
            console.log(err);
        }
    },
    default: {
        name: '',
        email: '',
        message: ''
    }
};
