import React, { createContext, useContext, useState } from 'react';
import { useAuth } from './User';

const HistoryContext = createContext();

const useUserHistory = _ => useContext(HistoryContext);

const HistoryContextProvider = ({ children }) => {
    const [ filterTerm, setFilterTerm ] = useState('');
    const [ selection, setSelection ] = useState([]);
    const { currentUser } = useAuth();

    const history = currentUser.history || [];

    const filteredHistory = history.filter( company => (
        company.name?.toLowerCase().includes(filterTerm.toLowerCase())
        || company.symbol?.toLowerCase().includes(filterTerm.toLowerCase())
    ));

    let historyIds = [];

    const handleClick = selected => {

        if (selected === 'clear-selection') return setSelection([]);
        if (selected === 'select-all') {
            history.map(company => (
                historyIds.push(company._id)
            ));
            return setSelection(historyIds);
        }

        let newSelection = selection;
        if (selection.includes(selected)) {
            newSelection = selection.filter(
                item => item !== selected
            );
        } else {
            newSelection = [...selection, selected]
        }
        setSelection(newSelection);
    }


    return (
        <HistoryContext.Provider 
            value={{ handleClick, selection, filteredHistory, setFilterTerm }}
        >
            {children}
        </HistoryContext.Provider>
    );
};

export { HistoryContextProvider, useUserHistory };