import React from 'react';
import { Input } from '../../components';
import { FieldsContainer } from '../../components/containers';
import { FieldSet } from './style';

const CashflowYearwise = ({ data, onChange }) => {
  let years = [];

  for (let i = 1; i <= data.periodLength; i++) {
    years.push(i);
  }

  return (
    <fieldset>
      <FieldSet.Legend>Cashflow Yearwise</FieldSet.Legend>
      <div>
        <FieldsContainer>
          {years.map(year =>
            <Input
              key={year}
              required
              placeholder={"Cashflow Year " + year}
              label={"Cashflow Year " + year}
              name={'cashFlowYear' + year}
              value={data['cashFlowYear' + year]}
              onChange={onChange}
            />
          )}

        </FieldsContainer>
      </div>
    </fieldset>
  );
};

export default CashflowYearwise;
