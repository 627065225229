import React from 'react';
import { H2, TextOpenSans } from '../../components/elements';
import { Main } from '../../components/containers';
import { simplicity, growth, accuracy } from '../../assets/images'; 
import About from './style';

const Section2 = () => {
  return (
    <Main.Section bottomPadding>
        <About.Row>
          <About.ColumnImage>
            <About.Image src={simplicity} alt="" />
          </About.ColumnImage>
          <About.Column>
            <H2>Simplicity</H2>
            <TextOpenSans large>
              We believe that simplicity is key for understanding. The better
              you understand something, the easier it is to make informed
              decisions. From our user interface to our technical models, we
              have made business valuation as simple as a few clicks
            </TextOpenSans>
          </About.Column>
        </About.Row>
        <About.Row reverse>
          <About.ColumnImage right>
            <About.Image src={growth} alt="" />
          </About.ColumnImage>
          <About.Column left>
            <H2>Growth</H2>
            <TextOpenSans large>
              Valuatr appreciates the hard work investors do to earn their money
              and to keep it safe. We want to not only encourage, but facilitate
              growth for the investors by offering tools to ensure this growth
              is maintained
            </TextOpenSans>
          </About.Column>
        </About.Row>
        <About.Row>
          <About.ColumnImage>
            <About.Image src={accuracy} alt="" />
          </About.ColumnImage>
          <About.Column>
            <H2>Accuracy</H2>
            <TextOpenSans>
              With information and opinions being abundant in this space, it is
              critical that the information you receive is accurate. Valuatr
              takes into account all information and helps investors feel
              confident that the values we produce are precise.
            </TextOpenSans>
          </About.Column>
        </About.Row>
    </Main.Section>
  );
};

export default Section2;
