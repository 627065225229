import React from 'react'
import { Tabs } from '../';
import { Title, Container, SignOut } from './style';
import schema from './schema';
import { useAuth } from '../../context';

const ModalContent = () => {
  const { handleSignOut } = useAuth();


  return <>
   
   <Title>Settings</Title> 
    <Container>
      <Tabs schema={schema} />
      <SignOut onClick={handleSignOut}>Sign out</SignOut>
    </Container>
  </>
}

export default ModalContent
