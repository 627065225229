import styled from 'styled-components';
import { Main } from '../../components/containers';
import { Image } from '../../components/elements';

const About = {};

About.Inner = styled(Main.Inner)`
text-align: center;
max-width: 580px;
padding-bottom: 2%;
padding-top: 2%;
h1 {
  margin-bottom: 10%!important;
}
`;

About.Row = styled(Main.InnerColumns)`
  @media only screen and (max-width: 600px) {
    padding: 5% 0;
  }
`;

About.Column = styled(Main.Column)`
@media only screen and (max-width: 600px) {
  padding-top: 0;
  text-align: ${props => props.left? 'left' : 'right'};
}
`;

About.ColumnImage = styled(Main.Column)`
@media only screen and (max-width: 600px) {
  padding-bottom: 0;
  text-align: ${props => props.right && 'right'}
}
`;

About.Image = styled(Image)`
  @media only screen and (max-width: 600px) {
    width: 40%!important;
  }
`;


export default About;
