import styled from 'styled-components';

const Container = styled.div`
    max-width: ${props => props.twoColumns? '510px' : '765px'};
    > div {
        display: ${props => props.oneColumn? 'block' : 'inline-block'};
        width: ${props => props.twoColumns? '50%' : '33%'};
        vertical-align: ${props => props.top? 'top' : 'bottom'};
    }
    input {
        max-width: 166px;
    }
    @media only screen and (max-width: 800px) {
        > div {
            width: 40vw; 
        }
    }
    @media only screen and (max-width: 600px) {
        > div {
            display:block;
            width: auto; 
        }
    }
`;

export default Container;