export const getCurrentDate = _ => {
  const now = new Date();
  const year = now.getFullYear();
  let month = now.getMonth() + 1;
  if (month < 10) month = `0${month}`;
  let date = now.getDate();
  if (date < 10) date = `0${date}`;
  const today = `${year}-${month}-${date}`;
  return today;
};

const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];
const dateSuffix = date => (
  (date % 10 === 1 && date !== 11 && 'st') ||
  (date % 10 === 2 && date !== 12 && 'nd') ||
  (date % 10 === 3 && date !== 13 && 'rd') ||
  'th'
)

export const timestampToDate = (timestamp, version) => {
  const time = new Date(timestamp * 1);
  const date = time.getDate();
  const month = months[time.getMonth()];
  const year = time.getFullYear();
  const day = weekDays[time.getDay()];

  if (version === 'long')
    return `${day}, ${date} ${month} ${year}`;

  if (version === 'time') {
    let hours = time.getHours();
    const minutes = time.getMinutes();
    let ampm = 'am';

    if (hours > 12) {
      hours = hours - 12;
      ampm = 'pm'
    }

    return `${date} ${month} ${hours}:${minutes > 10 ? minutes : '0' + minutes} ${ampm}`;
  }

  return `${date}${dateSuffix(date)} ${month}, ${year}`
}

export const createId = text => text.replaceAll(' ', '-').toLowerCase();

export const createDatalist = (min, max, step, id) => {
  let options = [];
  for (let i = min; i <= max; i = i + step) {
    options.push(<option value={i} />);
  }
  return <datalist id={id}>{options}</datalist>;
};

export const parseStringifiedJSON = (item, defaultValue) => {
  try {
    const parsedValue = JSON.parse(item);
    return parsedValue;
  } catch {
    return defaultValue;
  }
};

export const noop = () => null;

export * from './requests';
export * from './storageKeys';
export * from './graphs';