import React from 'react';
import { InputSelect } from '../../components';
import {
  DiscountDividendModel,
  DiscountCashflowModel,
  CapitalizedEarningsModel,
} from './PublicCompanyModels';
import { models } from '../definitions';
import { Container } from '../../components/containers';
import { Bold } from '../../components/elements';

const PublicCompany1 = ({ data, setData, handleChange }) => (

  <>
    <InputSelect
      label="Select the type of model"
      id="type"
      required
      options={[
        models.DDM.label,
        models.DCM.label,
        models.CEM.label
      ]}
      value={data.model}
      onChange={e => setData({ ...data, model: e.target.value })}
      placeholder="Select"
    />

    <Container top='2.5rem'>
      {data.model === models.DDM.name && (
        <DiscountDividendModel data={data} setData={setData} />
      )}
      {data.model === models.DCM.name && (
        <DiscountCashflowModel data={data} onChange={handleChange} setData={setData} />
      )}
      {data.model === models.CEM.name && (
        <CapitalizedEarningsModel data={data} onChange={handleChange} setData={setData} />
      )}

      {!data.model && <>
        <div>
          <Bold>{models.DDM.label}</Bold>
          <p>{models.DDM.info}</p>
        </div>
        <div>
          <Bold>{models.DCM.label}</Bold>
          <p>{models.DCM.info}</p>
        </div>
        <div>
          <Bold>{models.CEM.label}</Bold>
          <p>{models.CEM.info}</p>
        </div>
      </>}
    </Container>

  </>

);

export default PublicCompany1;
