import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BackNextContainer } from '../';

const ApiFetchSchema = ({ data, schema }) => {
  const [activePage, setActivePage] = useState(schema.page1);
  const history = useHistory();

  const handleBack = _ => {
    activePage.previous
      ? setActivePage(schema[activePage.previous])
      : history.push(schema.history);
  };

  const handleNext = _ => {
    activePage.next
      ? setActivePage(schema[activePage.next])
      : history.push(schema.history);
  };

  const ActivePage = activePage.component;

  return (
    <>
      <ActivePage data={data} handleNext={handleNext} />
      <BackNextContainer
        next={activePage.nextButton}
        handleBack={handleBack}
        handleNext={handleNext}
      />
    </>
  );
};

export default ApiFetchSchema;
