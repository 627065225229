import styled from 'styled-components';

const H2 = styled.h2`
  font-family: 'Roboto', sans-serif;
  font-size: ${props => props.small? '1.5em' :'2.5em'};
  line-height: 1.5em;
  color: ${props => (props.white ? '#FFF' : '#000')};
  font-weight: 700;
  margin-bottom: 0.25em;
  @media only screen and (max-width: 1024px) {
    font-size: ${props => props.small? '1.25em' :'2em'};
    line-height: 1.3;
  }
  @media only screen and (max-width: 480px) {
    font-size: ${props => props.small? '1em' :'1.75em'};
    line-height: 1.3;
  }
`;

export default H2;
