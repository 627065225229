import React from 'react';
import { createId } from '../../utils';
import { default as Styled } from './style';
import { Info } from '..';
import { Required } from '../elements';

const Input = ({ children, info, name, label, value, currency, percent, ...rest }) => {
  const id = name || createId(label);
  const placeholder = rest.readOnly? 'Unavailable': 'Enter Value Here' 
  const inputValue = (value === null) || (value === undefined)? '' : value;
  
  return (
    <Styled.Container>
      <Styled.Label htmlFor={id}>
        {label}
        {rest.required && <Required />}
      </Styled.Label>
      <Styled
        type="number"
        step={0.00000000001}
        placeholder={placeholder}
        id={id}
        name={id}
        value={inputValue}
        {...rest}
      />
      {info && <Info className='info' info={info} />}
      {children}
    </Styled.Container>
  );
};

export default Input;
