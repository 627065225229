import { noop } from '../../utils';
import StockOptionValuation1 from './StockOptionValuation1';
import StockOptionValuation2 from './StockOptionValuation2';
import StockOptionValuation3 from './StockOptionValuation3';

export const schema = {
  page1: {
    component: StockOptionValuation1,
    getPrevious: noop,
    getNext: () => 'page2',
    nextButton: 'Next',
  },
  page2: {
    component: StockOptionValuation2,
    getPrevious: () => 'page1',
    onNext: async (formData, setFormData, requestHandler, RequestMethods ) => {
      try {
        const getIntristicValue = requestHandler(
          RequestMethods.Post,
          `/stock-option-valuation/${formData.symbol}/valuation`,
          formData
        );

        const [ intristicValue] = 
          await Promise.all([getIntristicValue]);

        setFormData({ 
          ...formData, 
          ...intristicValue?.data
        });
      } catch (error) {
        console.error(error);
      }
    },
    getNext: () => 'page3',
    nextButton: 'Generate Report',
  },
  page3: {
    component: StockOptionValuation3,
    getPrevious: () => 'page2',
    getNext: noop,
    nextButton: 'Done',
  },
  hook: 'useSOV',
  history: '/public-investments/stock-option-valuation',
};
