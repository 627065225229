import React from 'react';
import Input from './style';

const InputCheckbox = ({ label, name, value, ...rest }) => (
  <Input.Container>   
    <Input
      id={name}
      name={name}
      aria-checked={value}
      {...rest}
      checked={value}
    />
    <Input.Label htmlFor={name}>{label}</Input.Label>
  </Input.Container>
);

export default InputCheckbox;
