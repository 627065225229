import React from 'react';
import { ProjectFinanceContextProvider } from '../../context/ProjectFinance';
import { Breadcrumbs, DashboardHeader, FormSchema } from '../../components';
import { DashboardMain as Main } from '../../components/containers';
import { schema } from './schema';

const ProjectFinance = () => (
  <>
    <DashboardHeader title="Project Finance" back="/dashboard" />
    <Main>
      <Main.Inner>
        <Main.Title>Project Finance</Main.Title>
        <Breadcrumbs path='Project Finance' url='/dashboard/project-finance' />
      </Main.Inner>

      <ProjectFinanceContextProvider>
        <Main.Inner>
          <FormSchema schema={schema} />
        </Main.Inner>
      </ProjectFinanceContextProvider>
    </Main>
  </>
);

export default ProjectFinance;
