import React from 'react'
import { Header, Footer } from '../../components';
import { Main } from '../../components/containers';
import { H1, H2, StyledLink, Text } from '../../components/elements';
import pdf from '../../assets/pdf/Valuatr Privacy Policy.pdf';
import { Pdf } from '../../assets/svgComponents';

const PrivacyPolicy = () => <>
    
    <Header />
    <Main>
        <Main.Section bottomPadding>
            <Main.Inner>
                <H1>Privacy Policy</H1>

                <Text bold dark>Last Updated: May 7th, 2021</Text>
                <Text><StyledLink><a href={pdf} download><Pdf /> Download PDF</a></StyledLink></Text>
                <Text dark>
                Valuatr Inc. ("Valuatr", “our”, “us” or “we”), respects your privacy and is committed to protecting it through our compliance with this privacy policy (“Privacy Policy”).
                </Text>
                <Text dark>
                This Privacy Policy applies to information collected by Valuatr through the Valuatr website and user dashboard. This Privacy Policy does not apply to the practices of any third party websites, applications or services that Valuatr does not own or maintain (collectively, “Third Party Services”) or to any third parties that use the Valuatr Application Programming Interface (API) to perform any function related to the Valuatr properties (“Integrated Platforms”). In particular, this Privacy Policy does not cover any information or other content you can view via the Valuatr properties on Integrated Platforms or information you provide to Third Party Services accessed via the Valuatr properties. As further detailed below, we cannot take responsibility for the content or privacy policies of any Third Party Services. If you are a Viewer we encourage you to carefully review the privacy policies of the Valuatr User whose Content you view and the privacy policies of any Integrated Platforms or Third Party Services used by such User which you may access via the Valuatr properties.
                </Text>
                <Text dark>
                This Privacy Policy also does not cover any information, recorded in any form, about more than one individual where the identity of the individuals is not known, cannot be inferred from the information, and is not linked or reasonably linkable to an individual, including via a device (“Aggregated Information”). Valuatr retains the right to use Aggregated Information in any way that it reasonably determines is appropriate.
                </Text>
                <Text dark>
                By using the Valuatr Services or otherwise providing us with your Personal Information (as defined below), you are accepting the practices described in this Privacy Policy, as they may be amended by us from time to time, and agreeing to our collection and use of your information in accordance with this Privacy Policy. If you do not agree to the collection, use and disclosure of your information in this way, please do not use any of the Valuatr services or otherwise provide Valuatr with Personal Information.
                </Text>

                <H2 small>What information Valuatr collects from you</H2>
                <Text dark>
                Valuatr collects only the information required to provide services to you. The amount of information provided by you and collected by Valuatr depends on the circumstances. Valuatr may collect two (2) types of information about you: Personal and Non-Personal.
                </Text>
                <Main.OList>
                    <Main.ListItem>
                    <em>“Personal Information.”</em> Personal Information means any information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular individual or household, or is otherwise defined as personal information under applicable law. Valuatr may collect Personal Information when you use the Valuatr services including, without limitation, setting up account information, filling out surveys, corresponding with Valuatr, or otherwise volunteering information about yourself.
                    </Main.ListItem>
                    <Main.ListItem>
                        <em>“Non-Personal Information.”</em> Non-Personal Information refers to information that does not meet the definition of Personal Information above. Valuatr may collect Non-Personal Information through any of the methods discussed above as well as automatically through use of industry standard technologies described further below.
                    </Main.ListItem>
                </Main.OList>

                <H2 small>How Valuatr collects your information</H2>
                <Main.OList>
                    <Main.ListItem>
                        <em>Registration.</em> Prior to using one or more of the Valuatr services, Valuatr may require you to provide us with certain Personal Information and Non-Personal Information to create an account (“Account”) or to enable features or functionality of the Valuatr dashboard.
                    </Main.ListItem>
                    <Main.ListItem>
                        <em>Users.</em> Valuatr may gather Personal Information about organizational representatives via various methods (phone, email, online forms, in-person meetings) but only if such Personal Information is submitted voluntarily. Valuatr may use such Personal Information for sales, marketing, and support of the Valuatr. This Personal Information is never shared with third parties other than Third Party Service Providers utilized by a User in connection with Valuatr Inc.
                    </Main.ListItem>
                    <Main.ListItem>
                        <em>User communications.</em> When you send email or other communications to us, we may retain those communications in order to process your inquiries, respond to your requests and improve the Valuatr Dashboard.
                    </Main.ListItem>
                    <Main.ListItem>
                        <em>Payment Information.</em> When creating an Account, for certain Valuatr services, or when you make online purchases, you may be asked to provide information, which may include your payment instrument number (e.g., credit card), your name and billing address, and the security code associated with your payment instrument (e.g., the CSV) and other financial data (“Payment Information”). We use Payment Information to complete transactions, as well as for the detection and prevention of fraud. When you provide Payment Information while authenticated, we will store that data to help you complete future transactions without your having to provide the information again. We do not, however, retain the security code associated with your payment instrument (e.g., the CSV) in this manner. To remove or modify Payment Information, please contact us. After you close your account or remove Payment Information, however, we may retain your Payment Information for as long as reasonably necessary to complete your existing transaction and for the detection and prevention.
                    </Main.ListItem>
                    <Main.ListItem>
                        <em>Information Collected Through Technology.</em> Valuatr automatically collects and receives certain information from your computer or mobile device, including the activities you perform on the Valuatr Site and the Valuatr Dashboard, the type of hardware and software you are using (for example, your operating system or browser), and information obtained from cookies (see below). If you have an Account, we may link this Non-Personal Information to your Account to better understand your needs and the needs of Users in the aggregate, diagnose problems, analyze trends, provide services, improve the features and usability of the Valuatr services, and better understand and market to our customers and Users.
                    </Main.ListItem>
                    <Main.ListItem>
                        We use technology to automatically gather information by the following methods:
                        <Main.UList type='circle'>
                            <Main.ListItem><em>Cookies.</em> Valuatr uses cookies on the Valuatr Site and other aspects of the Valuatr Dashboard. Cookies, including local shared objects, are small pieces of information that are stored by your browser on your computer's hard drive which work by assigning to your computer a unique number that has no meaning outside of the Valuatr Site. Most web browsers automatically accept cookies, but you can usually configure your browser to prevent this. Not accepting cookies may make certain features of the Valuatr Dashboard unavailable to you.</Main.ListItem>
                            <Main.ListItem><em>IP Address.</em> You may visit many areas of the Valuatr Site anonymously without the need to become a registered User. Even in such cases, Valuatr may collect IP addresses automatically. An IP address is a number that is automatically assigned to your computer whenever you begin services with an Internet services provider. Each time you access the Valuatr Site and each time you request one of the pages of the Valuatr Site, the server logs your IP address.</Main.ListItem>
                            <Main.ListItem><em>Web Beacons.</em> Web beacons are small pieces of data that are embedded in web pages and emails. Valuatr may use these technical methods in HTML emails that Valuatr sends to Users to determine whether they have opened those emails and/or clicked on links in those emails. The information from use of these technical methods may be collected in a form that is Personal Information.</Main.ListItem>
                            <Main.ListItem><em>Tracking Content Usage.</em> If you use the Valuatr Services and you post audio visual materials including, without limitation, videos, links, logos, artwork, graphics, pictures, advertisements, sound and other related intellectual property contained in such materials (collectively, “Content”) to your website or to a third party website, Valuatr tracks and captures information associated with User accounts and the use of Content by those that access your Content.</Main.ListItem>
                        </Main.UList>
                    </Main.ListItem>
                    <Main.ListItem>
                        <em>Information</em> You Provide About a Third Party. You may have the opportunity to communicate with others from the Valuatr Dashboard, such as by sending an invitation to a friend. If you choose to take advantage of this functionality, we may ask you to provide us with certain information about the person with whom you wish to communicate (e.g., name, email address, etc.). Valuatr collects such information for the purposes of facilitating the requested communication, which may contain a specific promotional message from you (e.g., an invitation to watch a video). Unless we explicitly say otherwise, Valuatr will not use this information for other marketing purposes without first obtaining consent from the person to whom the relevant information pertains. Please be aware that when you use any invitation functionality on the Valuatr Dashboard, your email address, name or username, and message may be included in the communication sent to your addressee(s).
                    </Main.ListItem>
                </Main.OList>

                <H2 small>How Valuatr uses your information</H2>
                <Main.OList>
                    <Main.ListItem><em>Personal Information.</em> Valuatr identifies the purpose for which your Personal Information is collected and will be used or disclosed. If that purpose is not listed below, we will identify any additional purposes for which we will collect your Personal Information, before or at the time of collection, and we will obtain your consent to collect, use or disclose your Personal Information for such additional purpose(s).</Main.ListItem>
                    <Main.ListItem>By using the Valuatr Dashboard, you will be deemed to consent to our use of your Personal Information for the purposes of:
                        <Main.UList type='circle'>
                            <Main.ListItem>communicating with you generally;</Main.ListItem>
                            <Main.ListItem>processing your purchases;</Main.ListItem>
                            <Main.ListItem>processing and keeping track of transactions and reporting back to you;</Main.ListItem>
                            <Main.ListItem>protecting against fraud or error;</Main.ListItem>
                            <Main.ListItem>providing information or services requested by you;</Main.ListItem>
                            <Main.ListItem>administering and managing the Valuatr Dashboard and our business operations;</Main.ListItem>
                            <Main.ListItem>personalizing your experience with the Valuatr Site, as well as evaluating statistics on Valuatr Site activity;</Main.ListItem>
                            <Main.ListItem>performing statistical analyses of your behavior and characteristics, in order to measure interest in and use of the various sections of the Valuatr Site;</Main.ListItem>
                            <Main.ListItem>communicating with you on other websites;</Main.ListItem>
                            <Main.ListItem>email gating;</Main.ListItem>
                            <Main.ListItem>delivery of content and information to Third Party Services Providers;</Main.ListItem>
                            <Main.ListItem>complying with legal and governmental requirements; and/or</Main.ListItem>
                            <Main.ListItem>fulfilling any other purpose that would be reasonably apparent to the average person at the time that we collect it.</Main.ListItem>
                        </Main.UList>
                    </Main.ListItem>
                    <Main.ListItem>Users utilize Valuatr to understand investments based on financial metrics. As part of this process, Valuatr may collect Personal Information from you in order to optimize how users utilize the Valuatr Dashboard.</Main.ListItem>
                    <Main.ListItem>Otherwise, we will obtain your express consent (by verbal, written or electronic agreement) to collect, use or disclose your Personal Information. You can change your consent preferences at any time by contacting us (see the “How to Access, Change and Erase Your Personal Information” section below).</Main.ListItem>
                    <Main.ListItem>Valuatr extends the rights granted to “data subjects” under the Personal Information Protection and Electronic Documents Act (Canada 2019) (the “PIPEDA”) to all of its Users. Consequently, you have the right to withdraw your consent to our processing of your Personal Information at any time (if our processing is based on consent) and the right to object to our processing of your Personal Information (if processing is based on legitimate interests).</Main.ListItem>
                    <Main.ListItem><em>Non-Personal Information.</em> Valuatr may use Non-Personal Information for the following purposes:
                        <Main.UList type='circle'>
                            <Main.ListItem>System Administration: Valuatr may use Non-Personal Information for the purposes of system administration, assisting in diagnosing problems with Valuatr servers, monitoring Valuatr’s system performance and traffic on the Valuatr Dashboard and to gather broad demographic information about Valuatr Users.</Main.ListItem>
                            <Main.ListItem>Personalization: Valuatr uses cookies and IP addresses to track features such as delivering Content specific to your interests and informing you of new, relevant services or certain third party offerings.</Main.ListItem>
                        </Main.UList>
                    </Main.ListItem>
                </Main.OList>

                <H2 small>How to access, change and erase your personal information</H2>
                <Text dark>Upon request, Valuatr will allow Users to update or correct Personal Information previously submitted, but only to the extent such activities will not compromise privacy or security interests. Additionally, upon request, Valuatr will delete Personal Information from the database where such information is stored; however, it may be impossible to entirely delete a User’s entry without some residual information being retained due to the manner in which data backups are maintained. Requests to delete Personal Information may be submitted to&nbsp;
                <a href='mailto:info@valuatr.ca'><StyledLink>info@valuatr.ca</StyledLink></a>.</Text>
                <Text dark>Users also have the right to receive their Personal Information from us in a structured, commonly used and machine-readable format, and the right to transmit their Personal Information to another controller without hindrance from us (data portability).</Text>

                <H2 small>Email preferences</H2>
                <Text dark>Valuatr may use your Personal Information to send you emails periodically listing promotions or events relating to the Valuatr Dashboard. You have the choice to opt-out of receiving such promotional emails by sending an email to info@valuatr.ca and/or following the instructions in such correspondence. Once Valuatr's has processed your opt-out request, Valuatr will not send you promotional emails unless you opt back in to receiving such communications.</Text>
            
                <H2 small>Disclosure of information to third parties</H2>
                <Text dark>Except as described below, we do not sell, transfer or otherwise disclose, sell, trade, or otherwise transfer your Personal Information to outside parties. This statement does not include trusted third party service providers who assist us in administering and providing the Valuatr Properties or provide services to us. Examples include storing and managing Content, analyzing data, providing marketing assistance, integrations of Third Party Services such as CRM and MAP services, processing credit card payments, and providing customer service. These third party service providers will have access to Personal Information needed to perform their functions, but may not use it for other purposes, and they are subject to appropriate agreements with Valuatr and/or its Users to secure and protect the confidentiality of your Personal Information.</Text>
                <Text dark>We may use service providers located outside of the United States and Canada, and, if applicable, your Personal Information may be processed and stored in other countries and therefore may be subject to disclosure under the laws of those countries. You explicitly consent and agree to such transfer, storing and/or processing of your Personal Information outside of the United States and Canada or other country in which you are located.</Text>
                <Text dark>We may share Payment Information with third parties for purposes of fraud prevention or to process payment transactions.</Text>
                <Text dark>We may also release your information when we believe release is appropriate to comply with the law, enforce our policies, or protect our or others’ rights, property or for safety. We may also provide non-Personal Information to other parties for marketing, advertising or other uses.</Text>
                <Text dark>Information, including Personal Information, which we collect from Users and Viewers, is considered to be a business asset. As a result, in the unlikely event that we go out of business, enter bankruptcy or if we are acquired as a result of a transaction such as a merger, acquisition or asset sale, your Personal Information may be disclosed or transferred to the third-party acquirer in connection with the transaction.</Text>
                <Text dark>We may also share information related to your account with your employer or organization if you have an individual Valuatr account and your account email domain is owned or managed by your employer or organization.</Text>
                <Text dark>Lastly, we may provide Users with certain usage information directly related to the videos and/or other Content that they make available through the Valuatr Properties. Such information may include who watched a particular Content (if the viewer is logged into Valuatr), which Content of a particular User was watched, and how many times a particular Content was watched.</Text>
                <Text dark>Under certain exceptional circumstances, Valuatr may have a legal duty or right to collect, use or disclose your Personal Information without your knowledge or consent. In accordance with applicable laws, We will not disclose any consumer information (which may include Personal Information) without your written consent, except where consumer information is required to be disclosed: (i) for billing or market operation purposes; (ii) for law enforcement purposes; or (iii) for the purpose of complying with a legal requirement.</Text>
                <Text dark>When you participate in a Valuatr Business or Valuatr Enterprise account (as described in Valuatr’s Terms of Service), the organization administering such account may be able to (1) access information in and about the account, including your Personal Information; (2) disclose, restrict or access Content posted in connection with the account; and (3) control how the account is accessed or deleted. Such accounts and their use are also subject to the Valuatr Terms of Service accepted by the organization that established the Valuatr Business or Valuatr Enterprise account.</Text>
                <Text dark>You consent to disclosure of your information for the above purposes.</Text>

                <H2 small>Safeguarding your personal information</H2>
                <Text dark>Valuatr takes appropriate security measures to protect against unauthorized access, alteration, disclosure or destruction of Personal Information. These include, but are not limited to, internal reviews of: (a) Valuatr's data collection; (b) storage and processing practices; (c) electronic security measures; and (d) physical security measures to guard against unauthorized access to systems where Valuatr stores Personal Information.</Text>
                <Text dark>Unfortunately, no data transmission over the internet can be guaranteed to be 100% secure. As a result, while we are committed to protecting your Personal Information, we cannot ensure or warrant the security of any information you provide to us.</Text>
                <Text dark>All Valuatr employees, contractors and agents who access Personal Information are bound by confidentiality obligations and may be subject to discipline, including termination and criminal prosecution or unauthorized use or disclosure of Personal Information.</Text>
                <Text dark>Some or all of the Personal Information we collect may be stored or processed on servers located outside your jurisdiction of residence, whose data protection laws may differ from the jurisdiction in which you live. As a result, this information may be subject to access requests from governments, courts, or law enforcement in those jurisdictions according to laws in those jurisdictions.</Text>
                
                <H2 small>Retention of information</H2>
                <Text dark>Valuatr retains the Personal Information that we collect about you for as long as reasonably necessary for the purposes set out in this Privacy Policy. We also may retain your Personal Information for a longer period of time on the basis of our legitimate interests in providing or marketing our services to you or as necessary to comply with our legal obligations, to resolve disputes, and to enforce our agreements. Even if we delete some or all of your Personal Information, we may continue to retain and use information that has been aggregated or anonymized so that it can no longer be used for personal identification.</Text>
                
                <H2 small>Children and students</H2>
                <Text dark>Valuatr takes the privacy of children and students extremely seriously. Personal information we collect through the Valuatr Services may be subject to the Children’s Online Privacy Protection Act (“COPPA”) and/or the Family Educational Rights and Privacy Act (“FERPA”).</Text>
                <Text dark><b>COPPA Compliance.</b> COPPA requires that operators of websites and online services that collect the personal information of children under 13 years of age (i) inform parents and legal guardians about their practices for collecting, using and disclosing such personal information and (ii) obtain verifiable consent from parents and legal guardians for doing so. We only collect personal information through the Valuatr Services from a child under 13 if that student’s school, school district or teacher has agreed to obtain parental consent for that child to use the Valuatr Services and disclose personal information to us for purposes of providing the Valuatr Services, or we have directly obtained such parental consent.</Text>
                <Text dark>If you are a student under 13, please do not send any personal information about yourself to us if your school, school district or teacher has not obtained this prior consent from your parent or guardian, or we have not obtained such consent, and please do not send any personal information other than what we request from you in connection with the Valuatr Services. If we learn we have collected personal information from a student under 13 without parental consent having been obtained, or if we learn a student under 13 has provided us personal information beyond what we request from him or her, we will delete that information as quickly as possible. If you believe that a student under 13 may have provided us with personal information in violation of this Privacy Policy, please contact us at&nbsp;
                <a href='mailto:privacy@Valuatr.com'><StyledLink>privacy@Valuatr.com</StyledLink></a>.</Text>
                <Text dark><b>FERPA Compliance.</b> FERPA protects personally identifiable information contained in students’ education records from unauthorized disclosure. Consistent with FERPA, we will only use education records, as defined under FERPA, for the purpose of providing agreed services to a school, school district or teacher. We will never share or sell FERPA-protected information, or use it for any other purposes, except as otherwise directed or permitted by the school, school district or teacher. If a parent or eligible student requests access to education records that are hosted on our servers, we will help facilitate such access.</Text>
                
                <H2 small>EU and Swiss Privacy Shield Frameworks</H2>
                <Text dark>Valuatr complies with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of Personal Information transferred from European Union member countries (as well as Iceland, Liechtenstein, and Norway), the United Kingdom ("UK") and Switzerland transferred to the United States in reliance on the Privacy Shield. Valuatr has certified that it adheres to the Privacy Shield Principles with respect to such Personal Information. If there is any conflict between the policies in this Privacy Policy and data subject rights under the Privacy Shield Principles, the Privacy Shield Principles shall govern. To learn more about the Privacy Shield program, and to view our certification page, please visit&nbsp;
                    <a href='https://www.privacyshield.gov/welcome' target='_blank' rel='noreferrer'>
                        <StyledLink>http://www.privacyshield.gov</StyledLink>
                    </a>.
                </Text>
                <Text dark>With respect to Personal Information received or transferred pursuant to the Privacy Shield Frameworks, Valuatr is subject to the regulatory and enforcement powers of the U.S. Federal Trade Commission.</Text>
                <Text dark><em>Types of EU, UK and Swiss Personal Information Collected.</em> Our participation in the Privacy Shield applies to all Personal Information that is subject to this Privacy Policy and is received from the European Union and European Economic Area, the UK and Switzerland. We will comply with the Privacy Shield Principles with respect to all EU, UK and Swiss Personal Information. We may collect employment-related Personal Information regarding our employees located in the EU, the UK and Switzerland.</Text>
                <Text dark><em>Purposes of EU, UK and Swiss Personal Information Collection and Use.</em> We will only process EU, UK and Swiss Personal Information in ways that are compatible with the purpose for which we collected the EU, UK and Swiss Personal Information, or for purposes that the individual or entity providing the EU, UK and Swiss Personal Information later authorizes.</Text>
                <Text dark>Pursuant to the Privacy Shield Frameworks, EU, UK and Swiss individuals have the right to obtain our confirmation of whether we maintain Personal Information relating to you in the United States. Upon request, we will provide you with access to the Personal Information that we hold about you. You may also correct, amend, or delete the Personal Information we hold about you. An individual who seeks access, or who seeks to correct, amend, or delete inaccurate data transferred to the United States under Privacy Shield, should direct their query to&nbsp;
                    <a href='mailto:privacy@Valuatr.com'><StyledLink>privacy@Valuatr.com</StyledLink></a>. If requested to remove data, we will respond within a reasonable timeframe.
                </Text>
                <Text dark>We will provide an individual opt-out choice, or opt-in for sensitive data, before we share your data with third parties other than our agents, or before we use it for a purpose other than which it was originally collected or subsequently authorized. To request to limit the use and disclosure of your Personal Information, please submit a written request to&nbsp;
                    <a href='mailto:privacy@Valuatr.com'><StyledLink>privacy@Valuatr.com</StyledLink></a>.
                </Text>
                <Text dark>In certain situations, we may be required to disclose Personal Information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</Text>
                <Text dark>Valuatr’s accountability for Personal Information that it receives in the United States under the Privacy Shield and subsequently transfers to a third party is described in the Privacy Shield Principles. In particular, Valuatr remains responsible and liable under the Privacy Shield Principles if third-party agents that it engages to process the Personal Information on its behalf do so in a manner inconsistent with the Principles, unless Valuatr proves that it is not responsible for the event giving rise to the damage.</Text>
                <Text dark>In compliance with the Privacy Shield Principles, Valuatr commits to resolve complaints about your privacy and our collection or use of your Personal Information transferred to the United States pursuant to Privacy Shield. European Union and Swiss individuals with Privacy Shield inquiries or complaints should first contact Valuatr by email at&nbsp;
                <a href='mailto:info@Valuatr.ca'><StyledLink>info@Valuatr.ca</StyledLink></a>.
                </Text>
                <Text dark>Valuatr has further committed to refer unresolved privacy complaints under the Privacy Shield Principles to an independent dispute resolution mechanism, the BBB EU PRIVACY SHIELD, operated by the Council of Better Business Bureaus. If you do not receive timely acknowledgment of your complaint, or if your complaint is not satisfactorily addressed, please visit&nbsp;
                <a href='https://bbbprograms.org/programs/all-programs/bbb-eu-privacy-shield-consumers/ProcessForConsumers' target='_blank' rel='noreferrer'>
                    <StyledLink>www.bbb.org/EU-privacy-shield/for-eu-consumers</StyledLink>
                </a>&nbsp;
                for more information and to file a complaint. This service is provided free of charge to you.</Text>
                <Text dark>Valuatr commits to cooperate with the EU data protection authorities (DPAs), the UK Information Commissioner and the Swiss Federal Data Protection and Information Commissioner (FDPIC) and comply with the advice given by such authorities with regard to human resources data transferred from the EU, the UK and/or Switzerland, as applicable, in the context of the employment relationship with Valuatr.</Text>
                <Text dark>If your Privacy Shield complaint cannot be resolved through the above channels, under certain conditions, you may invoke binding arbitration for some residual claims not resolved by other redress mechanisms.&nbsp;
                    <a href='https://www.privacyshield.gov/article?id=ANNEX-I-introduction' target='_blank' rel='noreferrer'>
                        <StyledLink>See Privacy Shield Annex 1 here</StyledLink>
                    </a>
                </Text>

                <H2 small>California residents</H2>
                <Text dark>California law grants additional privacy rights to California residents. In particular, the California Consumer Privacy Act (CCPA) requires businesses to disclose, for the past 12 months, (i) the categories of personal information collected, (ii) the sources of the collected personal information, (iii) the purposes for which the collected personal information is used, (iv) the categories of personal information disclosed for a business purpose, and (v) the categories of any personal information sold. Valuatr provides these disclosures in the following table. Valuatr has not sold personal information in the past 12 months.</Text>
                <Text dark><b>Category</b>: Identifiers</Text>
                <Text dark><b>Sources of Collection</b>: Website visits and registration for Valuatr Services</Text>
                <Text dark><b>Purposes of Collection</b>: To allow use of Valuatr Services and to enable Valuatr to communicate with you</Text>
                <Text dark><b>Disclosures for a Business Purpose</b>: To Valuatr service providers for the purpose of providing Valuatr Services to you</Text>
                <Text dark><b>Category</b>: Personal information categories listed in the California Customer Records statute</Text>
                <Text dark><b>Sources of Collection</b>: Registration for Valuatr Services</Text>
                <Text dark><b>Purposes of Collection</b>: Credit card information to permit payment for premium Valuatr Services</Text>
                <Text dark><b>Disclosures for a Business Purpose</b>: To Valuatr service providers to facilitate payment transactions</Text>
                <Text dark><b>Category</b>: Internet or other similar network activity</Text>
                <Text dark><b>Sources of Collection</b>: Your browsing and search history on the Valuatr Site</Text>
                <Text dark><b>Purposes of Collection</b>: To improve the visitor experience on the Valuatr Site, diagnose server problems and administer the Valuatr Site</Text>
                <Text dark><b>Disclosures for a Business Purpose</b>: To marketing specialist companies for the purpose of enhancing the Valuatr Site and improving the effectiveness of our advertising</Text>
                <Text dark>California residents also have the rights described below. We will not discriminate against any California resident who exercises these rights.</Text>
                <Text dark><em>Right to access/know.</em> You may request from us a list of (i) the personal information that we have collected about you, and (ii) the categories of third parties to whom we have disclosed your personal information. You have the right to up to two (2) access requests each twelve (12) months.</Text>
                <Text dark><em>Right to delete your personal information.</em> You may request, at any time, that we delete your personal information.</Text>
                <Text dark>
                    You may contact us to exercise these rights at&nbsp;
                    <a href='mailto:privacy@Valuatr.com'><StyledLink>privacy@Valuatr.com</StyledLink></a>. 
                    To ensure the privacy and protection of individuals, we are required to verify your identity or otherwise authenticate your request(s). Please note that, under the CCPA, we are not required to grant a request to access/know or a request to delete with respect to personal information obtained from you in your role as an employee, owner, director, officer or contractor of a company and within the context of Valuatr providing the Valuatr Services to such company.</Text>
                
                <H2 small>Third Party Websites and Services</H2>
                <Text dark>The Valuatr Properties may contain links to third party websites or services, including Third Party Services, (collectively, “Third Party Sources”) who may collect Personal Information and Non-Personal Information directly from you. Links to Third Party Sources are intended for convenience only. Third Party Sources are wholly independent from Valuatr. Third Party Source may have separate privacy policies and data collection practices, independent of Valuatr. Valuatr: (a) has no responsibility or liability for these independent policies or actions; (b) is not responsible for the privacy practices or the content of such websites; and (c) does not make any warranties or representations about the contents, products or services offered on such websites or the security of any information you provide to them.</Text>
                
                <H2 small>Changes to this Privacy Policy</H2>
                <Text dark>The terms in this Privacy Policy may be changed from time to time, so you should review it periodically for changes. We reserve the right, at any time, to modify or replace this Privacy Policy. The date of the most recent version of the Privacy Policy is noted below under “Effective Date of this Privacy Policy.” We may also notify you via email or other direct electronic communication method of any changes that, in our sole discretion, materially impact your use of the Valuatr Properties or the treatment of your Personal Information. Your use of the Valuatr Properties following the posting of any changes to the Privacy Policy constitutes acceptance of those changes.</Text>

                <H2 small>How to contact us</H2>
                <Text dark>If you have any questions or concerns about this Privacy Policy or our privacy practices, you may contact us directly as follows:</Text>
                <Text dark>Email us: <a href='mailto:info@Valuatr.com'><StyledLink>info@Valuatr.com</StyledLink></a></Text>
                <Text dark>
                    If you are a resident of the European Union, and you believe that our processing of your Personal Information is inconsistent with your data protection rights under the GDPR and we have not adequately addressed your concerns, you have the right to lodge a complaint with the data protection supervisory authority of your country.&nbsp;
                    <a href='https://edpb.europa.eu/about-edpb/about-edpb/members_en' target='_blank' rel='noreferrer'>
                        <StyledLink>Current list of National Data Protection Authorities and members of the European Data Protection Board found here</StyledLink>
                    </a>.
                </Text>

            </Main.Inner>
        </Main.Section>
    </Main>
    <Footer />
</>


export default PrivacyPolicy
