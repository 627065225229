import React from 'react';
import { Input, InputRadio, EditTitle } from '../../components';
import { FieldsContainer } from '../../components/containers';
import CashflowYearwise from './CashflowYearwise';

const ProjectFinance4 = ({ data, handleChange }) => <>

  <FieldsContainer>
    <EditTitle
      value={data.companyName}
      onChange={handleChange}
    />
  </FieldsContainer>

  <FieldsContainer>
    <InputRadio
      required
      name="fixedCashFlow"
      label="Do you have fixed cash flows each year?"
      onChange={handleChange}
      value={data.fixedCashFlow}
    />

    {data.fixedCashFlow === 'Yes' ? (
      <Input
        name="cashFlowPerPeriod"
        label="Cash flow per year"
        onChange={handleChange}
        value={data.cashFlowPerPeriod}
        required
      />
    ) : (
      <InputRadio
        name="fixedGrowth"
        label="Does your cash flow have a fixed growth rate?"
        onChange={handleChange}
        value={data.fixedGrowth}
      />
    )}

    {data.fixedCashFlow === 'No' && data.fixedGrowth === 'Yes' && (
      <div>
        <Input
          name="cashFlowInYear1"
          label="Cash flow in year 1"
          onChange={handleChange}
          value={data.cashFlowInYear1}
        />
        <Input
          name="growthRate"
          label="Growth rate %"
          onChange={handleChange}
          value={data.growthRate}
        />
      </div>
    )}

    {data.fixedCashFlow === 'No' && data.fixedGrowth === 'No' && (
      <CashflowYearwise
        name="cashFlowYearwise"
        onChange={handleChange}
        data={data}
      />
    )}
  </FieldsContainer>
</>;

export default ProjectFinance4;
