import React from 'react';
import { Input } from '../..';

const FormFields = ({ handleChange, formData }) => <>
    
  <Input
    label="Old Password"
    name="oldPassword"
    type='password'
    required
    placeholder={null}
    onChange={handleChange}
    value={formData.oldPassword}
  />
  <Input
    label="New Password"
    name="password"
    type='password'
    required
    placeholder={null}
    onChange={handleChange}
    value={formData.password}
  />

</>


export default FormFields;
