import React, { useState } from 'react';
import { useFinancials } from '../../../context/Financials';
import { Hidden, Label } from '../../../components/elements';
import { Table } from './style'
import { CompanyName } from '../../../components';

const TableFrame = ({ caption, children }) => {
  const { reports } = useFinancials();
  const [year1, year2, year3] = reports.incomeStatement || reports.balanceSheet;
  const years = [
    year1.date.slice(0, 4) * 1, 
    year2.date.slice(0, 4) * 1, 
    year3.date.slice(0, 4) * 1
];
  const company = reports.companyQuote;
  const [active, setActive] = useState(years[0]);

  const handlePrevious = _ => setActive(
    active < years[0]? active + 1 : years[0]
    );
  const handleNext = _ => setActive(
    active > years[2]? active - 1 : years[2]
    );

  return (
    <Table active={
        active === years[1]? 'second' :
        active === years[2]? 'third' : 'first'
      }
    >
      <Hidden.Caption>{caption}</Hidden.Caption>
      <Table.Head>
        <Table.HeadRow>
          <Table.HeadCell>
            <Label>Company Name</Label>
            <h3>
              <CompanyName name={company.name} symbol={company.symbol} />
            </h3>
          </Table.HeadCell>
            <Table.HeadCell>
              <Label>Year</Label>
              <Table.Button.Wrapper>
                <strong>{years[0]}</strong>
                <Table.Button.Right onClick={() => handleNext()}>&#x25B6;&#xFE0E;</Table.Button.Right>
              </Table.Button.Wrapper>
            </Table.HeadCell>
            <Table.HeadCell>        
              <Label>Year</Label>
              <Table.Button.Wrapper>
                <Table.Button.Left onClick={() => handlePrevious()}>&#x25C0;&#xFE0E;</Table.Button.Left>
                <strong>{years[1]}</strong>
                <Table.Button.Right onClick={() => handleNext()}>&#x25B6;&#xFE0E;</Table.Button.Right>
              </Table.Button.Wrapper>
            </Table.HeadCell>
            <Table.HeadCell>  
              <Label>Year</Label>
              <Table.Button.Wrapper>
                <Table.Button.Left onClick={() => handlePrevious()}>&#x25C0;&#xFE0E;</Table.Button.Left>
                <strong>{years[2]}</strong>
              </Table.Button.Wrapper>
            </Table.HeadCell>
        </Table.HeadRow>
      </Table.Head>
      <tbody>{children}</tbody>
    </Table>
  );
};

export default TableFrame;
