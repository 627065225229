import React from 'react';
import Socials from './Socials';
import { H1 } from '../../components/elements';
import { Main } from '../../components/containers';
import { Header, SimpleFormSchema } from '../../components';
import { Email } from '../../assets/svgComponents';
import { schema } from './schema';
import { Styled } from './style';

const Contact = () => {
  return (
    <>
      <Header />
      <Main>
        <Styled.Section>
          <Styled.Inner>

            <Styled.Row>
              <Styled.Info>
                <div>
                  <H1.Underlined large white>
                    Get in touch!
                  </H1.Underlined>
                  <Styled.Text>
                      Fill up the form and our team will get back go you as soon as
                      possible.
                  </Styled.Text>
                  <p>
                    <Styled.Link href="mailto:info@valuatr.ca">
                      <Email />
                      info@valuatr.ca
                    </Styled.Link>
                  </p>
                </div>
                <Socials />
              </Styled.Info>
              <Styled.Card>
                <SimpleFormSchema schema={schema} />
              </Styled.Card>
            </Styled.Row>

            <Styled.Copyright>Copyright © 2021 Valuatr Inc. All Rights Reserved.</Styled.Copyright>

          </Styled.Inner>
        </Styled.Section>
      </Main>
    </>
  );
};

export default Contact;
