import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

// Tabs with buttons
const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #d4d4d4;
`;

Tabs.Tab = styled.button`
  font-size: 1em;
  font-weight: 500;
  color: ${props => (props.active ? '#366EDA' : '#636363')};
  padding: 0.8em 1em;
  margin-bottom: -1px;
  border: 1px solid ${props => (props.active ? '#D4D4D4' : 'transparent')};
  display: block;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color: transparent;
  border-bottom-color: ${props => props.active && '#FFF'};
`;

// Tabs as NavLinks
Tabs.Wrapper = styled.ul`
  display: flex;
  border-bottom: 1px solid #d4d4d4;
`;

Tabs.Link = styled(NavLink)`
  font-size: 1rem;
  font-weight: 500;
  color: #636363;
  padding: 0.8em 1em;
  margin-bottom: -1px;
  border: 1px solid transparent;
  display: block;
  background-color: transparent;
  &.active {
    color: #366eda;
    border: 1px solid #d4d4d4;
    border-bottom: 1px solid #fff;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 0.875rem;
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.75rem;
    line-height: 1.3;
    padding-bottom: 0.75em;
  }
  @media only screen and (max-width: 480px) {
    font-size: 0.675rem;

  }
`;

export default Tabs;
