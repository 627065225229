import { StyleSheet } from '@react-pdf/renderer';

const stylesPDF = StyleSheet.create({
    page: {
      fontSize: 9,
      paddingHorizontal: '5vw',
      paddingVertical: '8vw',
      color: '#585858'
    },
    title: {
      fontSize: 15,
      color: '#000',
      marginBottom: 30,
      textAlign: 'center'
    },
    headingRow: {
      flexDirection: 'row',
      width: '90vw',
      color: '#000',
      paddingVertical: 5
    },
    border: {
      width: '100%',
      borderBottomWidth: 1,
      borderColor: '#D4D4D4',
    },
    headingYear: {
      width: '15%',
      paddingVertical: 5,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      textAlign: 'center'
    },
    row: {
      flexDirection: 'row',
      width: '90vw',
      paddingVertical: 5
    },
    rowTitle: {
      width: '55%'
    },
    rowTitleBold: {
      width: '55%',
      color: '#000'
    },
    rowCell: {
      width: '15%',
      textAlign: 'right'
    },
    label: {
      color: '#366eda',
      marginBottom: 2,
      fontSize: 6
    },
    bold: {
      color: '#000'
    }
  });

  export default stylesPDF;