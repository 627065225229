import { noop } from '../../utils';
import PublicCompany1 from './PublicCompany1';
import PublicCompany2 from './PublicCompany2';
import PublicCompany3 from './PublicCompany3';

export const schema = {
  page1: {
    component: PublicCompany1,
    getPrevious: noop,
    getNext: () => 'page2',
    nextButton: 'Next',
  },
  page2: {
    component: PublicCompany2,
    getPrevious: () => 'page1',
    onNext: async (formData, setFormData, requestHandler, RequestMethods ) => {
      try {
        const getEnterpriseValue = requestHandler(
          RequestMethods.Post,
          `/${formData.model}`,
          formData
        );
        const getGraph = requestHandler(
          RequestMethods.Get,
          `companies/${formData.symbol}/statements-yearly`
        );
        const [enterpriseValue, graph] = 
          await Promise.allSettled([getEnterpriseValue, getGraph]);
          
        setFormData({ 
          ...formData, 
          ...enterpriseValue.value?.data,
          graph: graph.value?.data
        });
      } catch (error) {
        console.error(error);
      }
    },
    getNext: () => 'page3',
    nextButton: 'Generate Report',
  },
  page3: {
    component: PublicCompany3,
    getPrevious: () => 'page2',
    getNext: noop,
    nextButton: 'Done',
  },
  history: '/business-valuation/public-companies',
  hook: 'usePublicCompany'
};
