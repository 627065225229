import styled from 'styled-components';

const Container = styled.div`
  background-color: #fff;
  position: fixed;
  padding: 2rem 0 2rem 20vw;
  bottom: 0;
  z-index: 80;
  left: 0;
  width: 100%;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.2);
  > button {
    margin-right: 0.5em;
    box-shadow: none!important;
  }
  @media only screen and (max-width: 1024px) {
    padding: 1.5em 0 1.5em 5vw;
  } 
  @media only screen and (max-width: 600px) {
    padding: 1em 0 1em 5vw;
  } 
`;

export default Container;
