import FormFields from './FormFields';
import { requestClient } from '../../utils/requests';
import { StorageKeys } from '../../utils/storageKeys';

export const schema = {
  buttonText: 'Sign In',
  buttonStyle: { long: true },
  formFields: FormFields,
  formStyle: { center: true, width: 327 },
  apiCall: async (formData, setCurrentUser) => {
    try {
      const response = await requestClient.post('/login', formData);
      
      localStorage.setItem(StorageKeys.User, JSON.stringify(response?.data?.user));
      localStorage.setItem(StorageKeys.Token, `Bearer ${response?.data?.token}`);
      setCurrentUser(response?.data?.user);
      return 'Successfully logged in';   
    } catch (err) {
      console.log(err);
    }
  },
  default: {
    email: '',
    password: ''
  },
  history: '/dasboard'
};
