import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { PublicCompanies, PrivateCompany, ErrorPage } from '../';
import BusinessValuationLanding from './BusinessValuationLanding';
import { DashboardHeader as Header } from '../../components';
import { DashboardMain as Main } from '../../components/containers';

const BusinessValuation = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <Header title="Business Valuation" back="/dashboard" />
        <Switch>
          <Route exact path={path} component={BusinessValuationLanding} />
          <Route
              path={`${path}/public-companies`}
              component={PublicCompanies}
          />
          <Route
              exact path={`${path}/private-companies`}
              component={PrivateCompany}
          />
          <Route>
            <Main>
              <Main.Inner>
                <ErrorPage />
              </Main.Inner>
            </Main>
          </Route>
        </Switch>
    </>
  );
};

export default BusinessValuation;
