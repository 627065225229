import React from 'react';
import { Article as Styled } from './style';
import { defaultNewsImg } from '../../assets/images';

const Article = props => {
  const { link, category, title, text, minRead, author } = props;
  const image = props.image_url || props.images?.[0]?.url || defaultNewsImg;

  return (
    <Styled>
        <Styled.Image src={image} href={link} target="_blank" rel="noreferrer" />
        <div>
          <div>
            <Styled.Category cat={category}>{category}</Styled.Category>
            {minRead && <Styled.Note>&#8226; {minRead} Min Read</Styled.Note>}
          </div>
          <a href={link} target="_blank" rel="noreferrer">
            <Styled.Title>{title}</Styled.Title>
          </a>
          <Styled.Text>{text}</Styled.Text>
          {author && <Styled.Note>Created by: <em>{author}</em></Styled.Note>}
      </div>
    </Styled>
  );
};

export default Article;
