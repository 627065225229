import React from 'react';
import { Checkmark as Button } from './style';
import { CheckMark } from '../../assets/svgComponents';
import { useUserHistory } from '../../context';

const HistoryCheckmark = ({ id }) => {
    const { selection, handleClick } = useUserHistory();
    const checked = selection.includes(id);

    return (
        <Button 
            role="checkbox"
            aria-checked={checked}
            checked={checked}
            onClick={() => handleClick(id)}
        >
          <CheckMark />
        </Button>
    )
}

export default HistoryCheckmark
