import React, {  useState } from 'react';
import { Tabs } from '../../../components';
import { schema } from './schema';
import { Container, Tabs as Styled } from './style';

const Graph = () => {
    const [data, setData] = useState({
      '1 Day': null,
      '5 Days': null,
      '1 Month': null,
      '6 Months': null,
      '1 Year': null,
      '5 Years': null
    });

    return (
        <Container>
            <Tabs 
                schema={{...schema, style: Styled}} 
                data={data} 
                setData={setData}     
            />
        </Container>
    )
}

export default Graph
