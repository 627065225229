import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Aside = styled.aside`
  font-family: 'Roboto', sans-serif;
  position: fixed;
  z-index: 90;
  height: 100vh;
  width: 15vw;
  padding-left: 1%;
  background-color: rgba(54, 110, 218, 1);
  color: white;
  .invite-friend {
    cursor: pointer;
    background-color: #305fbb;
    border-radius: 6px;
    border: none;
    display: flex;
    align-items: center;
    font-size: 1em;
    padding: 0.8em 7%;
    position: absolute;
    bottom: 30px;
    width: 85%;
    svg {
      margin-right: 10%;
    }
  }
  @media only screen and (max-width: 1365px) {
    font-size: 0.875em;
    svg {
      padding: 0.2em;
    }
  }
  @media only screen and (max-width: 1024px) {
    display: none;
    width: 220px;
  }
`;

Aside.Logo = styled.div`
  height: 6rem;
  padding-top: 1.5em;
  text-align: center;
`;

Aside.ListItem = styled.li`
  width: 100%;
  ::marker {
    color: transparent;
  }
`;

Aside.NavLink = styled(NavLink)`
  display: flex;
  svg {
    margin-right: 10%;
  }
  &::after {
    content: '';
    display: block;
    width: 11px;
    height: 44px;
    border-radius: 5px 0 0 5px;
  }
  &.active span {
    background-color: white;
    color: #303030;
  }
  &.active path {
    stroke: #3d7bf2;
  }
  &.active::after {
    background: white;
  }
`;

Aside.Link = styled.span`
  font-weight: 500;
  font-size: 1em;
  display: flex;
  align-items: center;
  border-radius: 6px;
  min-height: 44px;
  width: 100%;
  margin-right: 6px;
  padding: 0 7%;
`;

export default Aside;
