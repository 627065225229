export const logo = require('./logo.svg').default;
export const logoWhite = require('./logo-white.svg').default;
export const logoBlueIcon = require('./logo-blue-icon.svg').default;
export const background = require('./bluebackground.svg').default;
export const accurate = require('./accurate.svg').default;
export const effective = require('./effective.svg').default;
export const convenient = require('./convenient.svg').default;
export const publicInvestments = require('./public-investments.jpg').default;
export const businessValuation = require('./business-valuation.jpg').default;
export const projectFinance = require('./project-finance.jpg').default;
export const sharePriceValuation = require('./share-price-valuation.jpg')
  .default;
export const stockOptionValuation = require('./stock-option-valuation.jpg')
  .default;
export const financialStrength = require('./financial-strength.jpg').default;
export const privateCompany = require('./private-company-business-valuation.jpg')
  .default;
export const publicCompany = require('./public-company-business-valuation.jpg')
  .default;

// images
export const smarterInvestments = require('./smarter-investments.svg').default;
export const accurateBussVal = require('./accurate-business-valuations.svg').default;
export const valueOfProject = require('./value-of-project.svg').default;
export const simplicity = require('../../assets/images/simplicity.svg').default;
export const growth = require('../../assets/images/growth.svg').default;
export const accuracy = require('../../assets/images/accuracy.svg').default;
export const login = require('./login.jpg').default;
export const signup = require('./signup.jpg').default;

// backgrounds
export const homeBanner = require('./banner.svg').default;
export const signUpBg = require('./sign-up-bg.svg').default;
export const homeSignupBg = require('./home-sign-up-bg.svg').default;
export const lineTop = require('./line-top.svg').default;
export const lineBottom  = require('./line-bottom.svg').default;
export const circleBg = require('./circle-bg.svg').default;
export const contactBg = require('./contact-bg.svg').default;

// news
export const defaultNewsImg = require('./news-image.jpg').default;
