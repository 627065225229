import React from 'react';
import { Input } from '../../components';
import { Container, FieldsContainer } from '../../components/containers';
import { info } from '../definitions';


const FinancialStrength1 = ({ data }) => (
  <Container top='2.5rem'>
    <FieldsContainer>
      <Input
        {...info.priceToEarningsRatio}
        readOnly
        value={data.priceToEarningsRatio}
      />
      <Input {...info.returnOnEquity} readOnly value={data.returnOnEquity} />
      <Input {...info.currentRatio} readOnly value={data.currentRatio} />
      <Input {...info.quickRatio} readOnly value={data.quickRatio} />
      <Input
        {...info.grossProfitMargin}
        readOnly
        value={data.grossProfitMargin}
      />
      <Input
        {...info.netProfitMargin}
        readOnly
        value={data.netProfitMargin}
      />
      <Input {...info.debtEquityRatio} readOnly value={data.debtEquityRatio} />
      <Input {...info.returnOnAssets} readOnly value={data.returnOnAssets} />
      <Input  {...info.debtToEBITDA} readOnly value={data.debtToEBITDA} />
    </FieldsContainer>
  </Container>
);

export default FinancialStrength1;
