import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from '../containers';
import { Text } from '../../components/elements';

const Breadcrumbs = ({ path, url }) => {

    return (
        <Container top='0.75em' bottom='1em'>
            <Text small color="#9D9D9D">
                <Link to="/dashboard">Dashboard</Link> /
                <Link to={url}> {path}</Link>
            </Text>
        </Container>
    )
}
export default Breadcrumbs;
