import React from 'react';
import { Link } from 'react-router-dom';
import { DashboardHeader } from '../../components';
import {
  DashboardMain as Main,
  DashboardCard as Card,
} from '../../components/containers';
import { Text, Heading } from '../../components/elements';
import {
  publicInvestments,
  businessValuation,
  projectFinance,
} from '../../assets/images';
import { useAuth } from '../../context/User';
import { timestampToDate } from '../../utils';
import Styled from './style';
import { Calendar } from '../../assets/svgComponents';

const Landing = () => {
  const { currentUser } = useAuth();
  const today = Date.now();

  return (
    <>
      <DashboardHeader title="Dashboard" />
      <Main grey>
        <Main.SectionHalfWhite>
          <Main.Inner>
            <Main.Title>Dashboard</Main.Title>
            <div>
              <Styled.Heading>
                <Heading>Welcome, {currentUser?.name}</Heading>
              </Styled.Heading>
              <Styled.Date><Calendar />{timestampToDate(today)}</Styled.Date>
              <Styled.Text large>
                What are we valuing today?
              </Styled.Text>
            </div>
            <Card.Container>
              <Card>
                <Link to="/dashboard/public-investments">
                  <Card.Image img={publicInvestments} />
                  <Card.Text>
                    <Card.Title>Public Investments</Card.Title>
                    <Text small>
                      Find the intrinsic value of share prices and stock
                      options. Complete ratio analysis to determine the
                      financial strength of a company.
                    </Text>
                  </Card.Text>
                </Link>
              </Card>
              <Card>
                <Link to="/dashboard/business-valuation">
                  <Card.Image img={businessValuation} />
                  <Card.Text>
                    <Card.Title>Business Valuation</Card.Title>
                    <Text small>
                      Find Intrinsic enterprise value for public companies. Have
                      your own business? Find out the enterprise value of your
                      private company!
                    </Text>
                  </Card.Text>
                </Link>
              </Card>
              <Card>
                <Link to="/dashboard/project-finance">
                  <Card.Image img={projectFinance} />
                  <Card.Text>
                    <Card.Title>Project Finance</Card.Title>
                    <Text small>
                      Thinking of taking on a new project? Find the value that
                      this project will bring to you.
                    </Text>
                  </Card.Text>
                </Link>
              </Card>
            </Card.Container>
          </Main.Inner>
        </Main.SectionHalfWhite>
      </Main>
    </>
  );
};

export default Landing;
