import React from 'react'
import { Input } from '../../components';
import { FieldsContainer } from '../../components/containers';
import { FieldSet } from './style';

const StockOptionValuation1 = ({ data, handleChange }) => {
    const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

    return (
        <FieldSet>
            <FieldSet.Legend>
                Closing Prices of the Last 15 Days
            </FieldSet.Legend>
            <FieldsContainer>
                {days.map(day => (
                    <Input 
                        key={day}
                        label={day + '.'}
                        aria-label={'Day ' + day}
                        name={'day' + day}
                        value={data['day' + day]}
                        onChange={handleChange} 
                        required
                    />
                ))}
                
            </FieldsContainer>
        </FieldSet>
    )
}

export default StockOptionValuation1
