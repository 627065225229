import styled from 'styled-components';

const Info = styled.div`
  padding: 10px 5px;
  position: absolute;
  bottom: 0;
  left: 170px;
`;

Info.Button = styled.button.attrs({
  title: 'Info',
  type: 'button',
})`
  border-radius: 100%;
  border: 2px solid #929292;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: transparent;
  span {
    position: absolute;
  }
`;

Info.Popup = styled.div`
  background: #343a40;
  font-size: 0.75em;
  color: #fff;
  margin-top: 10px;
  position: absolute;
  z-index: 70;
  padding: 0.75em 1em;
  border-radius: 4px;
  left: -80px;
  width: 180px;

`;

Info.Tip = styled.span`
  border: 8px solid transparent;
  border-top: none;
  border-bottom-color: #343a40;
  position: absolute;
  top: -8px;
  left: calc(80px + 1em);
  z-index: 71;
`;

export default Info;
