import React, { useEffect, useState } from 'react';
import { Input, EditTitle } from '../../components';
import { FieldsContainer } from '../../components/containers';
import { info } from '../definitions';

const PrivateCompany3 = ({ data, handleChange }) => {
  const [readOnly, setReadOnly] = useState();

  useEffect(() => {
      setReadOnly(
        data.model === 'asset-based-approach' ? true : false
      );
    // eslint-disable-next-line
  }, [])

  return <>

    <FieldsContainer>
      <EditTitle
        value={data.companyName}
        onChange={handleChange}
      />
    </FieldsContainer>

    <FieldsContainer>
      <Input
        {...info.currentAssets}
        readOnly={readOnly}
        required
        value={data.currentAssets}
        onChange={handleChange}
      />
      <Input
        {...info.currentLiabilities}
        readOnly={readOnly}
        required
        value={data.currentLiabilities}
        onChange={handleChange}
      />
      <Input
        {...info.inventory}
        required
        value={data.inventory}
        onChange={handleChange}
      />
      <Input
        {...info.longTermDebt}
        required
        value={data.longTermDebt}
        onChange={handleChange}
      />
      <Input
        {...info.shortTermDebt}
        required
        value={data.shortTermDebt}
        onChange={handleChange}
      />
      <Input
        {...info.bankIndebtedness}
        required
        value={data.bankIndebtedness}
        onChange={handleChange}
      />
      <Input
        {...info.equityAttributableToShareholders}
        required
        value={data.equityAttributableToShareholders}
        onChange={handleChange}
      />
      <Input
        {...info.revenue}
        required
        value={data.revenue}
        onChange={handleChange}
      />
      <Input
        {...info.grossMargin}
        required
        value={data.grossMargin}
        onChange={handleChange}
      />
      <Input
        {...info.netProfit}
        required
        value={data.netProfit}
        onChange={handleChange}
      />
      <Input
        {...info.totalAssets}
        required
        value={data.totalAssets}
        onChange={handleChange}
      />
      <Input
        {...info.totalDebt}
        required
        value={data.totalDebt}
        onChange={handleChange}
      />
      <Input
        {...info.EBITDA}
        required
        value={data.EBITDA}
        onChange={handleChange}
      />
    </FieldsContainer>

  </>
}

export default PrivateCompany3;
