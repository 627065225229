import styled from 'styled-components';

const Company = {};

Company.Name = styled.span`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  color: #152536;
  vertical-align: top;
  @media only screen and (max-width: 1024px) {
    font-size: 1rem;
  }
`;

Company.Symbol = styled.span`
  color: #999;
  font-size: 1.25rem;
  vertical-align: top;
  @media only screen and (max-width: 1024px) {
    font-size: 1rem;
  }
`;

export default Company;
