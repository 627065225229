import React from 'react';
import { Link } from 'react-router-dom';
import HistoryCheckmark from './HistoryItemCheckmark';
import { Table } from './style';

const urls = {
  'Share Price Valuation':
    '/dashboard/public-investments/share-price-valuation',
  'Stock Option Valuation':
    '/dashboard/public-investments/stock-option-valuation',
  'Financial Strength': '/dashboard/public-investments/financial-strength',
  'Financials': '/financials',
  'Public Companies': '/dashboard/business-valuation/public-companies'
};

const HistoryItem = ({ name, symbol, model, _id }) => {
  const url = `${urls[model]}/${symbol}`;

  return (
    <Table.Row>
      <Table.Cell>
        <HistoryCheckmark id={_id} />
      </Table.Cell>
      <Table.Cell dark>
      <Link to={url}>       
          {name}
          <span>{symbol}</span>    
      </Link>
      </Table.Cell>
      <Table.Cell>
        {model}
      </Table.Cell>
    </Table.Row>
  );
};

export default HistoryItem;
