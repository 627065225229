import React, { createContext, useContext, useState } from 'react';

const PrivateCompanyContext = createContext();

const usePrivateCompany = _ => useContext(PrivateCompanyContext);

const PrivateCompanyContextProvider = ({ children }) => {
  const [data, setData] = useState({ 
    calculateFinancialStrength: false ,
    model: ''
  });

  return (
    <PrivateCompanyContext.Provider value={{ data, setData }}>
      {children}
    </PrivateCompanyContext.Provider>
  );
};

export { usePrivateCompany, PrivateCompanyContextProvider };
