import styled from 'styled-components';

const Price = styled.p`
    font-size: 0.875em;
    color: #b8b8b8;
`;

Price.Number = styled.b`
    font-size: 1.4em;
    color: #464646;
`;

Price.Change = styled.span`
    color: ${props => props.rise? '#34AF92' : '#AB1717'};
    margin-left: 0.5em;
    font-size: 0.875em;
`;

Price.Time = styled.p`
    font-size: 0.75em;
    color: #b8b8b8;
`;

export { Price, }