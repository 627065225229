import React from 'react';
import { InviteFriend } from '../../assets/svgComponents';

const ModalButton = ({ onClick }) => (
   
    <button 
        onClick={onClick}
        className='invite-friend'
    >
        <InviteFriend />
        Invite a Friend
    </button>
)

export default ModalButton
