import React from 'react';
import { Input } from '../..';

const FormFields = ({ handleChange, formData }) => <>

  <Input
    label="Name"
    name="name"
    type="text"
    required
    onChange={handleChange}
    value={formData.name}
    placeholder={null}
  />
  <Input
    label="Email"
    name="email"
    type="email"
    required
    onChange={handleChange}
    value={formData.email}
    placeholder={null}
  />
</>


export default FormFields;
