import React from 'react';
import { Main } from '../../components/containers';
import { H1, TextOpenSans, Text } from '../../components/elements';
import About from './style';

const Section1 = () => {
  return (
    <Main.Section gray>
      <About.Inner>
        <H1.Underlined center>Our Mission</H1.Underlined>
        <TextOpenSans>Our Vision</TextOpenSans>
        <Text bold font={1.5} color='#000'>
          We are passionate about giving people access to tools that will make
          them feel more confident about where they put their money.
        </Text>
        <TextOpenSans large>
          At Valuatr, we believe every investor deserves accurate and effective
          knowledge to support their investment decisions. Our goal is to
          provide structured and simplified valuation models to all of our users
          - from beginner investors to large investors to small firms. It is our
          mission to provide the people with financial models in a simple and
          easy-to-understand format.
        </TextOpenSans>
      </About.Inner>
    </Main.Section>
  );
};

export default Section1;
