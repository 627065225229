import React, { useEffect, useState } from 'react';
import { CompanyName } from '..';
import { EditTitle as Icon } from '../../assets/svgComponents';
import { Container } from '../containers';
import { Hidden, Label } from '../elements';
import Styled from './style';

const EditTitle = ({ value, onChange, readOnly }) => {
  const [edit, setEdit] = useState();

  useEffect(() => {
    setEdit(value ? false : true);
    // eslint-disable-next-line
  }, []);

  return (

    edit
      ? <Container top='2rem' bottom='3rem'>
        <Label bottom='' htmlFor='companyName'>
          Company Name
        </Label>
        <Styled.Input
          label='Company Name'
          name='companyName'
          id='companyName'
          placeholder='Enter company name'
          value={value}
          onChange={onChange}
          readOnly={readOnly}
        />
      </Container>
      : <Container top='2rem' bottom='3rem'>
        <Label>Company Name</Label>
        <Styled.Title><CompanyName name={value} /></Styled.Title>
        {!readOnly &&
          <Styled.Button title="Edit Company's name" onClick={() => setEdit(true)}>
            <Hidden.Span>Edit Company's Name</Hidden.Span>
            <Icon />
          </Styled.Button>
        }
      </Container>
  );
};

export default EditTitle;
