import React, { useEffect } from 'react';
import { useFinancials } from '../../context/Financials';
import { useParams } from 'react-router-dom';
import { schema } from './schema';
import { ApiFetchSchema, ReportNotFound } from '../../components';
import { RequestMethods, useRequest } from '../../utils/requests';
import { useAuth } from '../../context';
import Loading from '../../hoc/loading/Loading';

const Company = ({ setLoading }) => {
  const requestHandler = useRequest();
  const { symbol } = useParams();
  const { setReports, reports } = useFinancials();
  const { addToHistory } = useAuth();

  useEffect(() => {

    const getRequest = async _ => {
      setLoading(true);

      try {
        const response = await requestHandler(
          RequestMethods.Get,
          `/company-quote/${symbol}`
        );

        if (!response.data?.companyQuote) throw new Error('No available data.');

        addToHistory({
          symbol,
          name: response.data.companyQuote.name,
          exchange: response.data.companyQuote.exchange,
          model: 'Financials'
        });

        setReports(response.data);
      } catch (err) {
        console.error(err);
        setReports(null);
      }

      setLoading(false);
    };

    getRequest();

      // eslint-disable-next-line
  }, [symbol, setReports]);

  if (!reports) return <ReportNotFound />;

  return <ApiFetchSchema schema={schema} />;
};

export default Loading(Company, `Loading company's reports...`);
