import styled from 'styled-components';

const Button = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  background-color: transparent;
  border: none;
  font-size: 1em;
  color: #366eda;
  padding: 0.5em 0;
  cursor: pointer;
`;

export default Button;
