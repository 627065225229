import React, { createContext, useContext, useState } from 'react';

const FinancialStrengthContext = createContext();

const useFinancialStrength = _ => useContext(FinancialStrengthContext);

const FinancialStrengthContextProvider = ({ children }) => {
  const [data, setData] = useState({
    returnOnEquity: '',
    currentRatio: '',
    quickRatio: '',
    grossProfitMargin: '',
    netProfitMargin: '',
    debtEquityRatio: '',
    returnOnAssets: '',
    priceToEarningsRatio: '',
    debtToEBITDA: ''
  });

  return (
    <FinancialStrengthContext.Provider value={{ data, setData }}>
      {children}
    </FinancialStrengthContext.Provider>
  );
};

export { useFinancialStrength, FinancialStrengthContextProvider };
