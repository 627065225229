import styled from 'styled-components';

const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    padding-top: 1em;
    max-width: 90vw;
    margin: auto;
    padding: ${ props => props.padding };
    width: ${ props => props.width && props.width + 'px'};
    justify-content: ${ props => props.center && 'center'};
`;

export default Form;