import React from 'react';
import Graph from './Graph';
import { Container } from './style';
import IntristicValue from './IntristicValue';

const SharePriceValuation3 = ({ data, setData }) => {

  return (
    <Container>
      <div>
        <IntristicValue data={data} setData={setData} symbol={data.symbol} />
      </div>
      {data.graph && <Graph data={data.graph} />}
    </Container>

  );
}


export default SharePriceValuation3;
