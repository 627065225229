import React from 'react';
import { Link } from 'react-router-dom';
import { logo, logoWhite, logoBlueIcon } from '../../assets/images';

const Logo = ({ white, iconOnly }) => (
  <Link to="/">
    <img
      src={(white && logoWhite) || (iconOnly && logoBlueIcon) || logo}
      alt="Valuatr"
      title="Home"
      width={iconOnly? '22px' : "110px"}
      height="27px"
    />
  </Link>
);

export default Logo;
