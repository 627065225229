import React from 'react'
import { Text, ButtonLink } from '../elements'
import { Container } from '../containers';
import { useHistory } from 'react-router';

const ReportNotFound = ({ text }) => {
    const history = useHistory();

    return (
        <Container top='2em'>
            <Text dark>
                {text? 
                    text : 
                    'Sorry, we are unable to retrieve financial statements for this company.'
                }
            </Text>
            <ButtonLink onClick={() => history.goBack()}>Go Back</ButtonLink>
        </Container>
    )
}

export default ReportNotFound
