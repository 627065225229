import styled from 'styled-components';
import { Text } from '../../components/elements';

const Styled = {};

Styled.Heading = styled.p`
    padding-top: 3em;
`;

Styled.Date = styled.p`
    svg {
        vertical-align: sub;
        margin-right: 0.5em;
    }
`;

Styled.Text = styled(Text)`
    margin-bottom: 3%;
    margin-top: 0.5em;  
`;

export default Styled;