import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Main, Ul } from '../containers';
import { Button } from '../elements';

const Header = styled.header`
  font-family: 'Roboto', sans-serif;
  position: fixed;
  z-index: 90;
  width: 100%;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  @media only screen and (max-width: 375px) {
    font-size: 0.875em;
  }
`;

Header.Inner = styled(Main.Inner)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  @media only screen and (max-width: 800px) {
    > nav, .desktop {
      display: none;
    }
  }
`;

Header.Logo = styled.div`
  > :last-child {
    display: none;
  }
  @media only screen and (max-width: 500px) {
    > :first-child {
      display: none;
    }
    > :last-child {
      display: block;
    }
  }
`;

Header.Menu = styled(Ul)`
  display: flex;
  flex-direction: ${props => props.mobile && 'column'};
  color: #656565;
  font-size: ${props => !props.mobile && '0.875em'};
`;

Header.Link = styled(Link)`
  display: inline-block;
  padding: 1em 1.5em;
  text-decoration: none;
  width: 100%;
`;

Header.Button = styled(Button)`
  font-size: 0.875em;
  @media only screen and (max-width: 374px) {
    font-size: 0.75em;
    min-width: 1px;
  }
`;

Header.Secondary = styled.button`
  background-color: #FFF;
  border: none;
  font-size: 0.875em;
  font-weight: 700;
  margin-right: 1em;
  cursor: pointer;
  &:hover {
    color: #366EDA;
  }
`;

Header.Container = styled.div`
  display: flex;
  align-items: center;
`;

Header.Text = styled.p`
  color: #366EDA;
  font-size: 0.875em;
  font-weight: 500;
  margin-right: 1em;
  font-weight: bold;
  @media only screen and (max-width: 374px) {
    margin-left: 0.5em;
    width: 22px;
  }
`;

Header.Mobile = styled.div`
  min-width: 75vw;
`;

Header.Mobile.Button = styled.button`
  cursor: pointer;
  width: 100%;
  color: #fff;
  background-color: #366eda;
  border: none;
  box-shadow: none;
  padding: 1em 1.5em;
  font-size: 1em;
  text-align: left;
  display: flex;
  svg:first-child {
    border: 1px solid white;
    border-radius: 100%;
    padding: 1px;
  }
  > :last-child {
    margin-left: 1em;
  }
`;

Header.Mobile.Line = styled.hr`
  margin-top: 2em;
  margin-bottom: 1em;
  + div {
    padding-left: 1em;
  }
  + div a {
    background-color: transparent;
  }
  + div svg path {
    fill: #234292;
  }
`;

export default Header;
