import React from 'react';
import { Input, InputTextarea } from '../';

const ModalForm = ({ handleChange, formData }) => <>

    <Input
        label="Friend's email"
        name="friendsEmail"
        type="email"
        placeholder=""
        required
        onChange={handleChange}
        value={formData.friendsEmail}
    />
    <InputTextarea
        label="Your message"
        name="message"
        required
        onChange={handleChange}
        value={formData.message}
    />
</>

export default ModalForm
