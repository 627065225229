import React from 'react';
import { Input } from '../../components';
import { FieldsContainer, Container } from '../../components/containers';
import { info } from '../definitions';

export const DiscountCashflowModel = ({ data, handleChange }) => (
  <FieldsContainer>
    <Input
      {...info.terminalGrowthRate}
      value={data.terminalGrowthRate}
      required
      onChange={handleChange}
    />
    <Input
      {...info.growthRate}
      value={data.growthRate}
      required
      onChange={handleChange}
    />
    <Input
      {...info.revenue}
      value={data.revenue}
      required
      onChange={handleChange}
    />
    <Input
      {...info.costOfGoodsSold}
      value={data.costOfGoodsSold}
      required
      onChange={handleChange}
    />
    <Input
      {...info.CCARate}
      value={data.CCARate}
      required
      onChange={handleChange}
    />
    <Input
      {...info.operatingExpenses}
      value={data.operatingExpenses}
      required
      onChange={handleChange}
    />
    <Input
      {...info.interestExpense}
      value={data.interestExpense}
      required
      onChange={handleChange}
    />
    <Input
      {...info.otherIncome}
      value={data.otherIncome}
      required
      onChange={handleChange}
    />
    <Input
      {...info.otherExpenses}
      value={data.otherExpenses}
      required
      onChange={handleChange}
    />
    <Input
      {...info.taxRate}
      value={data.taxRate}
      required
      onChange={handleChange}
    />
    <Input
      {...info.capitalExpenditure}
      value={data.capitalExpenditure}
      required
      onChange={handleChange}
    />
    <Input
      {...info.workingCapital}
      value={data.workingCapital}
      required
      onChange={handleChange}
    />
    <Input
      {...info.totalDebt}
      value={data.totalDebt}
      required
      onChange={handleChange}
    />
  </FieldsContainer>
);

export const CapitalizedEarningsModel = ({ data, handleChange }) => <>
  <fieldset>
    <legend>Last Year</legend>
    <FieldsContainer>
    <Input
      {...info.earningsBeforeTaxYear1}
      value={data.earningsBeforeTaxYear1}
      required
      onChange={handleChange}
    />
    <Input
      {...info.interestExpenseYear1}
      value={data.interestExpenseYear1}
      required
      onChange={handleChange}
    />
        <Input
      {...info.amortizationYear1}
      value={data.amortizationYear1}
      required
      onChange={handleChange}
    />
    </FieldsContainer>
  </fieldset>
  
  <Container top='1rem'/>
  <fieldset>
    <legend>Second Last Year</legend>
    <FieldsContainer>
    <Input
      {...info.earningsBeforeTaxYear2}
      value={data.earningsBeforeTaxYear2}
      onChange={handleChange}
    />
    <Input
      {...info.interestExpenseYear2}
      value={data.interestExpenseYear2}
      onChange={handleChange}
    />
        <Input
      {...info.amortizationYear2}
      value={data.amortizationYear2}
      onChange={handleChange}
    />
    </FieldsContainer>
  </fieldset>
  <Container top='1rem'/>
  <fieldset>
  <legend>Third Last Year</legend>
  <FieldsContainer>
    <Input
      {...info.earningsBeforeTaxYear3}
      value={data.earningsBeforeTaxYear3}
      onChange={handleChange}
    />
    <Input
      {...info.interestExpenseYear3}
      value={data.interestExpenseYear3}
      onChange={handleChange}
    />
        <Input
      {...info.amortizationYear3}
      value={data.amortizationYear3}
      onChange={handleChange}
    />
    </FieldsContainer>
  </fieldset>
  <Container top='1rem'/>
  <FieldsContainer>
   
    <Input
      {...info.totalDebt}
      value={data.totalDebt}
      required
      onChange={handleChange}
    />
    <Input
      {...info.weightOfDebt}
      value={data.weightOfDebt}
      required
      onChange={handleChange}
    />
    <Input
      {...info.taxRate}
      value={data.taxRate}
      required
      onChange={handleChange}
    />
  </FieldsContainer>
</>;

export const AssetBasedApproach = ({ data, handleChange }) => (
  <FieldsContainer>
    <Input
      {...info.currentAssets}
      value={data.currentAssets}
      required
      onChange={handleChange}
    />
    <Input
      {...info.nonCurrentAssets}
      value={data.nonCurrentAssets}
      required
      onChange={handleChange}
    />
    <Input
      {...info.currentLiabilities}
      value={data.currentLiabilities}
      required
      onChange={handleChange}
    />
    <Input
      {...info.nonCurrentLiabilities}
      value={data.nonCurrentLiabilities}
      required
      onChange={handleChange}
    />
  </FieldsContainer>
);
