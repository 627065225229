import React, { createContext, useContext, useState } from 'react';

const SOVContext = createContext();

const useSOV = _ => useContext(SOVContext);

const SOVContextProvider = ({ children }) => {
  const [data, setData] = useState({
    type: '',
    prices: []
  });

  return (
    <SOVContext.Provider value={{ data, setData }}>
      {children}
    </SOVContext.Provider>
  );
};

export { useSOV, SOVContextProvider };
