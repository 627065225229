import React from 'react'

const Delete = () => (

    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Delete</title>
        <path d="M4.21214 5.99469C4.3518 5.99469 4.48575 6.05017 4.5845 6.14893C4.68326 6.24768 4.73874 6.38162 4.73874 6.52129V12.8404C4.73874 12.9801 4.68326 13.114 4.5845 13.2128C4.48575 13.3115 4.3518 13.367 4.21214 13.367C4.07248 13.367 3.93854 13.3115 3.83978 13.2128C3.74103 13.114 3.68555 12.9801 3.68555 12.8404V6.52129C3.68555 6.38162 3.74103 6.24768 3.83978 6.14893C3.93854 6.05017 4.07248 5.99469 4.21214 5.99469ZM6.84512 5.99469C6.98478 5.99469 7.11873 6.05017 7.21748 6.14893C7.31624 6.24768 7.37172 6.38162 7.37172 6.52129V12.8404C7.37172 12.9801 7.31624 13.114 7.21748 13.2128C7.11873 13.3115 6.98478 13.367 6.84512 13.367C6.70546 13.367 6.57152 13.3115 6.47276 13.2128C6.37401 13.114 6.31853 12.9801 6.31853 12.8404V6.52129C6.31853 6.38162 6.37401 6.24768 6.47276 6.14893C6.57152 6.05017 6.70546 5.99469 6.84512 5.99469ZM10.0047 6.52129C10.0047 6.38162 9.94922 6.24768 9.85046 6.14893C9.7517 6.05017 9.61776 5.99469 9.4781 5.99469C9.33844 5.99469 9.2045 6.05017 9.10574 6.14893C9.00698 6.24768 8.9515 6.38162 8.9515 6.52129V12.8404C8.9515 12.9801 9.00698 13.114 9.10574 13.2128C9.2045 13.3115 9.33844 13.367 9.4781 13.367C9.61776 13.367 9.7517 13.3115 9.85046 13.2128C9.94922 13.114 10.0047 12.9801 10.0047 12.8404V6.52129Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.6915 3.36172C13.6915 3.64105 13.5805 3.90893 13.383 4.10644C13.1855 4.30395 12.9176 4.41491 12.6383 4.41491H12.1117V13.8936C12.1117 14.4523 11.8898 14.9881 11.4948 15.3831C11.0997 15.7781 10.564 16 10.0053 16H3.68617C3.12752 16 2.59176 15.7781 2.19673 15.3831C1.80171 14.9881 1.57979 14.4523 1.57979 13.8936V4.41491H1.05319C0.773868 4.41491 0.505984 4.30395 0.308473 4.10644C0.110961 3.90893 0 3.64105 0 3.36172V2.30853C0 2.02921 0.110961 1.76132 0.308473 1.56381C0.505984 1.3663 0.773868 1.25534 1.05319 1.25534H4.73936C4.73936 0.976016 4.85032 0.708133 5.04783 0.510621C5.24535 0.313109 5.51323 0.202148 5.79255 0.202148L7.89894 0.202148C8.17826 0.202148 8.44614 0.313109 8.64365 0.510621C8.84117 0.708133 8.95213 0.976016 8.95213 1.25534H12.6383C12.9176 1.25534 13.1855 1.3663 13.383 1.56381C13.5805 1.76132 13.6915 2.02921 13.6915 2.30853V3.36172ZM2.75726 4.41491L2.63298 4.47705V13.8936C2.63298 14.173 2.74394 14.4408 2.94145 14.6384C3.13896 14.8359 3.40685 14.9468 3.68617 14.9468H10.0053C10.2846 14.9468 10.5525 14.8359 10.75 14.6384C10.9475 14.4408 11.0585 14.173 11.0585 13.8936V4.47705L10.9342 4.41491H2.75726ZM1.05319 3.36172V2.30853H12.6383V3.36172H1.05319Z" fill="white"/>
    </svg>

)


export default Delete
