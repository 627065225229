import FinancialsCompany1 from './FinancialsCompany1';
import FinancialsCompanyStatements from './FinancialsCompanyStatements';

export const schema = {
  page1: {
    component: FinancialsCompany1,
    previous: null,
    next: 'page2',
    nextButton: 'View Financial Statements',
  },
  page2: {
    component: FinancialsCompanyStatements,
    previous: 'page1',
    next: null,
    nextButton: 'Done',
  },
  history: '/financials',
};
