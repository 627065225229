import styled from 'styled-components';

const H1 = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-size: ${props => (props.large ? '3em' : '2.5em')};
  line-height: 1.4em;
  color: ${props =>
    (props.white && '#FFF') || (props.grey && '#4E4E4E') || '#171B3E'};
  font-weight: 700;
  display: inline-block;
  margin-bottom: ${props => props.margin || '0.3em'};
  @media only screen and (max-width: 1024px) {
    font-size: 2em;
    line-height: 1.3;
  }
  @media only screen and (max-width: 480px) {
    font-size: 1.75em;
  }
`;

H1.Underlined = styled(H1)`
  &::after {
    content: '';
    display: block;
    border-bottom: ${props => (props.white ? '0.2em solid #FFF' : '0.25em solid #3D7BF2')};
    width: 1.75em;
    height: 0.2em;
    margin: ${props => props.center && 'auto'};
  }
`;

export default H1;
