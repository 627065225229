import FormFields from './FormFields';
import { RequestMethods } from '../../../utils';
import { StorageKeys } from '../../../utils/storageKeys';

export const schema = {
  formStyle: { width: 500, padding: '30px 66px 30px 40px' },
  buttonText: 'Save Changes',
  formFields: FormFields,
  validation: ({ email, name }, currentUser) => {
    if( 
      email === currentUser.email && 
      name === currentUser.name
    ) return 'No changes' 
  },
  apiCall: async (data, setCurrentUser, requestHandler, currentUser, setFormData) => {
    try {
      const response = await requestHandler(RequestMethods.Put, '/users/me', {updates: data, currentUser});
      localStorage.setItem(StorageKeys.User, JSON.stringify(response.data.user));
      setCurrentUser(response.data.user);
      setFormData({
        name: response.data.user.name,
        email: response.data.user.email
      })
      return 'Success';
    } catch (err) {
      console.log(err);
    }
  },
  defaultUser: true,
  default: {
    name: '',
    email: '',
  },
};
