import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    width: 100%;
    > :first-child {
        max-width: 510px;
    }
    > :last-child {
        width: calc(97% - 510px);
    }
`;

const GraphContainer = styled.div`
margin-left: 3%;    
    label {
        color: transparent;
        height: 1px;
        width: 1px;
        display: inline;
    }
`;

GraphContainer.Note = styled.p`
    margin-top: 3em;
    font-size: 0.875em;
    &:before {
        content: '* ';
        color: #75FFBC;
    }
`;

export { Container, GraphContainer };