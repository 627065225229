import React, { createContext, useContext, useState } from 'react';

const FinancialsContext = createContext();

const useFinancials = _ => useContext(FinancialsContext);

const FinancialsContextProvider = ({ children }) => {
  const [reports, setReports] = useState({
    companyQuote: {
      symbol: '',
      name: '',
      price: '',
      pe: '',
      open: '',
      previousClose: '',
      marketCap: '',
      dayLow: '',
      dayHigh: ''
    },
    dividendYield: ''
  });

  return (
    <FinancialsContext.Provider value={{ reports, setReports }}>
      {children}
    </FinancialsContext.Provider>
  );
};

export { FinancialsContextProvider, useFinancials };
