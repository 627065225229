import styled from 'styled-components';

const Required = styled.strong.attrs({
  title: 'required',
  'aria-label': 'requreid',
})`
  color: #E03131;
  margin-left: 0.25em;
  &::before {
    content: '*';
  }
`;

export default Required;
