import React from 'react';
import { useNews } from '../../context';
import NewsArticle from './NewsArticle';
import { ArticleList } from './style';

const NewsList = () => {
    const { filteredNews, filterTerm, category, loading } = useNews();
    const showLandingSection = !filterTerm && !category;

    
    return <>

        <ArticleList className={showLandingSection && 'landing'}>    
            {loading ? <p>Loading...</p> :
            filteredNews.length === 0
                ? filterTerm ?  <p>No results found for <strong>{filterTerm}</strong></p>
                : <p>No results found </p>                
                : filteredNews?.map((article, key) => (
                    <NewsArticle key={key} {...article} />
                 ))}

        </ArticleList>
    </>
}

export default NewsList
