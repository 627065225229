export const currentAssets = [
  {
    title: 'Cash Equivalent',
    api: 'cashAndCashEquivalents',
  },
  {
    title: 'Other Short term income',
    api: 'otherShortTermIncome',
  },
  {
    title: 'Receivables',
    api: 'netReceivables',
  },
  {
    title: 'Inventory',
    api: 'inventory',
  },
  {
    title: 'Total Current Assets',
    api: 'totalCurrentAssets',
  },
];

export const nonCurrentAssets = [
  {
    title: 'Plant. Property and Equipment',
    api: 'propertyPlantEquipmentNet',
  },
  {
    title: 'Accumulated Depreciation',
    api: 'accumulatedDepreciation',
  },
  {
    title: 'Equity and other long term investment',
    api: 'longTermInvestments',
  },
  {
    title: 'Goodwill and intangible assets',
    api: 'goodwillAndIntangibleAssets',
  },
  {
    title: 'Other long term assets',
    api: 'otherNonCurrentAssets',
  },
  {
    title: 'Total non current assets',
    api: 'totalNonCurrentAssets',
  },
];

export const totalAssets = {
  title: 'Total Assets',
  api: 'totalAssets',
};

export const currentLiability = [
  {
    title: 'Current debt',
    api: 'shortTermDebt',
  },
  {
    title: 'Accounts payable',
    api: 'accountPayables',
  },
  {
    title: 'Deferred revenues',
    api: 'deferredRevenue',
  },
  {
    title: 'Other current liabilities ',
    api: 'otherCurrentLiabilities',
  },
  {
    title: 'Total Current liabilities',
    api: 'totalCurrentLiabilities',
  },
];

export const nonCurrentLiabilities = [
  {
    title: 'Non current debt',
    api: 'longTermDebt',
  },
  {
    title: 'Deferred taxes',
    api: 'deferredTaxLiabilitiesNonCurrent',
  },
  {
    title: 'Deferred revenues',
    api: 'deferredRevenueNonCurrent',
  },
  {
    title: 'Other long term liabilities',
    api: 'otherNonCurrentLiabilities',
  },
  {
    title: 'Total non current liabilities',
    api: 'totalNonCurrentLiabilities',
  },
];

export const totalLiabilities = {
  title: 'Total liabilities',
  api: 'totalLiabilities',
};

export const shareholderEquity = [
  {
    title: 'Common stock',
    api: 'commonStock',
  },
  {
    title: 'Retained earnings',
    api: 'retainedEarnings',
  },
  {
    title: 'Other income',
    api: 'othertotalStockholdersEquity',
  },
];

export const totalShareholderEquity = {
  title: 'Total shareholder Equity',
  api: 'totalStockholdersEquity',
};

export const totalLiabilitiesAndShareholderEquity = {
  title: 'Total Liabilities and Shareholder Equity',
  api: 'totalLiabilitiesAndStockholdersEquity',
};
