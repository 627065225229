import React from 'react';
import { Input, EditTitle } from '../../components';
import { FieldsContainer, Container } from '../../components/containers';
import { info } from '../definitions';

const EnterpriseValue = ({ data }) => <>

  {data.companyName? 
    <FieldsContainer>
      <EditTitle
        value={data.companyName}
        readOnly
      />
    </FieldsContainer>

: <Container top='2rem' />
  }

  <FieldsContainer oneColumn>
  <Input
      {...info.enterpriseValue}
      value={
        data.model === 'discount-cashflow-model'? data['enterpriseValue-discount-cashflow-model'] : 
        data.model === 'capitalized-earnings-model'? data['enterpriseValue-capitalized-earnings-model'] 
        : data.enterpriseValue
      }
      readOnly
    />
  </FieldsContainer>
</>

export default EnterpriseValue;
