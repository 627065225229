import React from 'react';
import { Table } from './style'

const Heading = ({ children }) => (
  <Table.Row>
    <Table.Heading scope="rowgroup">
      {children}
    </Table.Heading>
  </Table.Row>
);

export default Heading;