import AccountSetings from './accountSettings/AccountSetings';
import ChangePassword from './changePassword/ChangePassword';
import { Tabs as StyledTabs, Container } from './style';

const schema = {
    tabPanels: {
      'Account Settings': AccountSetings,
      'Change Password': ChangePassword,
    },
    style: StyledTabs,
    container: Container
};

export default schema