import React, { useState } from 'react';
import { InputSelect } from '../../components';
import { Container } from '../../components/containers';
import { Text } from '../../components/elements'
import { GraphContainer } from './style';
import { tenYoptions, datasetsDefault, datasetsPredictedDefault } from '../../utils/graphs';
import { Line } from 'react-chartjs-2';

const selectOptions = [
  'Market Cap over Time',
  'Revenue over Time',
  'Net Income over Time'
];

const Graph = ({ data }) => {
  const [activeGraph, setActiveGraph] = useState('market-cap-over-time');

  const schema = {
    // eslint-disable-next-line
    ['market-cap-over-time']: data.labels && data.marketCap ? {
      labels: data.labels,
      datasets: [
        {
          ...datasetsDefault,
          label: 'Market Cap',
          data: data.marketCap
        },
        {
          ...datasetsPredictedDefault,
          data: data.marketCapPredicted
        }
      ]
    } : null,
    // eslint-disable-next-line
    ['revenue-over-time']: data.revenueLabels && data.revenue ? {
      labels: data.revenueLabels,
      datasets: [
        {
          ...datasetsDefault,
          label: 'Revenue',
          data: data.revenue
        },
        {
          ...datasetsPredictedDefault,
          data: data.revenuePredicted
        }
      ]
    } : null,
    // eslint-disable-next-line
    ['net-income-over-time']: data.labelsNetIncome && data.netIncome ? {
      labels: data.labelsNetIncome,
      datasets: [
        {
          ...datasetsDefault,
          label: 'Net Income',
          data: data.netIncome
        },
        {
          ...datasetsPredictedDefault,
          data: data.netIncomePredicted
        }
      ]
    } : null
  }

  return (
    <GraphContainer>
      <InputSelect
        defaultValue={true}
        label="Select type of graph"
        id="type"
        options={selectOptions}
        onChange={e => setActiveGraph(e.target.value)}
      />
      {schema[activeGraph] ?
        <>
          <Container top='1.5rem'>
            <Line key={activeGraph} id={`graph-${activeGraph}`} data={schema[activeGraph]} options={tenYoptions} />
          </Container>
          {schema[activeGraph].datasets[1].data &&
            <GraphContainer.Note>
              Projections in green are based on the average growth rate of the past 10 years.
            </GraphContainer.Note>
          }
        </> :
        <Container top='1.5rem'>
          <Text>Graph unavailable.</Text>
        </Container>
      }
    </GraphContainer>
  )
}

export default Graph