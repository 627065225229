import React from 'react';
import Company from './style';

const CompanyName = ({ name, symbol }) => <>
  
  <Company.Name>
    {name}&nbsp; 
  </Company.Name>
  <Company.Symbol>
    {symbol}
  </Company.Symbol>
</>

export default CompanyName;
