import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from '../containers';
import { Button, FormMessage } from '../elements';
import { useAuth } from '../../context';
import { useRequest } from '../../utils';

const SimpleFormSchema = ({ schema }) => {
  const requestHandler = useRequest();
  const { apiCall, buttonText } = schema;
  const formStyle = schema.formStyle || {};
  const buttonStyle = schema.buttonStyle || {};
  const FormFields = schema.formFields;

  const { setCurrentUser, currentUser } = useAuth();
  const [formData, setFormData] = useState(
    schema.defaultUser? 
    { name: currentUser.name, email: currentUser.email} :
    schema.default
  );
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleChange = e => {
    setMessage('');
    if(e.target.type === 'checkbox') {
      return setFormData({ ...formData, [e.target.name]: e.target.checked });
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSubmit = async e => {
    e.preventDefault();

    if (schema.validation) {
      const response = schema.validation(formData, currentUser);
      if(response) return setMessage({ type: 'error', response });
    }

    setLoading(true);
    const sucess = await apiCall(formData, setCurrentUser, requestHandler, currentUser, setFormData);
    setLoading(false);

    if(!sucess) return setMessage({
      type: 'error', 
      response: 'Something went wrong. Try again.'
    });

    setMessage({ type: 'sucess', response: sucess});
    !schema.defaultUser && setFormData(schema.default);

    schema.history && history.push('/dashboard');
  };

  return (
    <Form {...formStyle} onSubmit={e => handleSubmit(e)}>
      <FormFields 
        handleChange={handleChange} 
        formData={formData} 
        setFormData={setFormData}
      />
      <Button {...buttonStyle} type="submit" disabled={loading}>
        {buttonText}
      </Button>
      {message && 
        <FormMessage type={message.type}>
          {message.response}
        </FormMessage>
      }
    </Form>
  );
};

export default SimpleFormSchema;
