import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../../components';
import {
  DashboardMain as Main,
  DashboardCard as Card,
} from '../../components/containers';
import { Text, Image } from '../../components/elements';
import {
  publicCompany,
  privateCompany,
  financialStrength,
} from '../../assets/images';

const Landing = () => (

  <Main grey>
    <Main.SectionHalfWhite>
      <Main.Inner>

        <Main.Title>Public Investments</Main.Title>
        <Breadcrumbs path='Public Investments' url='/dashboard/public-investments' />
  
        <Text large dark>
          Please select of valuation would you like to see?
        </Text>

        <Card.Container>
          <Card>
            <Link to="/dashboard/public-investments/share-price-valuation">
              <Image src={publicCompany} alt="" width="100%" />
              <Card.Text>
                <Card.Title>Share Price Valuation</Card.Title>
                <Text small>
                  Find the intrinsic value of a stock based on the company's
                  financials. Uses the Discounted Cash Flow and Dividend Discount Models.
                </Text>
              </Card.Text>
            </Link>
          </Card>
          <Card>
            <Link to="/dashboard/public-investments/stock-option-valuation">
              <Image src={privateCompany} alt="" width="100%" />
              <Card.Text>
                <Card.Title>Stock Option Valuation</Card.Title>
                <Text small>
                  Find the value of a stock option using Black Scholes Option
                  Pricing Model.
                </Text>
              </Card.Text>
            </Link>
          </Card>
          <Card>
            <Link to="/dashboard/public-investments/financial-strength">
              <Image src={financialStrength} alt="" width="100%" />
              <Card.Text>
                <Card.Title>Financial Strength</Card.Title>
                <Text small>
                  Use ratio analysis to find the strength of a company to determine
                  if it is a solid investment.
                </Text>
              </Card.Text>
            </Link>
          </Card>
        </Card.Container>
    
      </Main.Inner>
    </Main.SectionHalfWhite>
  </Main>
);

export default Landing;
