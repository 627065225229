import React from 'react';
import { Container } from '../containers';
import { Text } from '../elements';
import ResultLabel from './style';

const IntristicValueResult = ({ title, contingentResult, negativeCashflow, externalFactors }) => {


    return contingentResult? 
        (
            <Container top='2rem'>
                <Text dark>
                <strong>
                    {title}
                    <ResultLabel color={contingentResult.color}>
                    {' ' + contingentResult.value}
                    </ResultLabel>
                </strong>
                </Text>
                <Text dark>{contingentResult.description}</Text>
                {negativeCashflow && <Text><ResultLabel color={contingentResult.color}>*</ResultLabel> {negativeCashflow}</Text>}
                {externalFactors && <Text><ResultLabel color={contingentResult.color}>*</ResultLabel> {externalFactors}</Text>}
            </Container>
        )

        :   <Container top='2rem'>
                <Text>Unavailable for this Company.</Text>
            </Container>
}

export default IntristicValueResult
