
function getGradient(ctx, chartArea) {
  let width, height, gradient;
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
    gradient.addColorStop(0, '#FFF');
    gradient.addColorStop(0.5, '#BFDFFF');
    gradient.addColorStop(1, '#BFDFFF');
  }

  return gradient;
}

const optionsDefault = {
  spanGaps: true,
  responsive: true,
  animation: false,
  mantainAspectRation: true,
  legend: { display: false },
  elements: {
    line: {
      tension: 0
    }
  },
  tooltips: {
    multiKeyBackground: '#019DE7'
  }
};

const tooltipsDefault = {
  custom: function (tool) {
    if (!tool) return;
    tool.displayColors = false;
  },
  callbacks: {
    label: function (tooltipItem, data) {
      return 'Price: ' + tooltipItem.yLabel + " $ ";
    }
  }
};

const scalesDefault = {
  xAxes: [{
    gridLines: {
      color: "transparent",
      display: true,
      drawBorder: false,
      zeroLineColor: "#ccc",
      zeroLineWidth: 1
    },
    ticks: {
      padding: 10,
      minRotation: 0,
      maxRotation: 0,
      autoSkipPadding: 30,
      min: 0
    }
  }],
  yAxes: [{
    gridLines: {
      color: "transparent",
      display: true,
      drawBorder: false,
      zeroLineColor: "#ccc",
      zeroLineWidth: 1
    },
    ticks: {
      minRotation: 0,
      maxRotation: 0,
      autoSkipPadding: 20
    }
  }]
}

const datasetsDefault = {
  fill: true,
  borderColor: "#009CE7",
  pointBackgroundColor: 'transparent',
  pointBorderColor: 'transparent',
  backgroundColor: function (context) {
    const chart = context.chart;
    const { ctx, chartArea } = chart;
    if (!chartArea) return null;
    return getGradient(ctx, chartArea);
  }
}

const datasetsPredictedDefault = {
  fill: false,
  label: 'Projected',
  borderColor: '#ABF2D0',
  pointBackgroundColor: '#ABF2D0',
  pointBorderColor: '#ABF2D0',
}

const tenYoptions = {
  ...optionsDefault,
  parsing: false,
  scales: {
    ...scalesDefault,
    xAxes: [{
      gridLines: {
        color: "transparent",
        display: true,
        drawBorder: false,
        zeroLineColor: "#ccc",
        zeroLineWidth: 1
      },
      ticks: {
        maxRotation: 0,
        autoSkipPadding: 30
      }
    }]
    ,
    yAxes: [{
      gridLines: {
        color: "transparent",
        display: true,
        drawBorder: false,
        zeroLineColor: "#ccc",
        zeroLineWidth: 1
      },
      ticks: {
        autoSkipPadding: 30
      }
    }]
  }
}

export { tenYoptions, getGradient, datasetsPredictedDefault, optionsDefault, tooltipsDefault, scalesDefault, datasetsDefault }