import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { FinancialStrengthContextProvider, useCompanies } from '../../context';
import { TabsPublicInv, SearchCompanies, Breadcrumbs } from '../../components';
import FinancialStrengthCompany from '../financialStrengthCompany/FinancialStrengthCompany';
import { DashboardMain as Main} from '../../components/containers';
import { ErrorPage } from '..';

const FinancialStrength = () => {
  const { companies } = useCompanies();
  let { path } = useRouteMatch();

  return (
    <Main>
      <Main.Inner>
      <Main.Title>Public Investments</Main.Title>
      <Breadcrumbs path='Public Investments' url='/dashboard/public-investments' />
        <TabsPublicInv option="public-investments" />
        <FinancialStrengthContextProvider>
        <Switch>
          <Route exact path={path}>
            <SearchCompanies data={companies} back="public-investments" model='Financial Strength' />
          </Route>
          <Route exact path={`${path}/:symbol`} component={FinancialStrengthCompany} />
          <Route component={ErrorPage} />
        </Switch>
        </FinancialStrengthContextProvider>
      </Main.Inner>
    </Main>
  );
};

export default FinancialStrength;
