import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { RequestMethods, useRequest } from '../../../utils/requests';
import { Loading } from '../../../components';
import { useParams } from 'react-router';
import { Label } from '../../../components/elements';
import { timestampToDate } from '../../../utils';
import { Container } from '../../../components/containers';

const GraphSchema = ({ api, data, setData, options, datasets }) => {
  const requestHandler = useRequest();
  const [loading, setLoading] = useState(false);
  const { symbol } = useParams();

  useEffect(() => {

    if (data[api] && api !== 'prices-quarter-hourly') return;

    setLoading(true);

    try {
      const getPrices = async _ => {
        const response = await requestHandler(
          RequestMethods.Get, `/companies/${symbol}/${api}`
        );
        setData({ ...data, [api]: response?.data });
      }
     getPrices();
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  // eslint-disable-next-line
  }, [api, setData]);

  if (loading) return <Loading />

  const graphData = {
    labels: data[api]?.labels,
    datasets: [{ ...datasets, data: data[api]?.prices }]
  }

  return <>
    <Line id={'graph-' + api} data={graphData} options={options} />
    {data[api]?.timestamp &&
      <Container top='1em'>
        <Label>* 1 Day prices are shown for {timestampToDate(data[api]?.timestamp)} </Label>
      </Container>
    }
  </>
}

export default GraphSchema
