import React from 'react';
import BalanceSheet from './balanceSheet/BalanceSheet';
import IncomeStatement from './incomeStatement/IncomeStatement';
import { Tabs, ReportNotFound } from '../../components';
import { Container, Tabs as Styled } from '../../components/containers';
import { useFinancials } from '../../context';

const FinancialsStatements = () => {
  const { reports } = useFinancials();

  const schema = {
    tabPanels: {
      'Balance Sheet': reports.balanceSheet? BalanceSheet : ReportNotFound,
      'Income Statement': reports.incomeStatement? IncomeStatement : ReportNotFound
    },
    style: Styled,
  };

  return (
    <Container top='3em'>
      <Tabs schema={schema} />
    </Container>
  )
}

export default FinancialsStatements;
