import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useCompanies, SPVContextProvider } from '../../context';
import { TabsPublicInv, SearchCompanies, Breadcrumbs } from '../../components';
import SharePriceValuationCompany from '../sharePriceValuationCompany/SharePriceValuationCompany';
import { DashboardMain as Main } from '../../components/containers';
import { ErrorPage } from '..';

const SharePriceValuation = () => {
  const { companies } = useCompanies();
  let { path } = useRouteMatch();

  return (
    <Main>
    <Main.Inner>

      <Main.Title>Public Investments</Main.Title>
      <Breadcrumbs path='Public Investments' url='/dashboard/public-investments' />
      <TabsPublicInv option="public-investments" />
      
        <Switch>

          <Route exact path={path}>
            <SearchCompanies data={companies} back="public-investments" model="Share Price Valuation" />
          </Route>

   
            <Route exact path={`${path}/:symbol`}>
              <SPVContextProvider>
                <SharePriceValuationCompany />
              </SPVContextProvider>
            </Route>
          
          <Route component={ErrorPage} />
        </Switch>
      
    </Main.Inner>
    </Main>
  );
};

export default SharePriceValuation;
