import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { UserContextProvider } from '../context';
import * as pages from '../pages';
import { Dashboard, Financials, News, History } from '../pagesPrivate';
import PrivateRoute from './PrivateRoute';
import ScrollToTop from './ScrollToTop';

const App = () => (
  <UserContextProvider>
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={pages.Home} />
        <Route exact path="/about" component={pages.About} />
        <Route exact path="/contact" component={pages.Contact} />
        <Route exact path="/login" component={pages.Login} />
        <Route exact path="/sign-up" component={pages.SignUp} />
        <Route exact path='/faqs' component={pages.FAQs} />
        <Route exact path='/privacy-policy' component={pages.PrivacyPolicy} />
        <Route exact path='/terms-and-conditions' component={pages.TermsAndConditions} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/financials" component={Financials} />
        <PrivateRoute exact path="/news" component={News} />
        <PrivateRoute exact path="/history" component={History} />
        <Route component={pages.ErrorPage} />
      </Switch>
    </BrowserRouter>
  </UserContextProvider>
);

export default App;
