import React from 'react';
import { ContingentResult } from '../../components';
import { FieldsContainer } from '../../components/containers';
import { H4, Text } from '../../components/elements';
import { info } from '../definitions';

const FinancialStrengthReport2 = ({ data }) => (
  <section>
    <Text>
      Financial strength of your company is analysed based on the following
      factors:
    </Text>
    <div>
      <H4>Profitability</H4>
      <FieldsContainer top twoColumns>
        <ContingentResult
          {...info.returnOnEquity}
          value={data.returnOnEquity}
          {...data.contingent.returnOnEquity}
        />
        <ContingentResult
          {...info.returnOnAssets}
          value={data.returnOnAssets}
          {...data.contingent.returnOnAssets}
        />
        <ContingentResult
          {...info.grossProfitMargin}
          value={data.grossProfitMargin}
          {...data.contingent.grossProfitMargin}
        />
        <ContingentResult
          {...info.netProfitMargin}
          value={data.netProfitMargin}
          {...data.contingent.netProfitMargin}
        />
      </FieldsContainer>
    </div>
    <div>
      <H4>Liquidity</H4>
      <FieldsContainer top>
        <ContingentResult 
          {...info.currentRatio}
          value={data.currentRatio}
          {...data.contingent.currentRatio}
        />
        <ContingentResult
          {...info.quickRatio}
          value={data.quickRatio}
          {...data.contingent.quickRatio}
        />
      </FieldsContainer>
    </div>
    <div>
      <H4>Market</H4>
      <FieldsContainer top>
        <ContingentResult
          {...info.priceToEarningsRatio}
          value={data.priceToEarningsRatio}
          {...data.contingent.priceToEarningsRatio}
        />
        <ContingentResult
          {...info.earningsPerShare}
          value={data.earningsPerShare}
        />
      </FieldsContainer>
    </div>
    <div>
      <H4>Leverage</H4>
      <FieldsContainer top>
        <ContingentResult
          {...info.debtEquityRatio}
          value={data.debtEquityRatio}
          {...data.contingent.debtEquityRatio}
        />
        <ContingentResult 
          {...info.debtToEBITDA} 
          value={data.debtToEBITDA}
          {...data.contingent.debtToEBITDA}
        />
      </FieldsContainer>
    </div>
  </section>
);

export default FinancialStrengthReport2;
