import React, { useEffect } from 'react';
import { Input } from '../../components';
import { createDatalist } from '../../utils';
import { FieldsContainer as Container } from '../../components/containers';
import { info } from '../definitions';
import { Text } from '../../components/elements';

export const DiscountDividendModel = ({ data, setData }) => {

  useEffect(() => {
    if (data.DDM) {
      setData({ ...data, disableNext: false })
    } else {
      setData({ ...data, disableNext: true })
    }
   // eslint-disable-next-line 
  }, []);

  return data.disableNext ?

    <Text>Sorry, we are unable to retrieve dividend statement for this company.</Text>
    : (
      <Container oneColumn>
        <Input {...info.dividendPerShare} value={data.dividendPerShare} readOnly />
        <Input {...info.growthRate} value={data.growthRate} readOnly />
      </Container>
    );
}

export const DiscountCashflowModel = ({ data, setData, onChange }) => {

  useEffect(() => {
    if (data.DCM) {
      setData({ ...data, disableNext: false })
    } else {
      setData({ ...data, disableNext: true })
    }
    // eslint-disable-next-line
  }, []);

  return data.disableNext ?
    <Text>Sorry, we are unable to retrieve data for this company.</Text>
    : (
      <Container>
        <Input
          {...info.terminalGrowthRate}
          value={data.terminalGrowthRate}
          onChange={onChange}
          required
          list='terminalGrowthRateOptions'
        />
        {createDatalist(1, 4, 1, 'terminalGrowthRateOptions')}
        <Input {...info.revenue} readOnly value={data.revenue} />
        <Input {...info.CCARate} readOnly value={data.CCARate} />
        <Input {...info.interestExpense} readOnly value={data.interestExpense} />
        <Input {...info.taxRate} readOnly value={data.taxRate} />
        <Input {...info.capitalExpenditure} readOnly value={data.capitalExpenditure} />
        <Input {...info.outstandingShares} readOnly value={data.outstandingShares} />
        <Input {...info.workingCapital} readOnly value={data.workingCapital} />
      </Container>
    );
}

export const CapitalizedEarningsModel = ({ data, setData, onChange }) => {

  useEffect(() => {
    if (data.CEM) {
      setData({ ...data, disableNext: false })
    } else {
      setData({ ...data, disableNext: true })
    }
    // eslint-disable-next-line
  }, []);

  return data.disableNext ?
    <Text>Sorry, we are unable to retrieve data for this company.</Text>
    : (
      <Container>
        <Input
          {...info.terminalGrowthRate}
          value={data.terminalGrowthRate}
          onChange={onChange}
          required
          list='terminalGrowthRateOptions'
        />
        {createDatalist(1, 4, 1, 'terminalGrowthRateOptions')}
        <Input {...info.earningsBeforeTax} value={data.earningsBeforeTax} readOnly />
        <Input {...info.interestExpense} value={data.interestExpense} readOnly />
        <Input {...info.CCARate} value={data.CCARate} readOnly />
        <Input {...info.weightOfDebt} value={data.weightOfDebt} readOnly />
        <Input {...info.amortization} value={data.amortization} readOnly />
        <Input {...info.totalDebt} value={data.totalDebt} readOnly />
        <Input {...info.capitalExpenditure} value={data.capitalExpenditure} readOnly />
        <Input {...info.taxRate} value={data.taxRate} readOnly />
      </Container>
    );
}