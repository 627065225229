import React, { useState } from 'react';
import { Expand } from '../../assets/svgComponents';
import { Explain } from './style';

const Explanation = ({ text }) => {
  const [open, setOpen] = useState(false);

  return (
    <Explain onClick={() => setOpen(!open)}>
        <Explain.Popup 
          open={open}
          aria-expanded={open}
          onMouseLeave={() => setOpen(false)}
        >
          {text}
        </Explain.Popup>
    <Explain.Button>
      <Expand />
      <Explain.Text>Explain</Explain.Text>
    </Explain.Button>
  </Explain>
  );
};

export default Explanation;
