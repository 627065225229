import styled from 'styled-components';

const Input = styled.input.attrs({
    type: 'checkbox'
})`
    margin-right: 0.5em;
    cursor: pointer;
`;

Input.Label = styled.label`
    color: #68717a;
    font-size: 0.875em;
`;

Input.Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 2em;
`;

export default Input;