import React from 'react'
import { Hidden } from '../elements';
import { Button } from './style';

const MenuBars = ({ onClick }) => {
    return (
        <Button title='Open Menu' onClick={onClick}>
            <Hidden.Span className='hide'>Open Menu</Hidden.Span>
            <Button.Bars>
                <Button.Bar />
                <Button.Bar />
                <Button.Bar />
            </Button.Bars>
        </Button>
    )
}

export default MenuBars
