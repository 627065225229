import React from 'react';
import { PortalWithState } from 'react-portal';
import { Hidden } from '../elements';
import { default as Styled } from './style';

const Modal = (props) => {

  const Button = props.button;
  const Content = props.content;

  return (
    <PortalWithState closeOnEsc>
      {({ openPortal, closePortal, portal }) => (

        <>    
          <Button onClick={openPortal} />

          {portal(
            <Styled>
              <Styled.BackDrop onClick={closePortal} />
              <Styled.Container>
                <Styled.ButtonClose onClick={closePortal}>
                  <Hidden.Span>Close</Hidden.Span>
                  <span>&times;</span>
                </Styled.ButtonClose>
                <Content />
              </Styled.Container>
            </Styled>
          )}
        </>
      )}
    </PortalWithState>
  );
}

export default Modal;
