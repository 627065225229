import FormFields from './FormFields';
import { RequestMethods } from '../../../utils';

export const schema = {
  formStyle: { width: 500, padding: '30px 66px 30px 40px' },
  buttonText: 'Change',
  formFields: FormFields,
  validation: ({ password }) => {
    if(password.length < 6) {
      return 'The New Password must be at least 6 characters long';
    } else if (password.toLowerCase().includes('password')) {
      return "The New Password cannot contain a word 'password'"
    }
  },
  apiCall: async (data, setCurrentUser, requestHandler, currentUser) => {
    try {
      await requestHandler(RequestMethods.Put, '/users/me', {
        updates: { password: data.password }, 
        oldPassword: data.oldPassword,
        currentUser
      });
      return 'Success';
    } catch (err) {
      console.log(err);
    }
  },
  default: {
    oldPassword: '',
    password: '',
  },
};
