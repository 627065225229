import styled from 'styled-components';

const Hidden = {};

Hidden.Label = styled.label`
  overflow: hidden;
  color: transparent;
  width: 1px;
  height: 1px;
  display: inline-block;
`;

Hidden.Span = styled.span`
  overflow: hidden;
  color: transparent;
  width: 1px;
  height: 1px;
  display: inline-block;
`;

Hidden.Caption = styled.caption`
  overflow: hidden;
  color: transparent;
  width: 1px;
  height: 1px;
  display: inline-block;
`;
export default Hidden;
