import styled from 'styled-components';

const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    tbody * {
        line-height: 1.4;
    };
    strong {
        color: #152536;
    };
    @media only screen and (max-width: 767px) {
        font-size: 0.875em;
    }
    @media only screen and (max-width: 599px) {
        tr > :nth-child(2) {
            display: ${props => props.active !== 'first' && 'none'};
        }
        tr > :nth-child(3) {
            display: ${props => props.active !== 'second' && 'none'};
        }
        tr > :nth-child(4) {
            display: ${props => props.active !== 'third' && 'none'};
        }
    }
`;

Table.Head = styled.thead``;

Table.HeadRow = styled.tr`
    border-bottom: 1px solid #d4d4d4;
    > :first-child {
        text-align: left;
    }
`;

Table.HeadCell = styled.th.attrs({
    scope: 'col'
})`
    padding: 0.5em;
`;

Table.Row = styled.tr`
    vertical-align: baseline;
    > :first-child {
        text-align: left;
    }
`;

Table.Heading = styled.th`
    padding: 0.5em;
    padding-right: 3em;
    font-weight: ${props => props.bold? 700 : 400};
    color: ${props => props.bold? '#525252' : '#757575'};
    &[scope='rowgroup'] {
        font-weight: 500;
        color: #525252;
    }
`;

Table.Cell = styled.td`
    padding-right: 1em;
    color: #757575;
    font-weight: 400;
    text-align: right;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
`;

Table.Button = styled.button`
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    position: absolute;
    font-size: 1em;
    padding: 1em 0.75em;
    top: 50%;
    transform: translateY(-50%);
    color: #2860d0;
    :before {
        color: transparent;
        position: absolute;
        width: 1px;
        height: 1px;
    }
    @media only screen and (min-width:600px ) {
        display: none;
    }
`;

Table.Button.Wrapper = styled.span`
    position: relative;
`;

Table.Button.Left = styled(Table.Button).attrs({
    title: 'Next year'
})`
    left: -2.5em;
    &:before {
        content: 'Next year'
    }
`;

Table.Button.Right = styled(Table.Button).attrs({
    title: 'Previous year'
})`
    right: -2.5em;
    &:before {
        content: 'Previous year'
    }
`;

export { Table };
