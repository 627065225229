import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useCompanies, useAuth } from '../../context';
import List from './List';
import SearchOptions from './SearchOptions';
import { BackNextContainer, InputSearch } from '..';
import { Search } from './style';

const SearchCompanies = ({ back }) => {
  const historyPush = useHistory();
  const { currentUser } = useAuth();
  const history = currentUser.history;
  const { companies } = useCompanies();
  const [search, setSearch] = useState('');
  const [input, setInput] = useState('');
  const [list, setList] = useState(history);

  const handleSearch = e => {
    setInput(e.target.value);
    setSearch(e.target.value);
  }

  const handleSubmit = async e => {
    e.preventDefault();
    setInput('');
    setList(results);
  };

  const results = (companies || []).filter(company =>
    company.name?.toLowerCase().includes(search.toLowerCase()) ||
    company.symbol?.toLowerCase().includes(search.toLowerCase())
  );

  const handleBack = _ => historyPush.push(`/dashboard/${back}`);

  return (
    <>
      <Search onSubmit={handleSubmit}>
      <InputSearch
          label="Search your company here"
          onChange={handleSearch}
          value={input}
      >        
          {results.length !== 0 && input && 
              <SearchOptions 
              results={results} 
              handleSubmit={handleSubmit} 
              />
          }
      </InputSearch>
      </Search>

      <List list={list} search={search} input={input} />

      {back && 
        <BackNextContainer
          handleBack={handleBack}
        />
      }
    </>
  );
};

export default SearchCompanies;
