import React from 'react';
import { useHistory } from 'react-router-dom';
import { default as Styled } from './style';
import { Logo } from '..';

// Header for Login and Signup pages

const HeaderLogin = ({ button, buttonText, text }) => {
  const history = useHistory();

  return <>

    <Styled.Logo>
        <Logo />
        <Logo iconOnly={true} />
    </Styled.Logo>
    <Styled.Container>
      <Styled.Text>{text}</Styled.Text>
      <Styled.Button onClick={() => history.push(button)}>{buttonText}</Styled.Button>
    </Styled.Container>
  
  </>
};

export default HeaderLogin;