import React from 'react';
import Input from './style';

const InputRadio = ({ label, value, ...rest }) => (
  <fieldset>
    <Input.Legend>{label}</Input.Legend>
    <Input.RadioContainer>
      <Input
        type="radio"
        id={`${label}-yes`}
        value={'Yes'}
        checked={value === 'Yes'}
        {...rest}
      />
      <Input.Label htmlFor={`${label}-yes`}>Yes</Input.Label>
    </Input.RadioContainer>
    <Input.RadioContainer>
      <Input
        type="radio"
        id={`${label}-no`}
        value={'No'}
        checked={value === 'No'}
        {...rest}
      />
      <Input.Label htmlFor={`${label}-no`}>No</Input.Label>
    </Input.RadioContainer>
  </fieldset>
);

export default InputRadio;
