import styled from 'styled-components';
import { contactBg, circleBg } from '../../assets/images';
import { Main } from '../../components/containers';

const Styled = {};

Styled.Section = styled.section`
    background: url(${contactBg}), url(${circleBg}), #366EDA;
    background-position: bottom left, 85% 17%;
    background-size: 15%, 10%;
    background-repeat: no-repeat;
    min-height: 100vh;
    color: #FFF;
    input, textarea {
        color: #4D4D4D;
    }
`;

Styled.Inner = styled(Main.Inner)``

Styled.Row = styled.div`
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 800px) {
        flex-wrap: wrap;
        justify-content: center;
    }
`;

Styled.Info = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 800px) {
        padding: 0 5% 50px;
        max-width: 525px;
    }
`;

Styled.Text = styled.p`
    font-size: 1.25em;
    margin-right: 3%;
    margin-right: 20%;
    margin-bottom: 2em;
    margin-top: 1em;
    display: block;
`;

Styled.Link = styled.a`
    margin-bottom: 1em;
    padding: 0.5em 0;
    display: inline-block;
    font-size: 1.25em;
    svg {
        margin-right: 1em;
        vertical-align: middle;
    }
    @media only screen and (max-width: 425px) {
        padding: 0.25em 0;
    }
`;

Styled.Card = styled.div`
max-width: 525px;
  background-color: #fff;
  border-radius: 13px;
  padding: 4% 5% 50px;
  text-align: ${props => props.center && 'center'};
`;

Styled.Copyright = styled.p`
    width: 100%;
    text-align: right;
    font-size: 1.125em;
    padding: 2rem 0;
`;

const Social = styled.div`
`;

Social.Link = styled.a`
background-color: #234292;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 46px;
    height: 46px;
    margin-right: 1em;
    @media only screen and (max-width: 425px) {
        width: 44px;
        height: 44px;
        margin-right: 0.3em;
        margin-left: 0.3em;
    }
`


export { Styled, Social };