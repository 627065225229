export const totalRevenue = {
  title: 'Total Revenue',
  api: 'revenue',
};

export const costOfRevenue = {
  title: 'Cost of Revenue',
  api: 'costOfRevenue',
};

export const grossProfit = {
  title: 'Gross Profit',
  api: 'grossProfit',
};

export const operatingExpenses = [
  {
    title: 'Research and Development',
    api: 'researchAndDevelopmentExpenses',
  },
  {
    title: 'Selling, General, Administrative Expenses',
    api: 'SGAExpenses',
  },
  {
    title: 'Total Operating Expenses',
    api: 'totalOperatingExpenses',
  },
];

export const operatingIncomeOrLoss = [
  {
    title: 'Interest Expense',
    api: 'interestExpense',
  },
  {
    title: 'Other Income',
    api: 'otherIncome',
  },
  {
    title: 'Income Before Tax',
    api: 'incomeBeforeTax',
  },
  {
    title: 'Income Tax Expense',
    api: 'incomeTaxExpense',
  },
  {
    title: 'Net Icome',
    api: 'netIncome'
  },
  {
    title: 'Earning Per Share',
    api: 'eps',
  },
  {
    title: 'Shares Outstanding',
    api: 'sharesOutstanding',
  },
];

export const earningsBeforeITDA = {
  title: 'Earnings before Interest Tax Depreciation and Amortization',
  api: 'ebitda',
};
