import styled from 'styled-components';

const Styled = {};

Styled.Input = styled.input`
    max-width: 418px!important;
    width: 100%;
    padding: 0 0.75em;
    margin-top: 0.3em;
`;

Styled.Title = styled.h3`
    display: inline;
    margin-top: 0.2em;
`;

Styled.Button = styled.button`
    cursor: pointer;
    border: none;
    background-color: transparent;
`;

export default Styled;