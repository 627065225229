import React from 'react';
import { Arrow } from '../../assets/svgComponents';
import {  ModalAccount } from '..';
import { Hidden, Heading } from '../elements';
import { HeaderDesktop } from './style';
import HeaderMobile from './HeaderMobile';

const Header = ({ title, back, ...rest }) => <>

  <HeaderDesktop {...rest}>
    <HeaderDesktop.Inner wide>

      {back && (
        <HeaderDesktop.Link to={back} title="Go Back">
          <Arrow />
          <Hidden.Span>Go Back</Hidden.Span>
        </HeaderDesktop.Link>
      )}
      <h1>
        <Heading>{title}</Heading>
      </h1>
      
      <ModalAccount />
      
   </HeaderDesktop.Inner>
  </HeaderDesktop>
  
  <HeaderMobile />
</>;

export default Header;
