import React from 'react';
import { Container, Heading, Row } from '../pdf/Frame';
import * as c from './schema';
import { View } from '@react-pdf/renderer';
import stylesPDF from '../pdf/style';

const BalanceSheetPDF = ({ data, name, symbol }) => {
    
  return (
    <Container name={name} symbol={symbol} title='Balance Sheet'>

      <Heading>Assets</Heading>
      <View style={stylesPDF.border} />

      <Heading>Current Assets</Heading>
      <View style={stylesPDF.border} />
      {c.currentAssets.map(item => (
        <Row key={item.api} item={item} data={data} />
      ))}
      
      <View style={stylesPDF.border} />
      <Heading>Non Current Assets</Heading>
      <View style={stylesPDF.border} />
      {c.nonCurrentAssets.map(item => (
        <Row key={item.api} item={item} data={data} />
      ))}
      <Row data={data} item={c.totalAssets} bold />
      
      <View style={stylesPDF.border} />
      <Heading>Liabilities and Shareholder Equity</Heading>
      <View style={stylesPDF.border} />

      <Heading>Current Liabilities</Heading>
      <View style={stylesPDF.border} />
      {c.currentLiability.map(item => (
        <Row key={item.api} item={item} data={data} />
      ))}
 
      <View style={stylesPDF.border} />
      <Heading>Non Current Liabilities</Heading>
      <View style={stylesPDF.border} />
      {c.nonCurrentLiabilities.map(item => (
        <Row key={item.api} item={item} data={data} />
      ))}
      <Row data={data} item={c.totalLiabilities} />

      <View style={stylesPDF.border} />
      <Heading>Shareholder Equity</Heading>
      <View style={stylesPDF.border} />
      {c.shareholderEquity.map(item => (
        <Row key={item.api} item={item} data={data} />
      ))}
      <Row data={data} item={c.totalShareholderEquity} bold />

      <View style={stylesPDF.border} />
      <Row data={data} item={c.totalLiabilitiesAndShareholderEquity} bold />
      
    </Container>
  );
};

export default BalanceSheetPDF;