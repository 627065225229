import React from 'react';
import { useFinancials } from '../../context';
import { Input, CompanyName } from '../../components';
import { FieldsContainer, Container } from '../../components/containers';
import { Label } from '../../components/elements';
import { info } from '../definitions';
import { Price } from './style';
import Graph from './graph/Graph';

const FinancialOverview = () => {
  const { reports } = useFinancials();
  const quote = reports.companyQuote;

  const rise = quote?.change > 0;

  return <>

    <Container bottom='1rem' top='2.5em'>
      <Label>Company Name</Label>
      <h2><CompanyName name={quote.name} symbol={quote.symbol} /></h2>
    </Container>
    <div>
      <Price>
        <Price.Number>{quote.price}</Price.Number> USD
        <Price.Change rise={rise}>
          {rise? '+' : '-'}
          {`${quote.change} (${quote.changesPercentage}%)`}
          {rise ? <> &#9650;</> : <> &#9660;</>}
        </Price.Change>
      </Price>
      <Price.Time>
        {new Date(quote.timestamp * 1000).toLocaleString('en-US', {
          timeZone: "America/New_York",
          dateStyle: "medium",
          timeStyle: "short"
        })} GMT-4 Disclamer
      </Price.Time>
      <Graph />
      <FieldsContainer>
        <Input {...info.priceToEarningsRatio} readOnly value={quote.pe} />
        <Input {...info.open} readOnly value={quote.open} />
        <Input {...info.previousClose} readOnly value={quote.previousClose} />
        <Input {...info.currentMarketCap} readOnly value={quote.marketCap} />
        <Input {...info.dayHigh} readOnly value={quote.dayHigh} />
        <Input {...info.dayLow} readOnly value={quote.dayLow} />
        <Input {...info.dividendYield} readOnly value={reports.dividendYield} />
      </FieldsContainer>
    </div>
  </>

};

export default FinancialOverview;
