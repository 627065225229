import React from 'react';
import { Header, Footer } from '../../components';
import { Main } from '../../components/containers';
import Section1 from './Section1';
import Section2 from './Section2';

const About = () => <>

  <Header />
  <Main>
    <Section1 />
    <Section2 />
  </Main>
  <Footer />

</>

export default About;
