import styled from 'styled-components';

const Input = styled.input`
display: inline-block;
vertical-align: middle;
`;

Input.Container = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 1em;
`;
Input.RadioContainer = styled.div`
    display: inline-block;
`;

Input.Legend = styled.legend`
  font-size: 1em;
  color: #4d4d4d;
  font-weight: 500;
  padding-bottom: 0.3em;
`;

Input.Label = styled.label`
    color: #152536;
    font-size: 1em;
    display: inline-block;
    margin: 0 2em 2em 0.5em;
`;

export default Input;
