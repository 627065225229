import React from 'react';
import { useHistory } from 'react-router-dom';
import { ArrowRight } from '../../assets/svgComponents';
import { H1, TextOpenSans } from '../../components/elements';
import Home from './style';
import { homeBanner } from '../../assets/images';

const Section1 = () => {
  const history = useHistory();

  return (
    <Home.Banner img={homeBanner}>
      <Home.Inner>
        <Home.TextContainer width={560}>
          <H1>
            Bringing simplified business valuation to the hands of every
            investor.
          </H1>
          <TextOpenSans>
            Find intrinsic share price and conduct business valuations. Making
            smarter investment decisions has never been this easy!
          </TextOpenSans>
          <Home.Button onClick={() => history.push('/sign-up')}>
            Get started
            <ArrowRight white />
          </Home.Button>
        </Home.TextContainer>
      </Home.Inner>
    </Home.Banner>
  );
};

export default Section1;
