import React from 'react';
import { createDatalist } from '../../utils';
import { Input, InputSelect } from '../../components';
import { FieldsContainer } from '../../components/containers';
import { FieldSet } from './style';
import { Text } from '../../components/elements';

const ProjectFinance1 = ({ data, handleChange, setData }) => (
  <>
    <Text large>
      Calculate the value, profitability, and payback time of your next project
    </Text>

    <FieldsContainer>

    </FieldsContainer>

    <FieldSet>
      <FieldSet.Legend>Duration</FieldSet.Legend>
      <FieldSet.Container>
        {data.period === 'days' &&
          <Input
            label="Days"
            required
            value={data.periodLength}
            onChange={handleChange}
            name="periodLength"
            min='10'
            max='30'
            list='daysOptions'
            placeholder='00'
            step='1'
          />
        }
        {createDatalist(10, 30, 1, 'daysOptions')}
        {data.period === 'months' &&
          <Input
            label="Months"
            required
            value={data.periodLength}
            onChange={handleChange}
            name="periodLength"
            min='2'
            max='12'
            list='monthsOptions'
            placeholder='00'
            step='1'
          />
        }
        {createDatalist(2, 12, 1, 'monthsOptions')}
        {data.period === 'years' &&
          <Input
            label="Years"
            required
            value={data.periodLength}
            onChange={handleChange}
            name="periodLength"
            min='2'
            list='yearsOptions'
            placeholder='000'
            step='1'
          />
        }
        {createDatalist(2, 30, 1, 'yearsOptions')}
      </FieldSet.Container>
      <FieldSet.Select>
        <InputSelect
          label="Select period"
          required
          value={data.period}
          onChange={e => setData({ ...data, period: e.target.value })}
          name="period"
          options={['Days', 'Months', 'Years']}
          defaultValue='Days'
        />
      </FieldSet.Select>
    </FieldSet>

    <FieldsContainer oneColumn>
      <Input
        label="Initial Investment"
        required
        value={data.initialInvestment}
        onChange={handleChange}
        min='0'
        name="initialInvestment"
      />
      {data.period !== 'days' && <Input
        label="Required return on equity %"
        required
        value={data.requiredReturnOnEquity}
        onChange={handleChange}
        name="requiredReturnOnEquity"
      />}
    </FieldsContainer>
  </>
);

export default ProjectFinance1;
