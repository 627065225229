import React from 'react';
import { info } from '../definitions';
import ReportCard from './ReportCard';
import { EditTitle } from '../../components';
import { FieldsContainer, Container } from '../../components/containers';
import { ReportCard as Styled } from './style';

const ProjectFinance5 = ({ data }) => <>
  { data.companyName ?
    <FieldsContainer>
      <EditTitle
        value={data.companyName}
        readOnly
      />
    </FieldsContainer>
    : <Container top='2rem' />
  }
  <Styled.Container>
    <ReportCard {...info.netPresentValue} value={data.netPresentValue} {...data.contingent.netPresentValue} />
    <ReportCard {...info.internalRateOfReturn} value={data.internalRateOfReturn} {...data.contingent.internalRateOfReturn} />
    <ReportCard {...info.payBackPeriod} value={data.payBackPeriod} {...data.contingent.payBackPeriod} />
    <ReportCard {...info.returnOnInvestment} value={data.returnOnInvestment} {...data.contingent.returnOnInvestment} />
    <ReportCard {...info.profitabilityIndex} value={data.profitabilityIndex} {...data.contingent.profitabilityIndex} />
    <ReportCard {...info.holdingPeriodReturn} value={data.holdingPeriodReturn} />
  </Styled.Container>
</>;

export default ProjectFinance5;
