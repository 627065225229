import React from 'react'

const Calendar = () => (

    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.5 5.33325C2.5 4.22868 3.39543 3.33325 4.5 3.33325H15.5C16.6046 3.33325 17.5 4.22868 17.5 5.33325V16.3333C17.5 17.4378 16.6046 18.3333 15.5 18.3333H4.5C3.39543 18.3333 2.5 17.4378 2.5 16.3333V5.33325Z" stroke="#868686" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.3333 1.66675V5.00008" stroke="#868686" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.66675 1.66675V5.00008" stroke="#868686" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.5 8.33325H17.5" stroke="#868686" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)
export default Calendar


