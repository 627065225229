import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FormSchema, CompanyName, ReportNotFound } from '../../components';
import { useSOV, useAuth, useCompanies } from '../../context';
import { Container } from '../../components/containers';
import { Label } from '../../components/elements';
import { schema } from './schema';
import { RequestMethods, useRequest } from '../../utils/requests';
import Loading from '../../hoc/loading/Loading';

const StockOptionValuationCompany = ({ setLoading }) => {
  const requestHandler = useRequest();
  const { addToHistory } = useAuth();
  const { symbol } = useParams();
  const { data, setData } = useSOV();
  const { companies } = useCompanies();

  useEffect(() => {

    const getRequest = async _ => {
      setLoading(true);
      try {
        const response = await requestHandler(
          RequestMethods.Get,
          `/stock-option-valuation/${symbol}`
        );

        if (!response?.data?.name) {
          const company = companies.find(
            company => company.symbol === symbol
          );

          setData({
            name: company.name,
            exchange: company.exchangeShortName,
            symbol,
            ...response?.data
          })
        } else {
          setData({ ...data, symbol, ...response?.data });

        }

        addToHistory({
          symbol,
          name: response?.data?.name,
          exchange: response?.data?.exchange,
          model: 'Stock Option Valuation'
        });

      } catch (err) {
        setData(null);
        console.error(err.message);
      }

      setLoading(false);
    };

    getRequest();

  // eslint-disable-next-line
  }, [symbol, setData]);

  if (!data) return <ReportNotFound />;

  return <>

    <Container top='2rem' bottom='1rem'>
      {data.name && <>
        <Label>Company Name</Label>
        <h3><CompanyName name={data.name} symbol={data.symbol} /></h3>
      </>}
    </Container>
    <FormSchema schema={schema} />

  </>
};

export default Loading(StockOptionValuationCompany, `Loading company's reports...`);
