import React, { useEffect } from 'react'
import { RequestMethods, useRequest } from '../../utils';
import { info, notesForPublicCompany } from '../definitions';
import { Input, IntristicValueResult } from '../../components';
import { FieldsContainer } from '../../components/containers';

const ContingentResults = ({ symbol, data, setData }) => {
  const requestHandler = useRequest();

  useEffect(() => {

    const getContingentResults = async _ => {
      try {
        const results = await requestHandler(
          RequestMethods.Post,
          `/current-market-cap/${symbol}`,
          { enterpriseValue: data[`enterpriseValue-${data.model}`] }
        );
        setData({ ...data, ...results?.data });
      } catch (error) {
        console.error(error);
      }
    }
    getContingentResults();
  
    // eslint-disable-next-line
  }, []);

  const enterpriseValue = data[`enterpriseValue-${data.model}`];
  return (
    <div>
      <FieldsContainer twoColumns>
        <Input {...info.enterpriseValue} value={enterpriseValue} readOnly />
        <Input
          {...info.currentMarketCap}
          value={data.currentMarketCap}
          readOnly
        >
        </Input>
        <Input {...info.revenue} value={data.revenue} readOnly />
        <Input {...info.netIncome} value={data.netIncome} readOnly />
      </FieldsContainer>
      <IntristicValueResult
        title='The Company is '
        contingentResult={data.contingentResult}
        negativeCashflow={enterpriseValue < 0 ? notesForPublicCompany.negativeCashflow : false}
        externalFactors={
          enterpriseValue > 2 * data.currentMarketCap || enterpriseValue < 0.3 * data.currentMarketCap ?
            notesForPublicCompany.externalFactors : false}
      />
    </div>
  )
}

export default ContingentResults
