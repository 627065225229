import React from 'react';
import { useUserHistory } from '../../context';
import HistoryItem from './HistoryItem';
import { Table } from './style';

const HistoryTableBody = () => {
    const { filteredHistory } = useUserHistory();

    if (filteredHistory.length === 0) return (
        <Table.Row />
    )

    const getDates = () => {
        let arr = [];
        filteredHistory.map(company => (
            company.date !== arr[arr.length - 1] &&
            arr.push(company.date)
        ));
        return arr;
    }

    const dates = getDates();

    const groupByDates = _ => {
        let obj = {};
        dates.forEach(date => {
            obj[date] = filteredHistory.filter(
                company => company.date === date
            )
        })
        return obj;
    }

    const groupedHistory = groupByDates();

    return <>
        {dates.map(date => <>

            <Table.RowDate key={date}>
                <th scope='rowgroup'>{date}</th>
            </Table.RowDate>
            {groupedHistory[date].map((item, key) => (
                <HistoryItem key={key} {...item} />
            ))}

        </>)}
    </>
}

export default HistoryTableBody
