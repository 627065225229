import styled from 'styled-components';

const Text = styled.p`
  font-size: ${props =>
    (props.small && '0.875em') ||
    (props.large && '1.25em') ||
    (props.font && `${props.font}em`) ||
    '1em'};
  line-height: 1.5;
  font-weight: ${props => (props.bold ? 700 : 400)};
  color: ${props => (props.dark && '#4D4D4D') || props.color || '#68717A' };
  margin-bottom: 0.8em;
  @media only screen and (max-width: 600px) {
    font-size: ${props => props.font === 1.5 && '1.125em'}
  }
`;

export default Text;
