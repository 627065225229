import React from 'react';
import { H1, Text } from '../../components/elements';

const ErrorPage = () => (

    <>
        <H1>404 Error</H1>
        <Text dark> Sorry, this page is not found. </Text>
    </>

)

export default ErrorPage
