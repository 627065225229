import React from 'react';
import { datasetsDefault, optionsDefault, scalesDefault } from '../../utils/graphs';
import { Line } from 'react-chartjs-2';

const Graph = ({ data }) => {
    const { labels, profit, breakEvenPrice, exercisePrice } = data;

    const graph = {
        labels: labels,
        datasets: [
            {
                ...datasetsDefault,
                label: 'Profit',
                data: profit
            }
        ]
    }

    const options = {
        ...optionsDefault,
        title: {
            display: true,
            text: 'Profit per Share Price'
        },
        tooltips: {
            custom: function (tool) {
                if (!tool) return;
                tool.displayColors = false;
            },
            callbacks: {
                title: function (tooltipItem) {
                    if (tooltipItem[0].xLabel === breakEvenPrice) {
                        return 'Break Even Price: ' + tooltipItem[0].xLabel + ' $';
                    } else if (tooltipItem[0].xLabel === exercisePrice) {
                        return 'Exercise Price: ' + tooltipItem[0].xLabel + ' $';
                    } else return 'Price: ' + tooltipItem[0].xLabel + ' $';
                },
                label: function (tooltipItem) {
                    return 'Profit: ' + tooltipItem.yLabel + " $ ";
                }
            }
        },
        parsing: false,
        scales: {
            yAxes: [{
                ...scalesDefault.yAxes[0],
                scaleLabel: {
                    display: true,
                    labelString: 'Profit'
                }
            }],
            xAxes: [{
                ...scalesDefault.xAxes[0],
                scaleLabel: {
                    display: true,
                    labelString: 'Stock Price',
                }
            },
            ]
        }
    }

    return (
        <div>
            {
                labels && data &&
                <Line id={'graph' + data.type} data={graph} options={options} />
            }
        </div>
    )
}

export default Graph