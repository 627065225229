import styled from 'styled-components';


const Row = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 720px;
    width: 80%;
    align-items: flex-end;
    margin-top: 2rem;
    flex-wrap: wrap;
    > :first-child {     
            margin-right: 5%;
        }
    > div {
        margin-bottom: 1rem;
    }
`;

const Checkbox = styled.div``

Checkbox.Label = styled.label`
    background-color: #DEE9FF;
    border-radius: 7px;
    color: #366EDA;
    display: flex;
    align-items: center;
    min-height: 38px;
    padding: 0 0.75em;
`;

Checkbox.Input = styled.input.attrs({
    type: 'checkbox'
})`
    width: 0px;
    height: 0px;
    opacity: 0;
    + span {
       background-color:  ${props => props['aria-checked'] && '#366EDA'};
    }    
    + span:before {
        transform:  ${props => props['aria-checked'] && 'translateX(calc(0.875em - 2px))'};
     }
`;

Checkbox.Switch = styled.span`
    cursor: pointer;
    position: relative;
    border-radius: 2em;
    border: 2px solid #366EDA;
    transition: .4s;
    height: 1em;
    width: 1.75em;
    display: block;
    margin-left: 1em;
    :before {
        content: '';
        position: absolute;
        height: calc(0.875em - 2px);
        width: calc(0.875em - 2px);
        top: -2px;
        left: -2px;
        border: 2px solid #366EDA;
        border-radius: 100%;
        transition: .4s;
        background-color: #DEE9FF;
    }
`;

export { Row, Checkbox }