import React from 'react';
import * as schema from './schema';
import { Heading, Row, Container } from '../pdf/Frame';
import { View } from '@react-pdf/renderer';
import stylesPDF from '../pdf/style';

const IncomeStatement = ({ data, name, symbol }) => {

  return (
      <Container name={name} symbol={symbol} title='Income Statement'>

         <Row data={data} item={schema.totalRevenue} bold />

         <Row data={data} item={schema.costOfRevenue} bold />

         <Row data={data} item={schema.grossProfit} bold />
         
         <View style={stylesPDF.border} />
        <Heading>Operating Expenses</Heading>
        <View style={stylesPDF.border} />
        {schema.operatingExpenses.map(item => (
          <Row key={item.api} data={data} item={item} />
        ))} 
        
        <View style={stylesPDF.border} />
        <Heading>Operating Income or Loss</Heading>
        <View style={stylesPDF.border} />
        {schema.operatingIncomeOrLoss.map(item => (
          <Row data={data} item={item} />
        ))}

        <View style={stylesPDF.border} />
         <Row data={data} item={schema.earningsBeforeITDA} bold />

      </Container>
  );
};

export default IncomeStatement;
