import React from 'react';
import { Refresh } from '../../assets/svgComponents';
import Button from './style';

const RefreshPrice = ({ ...rest }) => (

    <Button {...rest}>
        <Refresh />
    </Button>
)

export default RefreshPrice
