import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Search = styled.form`
    position: relative;
    padding-top: 2.5em;
    > div {
        margin-bottom: 0;
    }
`;

Search.Options = styled.ul`
    display: none;
    position: absolute;
    top: calc(2.6em + 2px);
    background-color: white;
    width: 568px;
    max-width: calc(80vw - 86px);
    border: 1px solid #D6d6d6;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    &:focus,
    &:hover {
        display: block;
    }
`;

Search.Options.Link = styled(Link)`
    padding: 0.5em;
    padding-left: 55px;
    display: block;
    color: #444;
    font-weight: 500;
    span {       
        color: #999;
    }
    @media only screen and (max-width: 1024px) {
        padding-left: 1em;
    }
    @media only screen and (max-width: 480px) {
        font-size: 0.875em;
    }
`;

Search.ViewAll = styled.li`
    border-top: 1px solid #D6d6d6;
    padding: 0.5em 0;
    text-align: center;
    button {
        background-color: transparent;
        border: none;
        color: #366eda;
        font-size: 1em;
    }
`;

const List = styled.ul``;

List.Item = styled.li`
    display: flex;
    flex-direction: column;
    margin-bottom: 0.75em;
    * {
        line-height: 1.2;
        display: inline-block;
    }
`;

List.Title = styled.p`
    color: #305FBB;
    font-size: 1.25em;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin: 2em 0 0.75em 0;
    svg {
        margin-right: 0.5em;
        width: 1.25em;
        height: 1.25em;
    };
    path:first-child {
        stroke: #305FBB;
        fill: #305FBB;
    }
    @media only screen and (max-width: 1024px) {
        font-size: 1em;
        svg {
            padding: 0.1em;
        }
    }
`;

List.Note = styled.p`
    font-size: 1.125em;
    margin: 2em 0 0.75em 0;
    color: #bfbdbd;
    em {
        color: #6a7073;
        font-weight: 500;
    }
`;

export { Search, List};