import React from 'react';
import { useHistory } from 'react-router-dom';
import { TextOpenSans } from '../../components/elements';
import { Main } from '../../components/containers';
import { ArrowRight } from '../../assets/svgComponents';
import { homeSignupBg } from '../../assets/images';
import Home from './style';

const Signup = () => {
  const history = useHistory();

  return (
    <Main.SectionBg img={homeSignupBg}>
      <Home.Inner>
        <Home.TextContainer>
          <h2>
            <TextOpenSans white bold small>
              Become a better informed investor with Valuatr.
            </TextOpenSans>
          </h2>
          <TextOpenSans small white>
            Sign up now to experience the future of business valuation and
            investment support.
          </TextOpenSans>
          <Home.Button white onClick={() => history.push('/sign-up')}>
            Sign Up
            <ArrowRight />
          </Home.Button>
        </Home.TextContainer>
      </Home.Inner>
    </Main.SectionBg>
  );
};

export default Signup;
