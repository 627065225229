import React from 'react';
import { Link } from 'react-router-dom';
import { Logo, HeaderModal, ModalInviteFriend, ModalAccount } from '..';
import { Home, Financials, News, History } from '../../assets/svgComponents';
import { useAuth } from '../../context';
import { HeaderMobile as Header} from './style';

const HeaderMobile = () => {
    const { handleSignOut } = useAuth();

    return (

        <Header>
            <Header.Inner>
                <Logo white />
                <HeaderModal>
                    <nav>
                        <Header.NavList>
                            <Header.NavItem>
                                <Logo />
                            </Header.NavItem>
                            <Header.Line noTopMargin />
                            <Header.NavItem>
                                <Link to='/dashboard'>
                                    <Home />
                                    Dashboard
                                </Link>
                            </Header.NavItem>
                            <Header.NavItem>
                                <Link to='/financials'>
                                    <Financials />
                                    Financials
                                </Link>
                            </Header.NavItem>
                            <Header.NavItem>          
                                <Link to='/news'>
                                    <News />
                                    News
                                </Link>
                            </Header.NavItem>
                            <Header.NavItem>
                                <Link to='/history'>
                                    <History />
                                    History
                                </Link>
                            </Header.NavItem>
                            <Header.Line />
                            <Header.NavItem className='closeHeader'>
                                <ModalAccount />
                            </Header.NavItem>
                            <Header.NavItem className='closeHeader'>
                                <ModalInviteFriend />
                            </Header.NavItem>
                            <Header.NavItem>
                                <button onClick={handleSignOut}>
                                    Sign Out
                                </button>
                            </Header.NavItem>
                        </Header.NavList>
                    </nav>
                </HeaderModal>
            </Header.Inner>
        </Header>
    )
}

export default HeaderMobile
