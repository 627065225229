import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePublicCompany, useAuth} from '../../context';
import { FormSchema, CompanyName } from '../../components';
import { Label } from '../../components/elements';
import { schema } from './schema';
import { RequestMethods, useRequest } from '../../utils/requests';
import Loading from '../../hoc/loading/Loading';
import { Container } from '../../components/containers';

const PublicCompany = ({ setLoading }) => {
  const requestHandler = useRequest();
  const { addToHistory } = useAuth();
  const { symbol } = useParams();
  const { data, setData } = usePublicCompany();

  useEffect(() => {
    
    const getRequest = async _ => {  
      setLoading(true);
      try {
        const response = await requestHandler(
          RequestMethods.Get,
          `/public-company/${symbol}`
        );
        
        addToHistory({
          symbol,
          name: response?.data?.name,
          exchange: response?.data?.exchange,
          model: 'Public Companies'
        });
        
        setData({ symbol, ...response?.data });
      } catch (err) {
        console.error(err);
      }

      setLoading(false);
    };

    getRequest();

  // eslint-disable-next-line
  }, [symbol, setData]);

  return <>

    <Container top='2rem' bottom='3rem'>
      <Label>Company Name</Label>
      <h3><CompanyName name={data.name} symbol={symbol.data} /></h3>
      <Label gray><em>Showing reports for year {data.date} </em></Label>
    </Container>
    <FormSchema schema={schema} />
  </>
};

export default Loading(PublicCompany, `Loading company's reports...`);
