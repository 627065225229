import React from 'react';
import { useRouteMatch, Switch, Route } from 'react-router-dom';
import { CompaniesContextProvider } from '../../context/Companies';
import { PublicInvestments, BusinessValuation, ProjectFinance, ErrorPage } from '..';
import DashboardLanding from './DashboardLanding';
import { Aside } from '../../components';
import { ContentWrapper, DashboardMain as Main } from '../../components/containers';

const Dashboard = () => {
  const { path } = useRouteMatch();

  return (
    <ContentWrapper>
      <Aside />
      <CompaniesContextProvider>
        <Switch>
          <Route exact path={path} component={DashboardLanding} />
          <Route
            path={`${path}/public-investments`}
            component={PublicInvestments}
          />
          <Route
            path={`${path}/business-valuation`}
            component={BusinessValuation}
          />
          <Route exact path={`${path}/project-finance`} component={ProjectFinance} />
          <Route>
            <Main>
              <Main.Inner>
                <ErrorPage />
              </Main.Inner>
            </Main>
          </Route>
        </Switch>
      </CompaniesContextProvider>
    </ContentWrapper>
  );
};

export default Dashboard;
