import React from 'react';
import { useParams } from 'react-router';
import ContingentResults from './ContingentResults';
import Graph from './Graph';
import { Container } from './style';

const PublicCompany3 = ({ data, setData }) => {
  const { symbol } = useParams();


  return (
    <Container>
      <ContingentResults symbol={symbol} data={data} setData={setData} />
      {data.graph && <Graph data={data.graph} />}
    </Container>
  );
}

export default PublicCompany3;
