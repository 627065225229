import React from 'react';

const Pdf = () => (

    <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <title>Pdf download</title>
        <path d="M2.875 0H10.7812V1.4375H2.875C2.49375 1.4375 2.12812 1.58895 1.85853 1.85853C1.58895 2.12812 1.4375 2.49375 1.4375 2.875V20.125C1.4375 20.5062 1.58895 20.8719 1.85853 21.1415C2.12812 21.411 2.49375 21.5625 2.875 21.5625H14.375C14.7562 21.5625 15.1219 21.411 15.3915 21.1415C15.661 20.8719 15.8125 20.5062 15.8125 20.125V6.46875H17.25V20.125C17.25 20.8875 16.9471 21.6188 16.4079 22.1579C15.8688 22.6971 15.1375 23 14.375 23H2.875C2.1125 23 1.38123 22.6971 0.842068 22.1579C0.302901 21.6188 0 20.8875 0 20.125V2.875C0 2.1125 0.302901 1.38124 0.842068 0.842068C1.38123 0.302901 2.1125 0 2.875 0V0Z" fill="#3D7BF2"/>
        <path d="M10.7812 4.3125V0L17.25 6.46875H12.9375C12.3656 6.46875 11.8172 6.24157 11.4128 5.8372C11.0084 5.43282 10.7813 4.88437 10.7812 4.3125Z" fill="#3D7BF2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M4.3125 16.5312C4.3125 16.3406 4.38823 16.1578 4.52302 16.023C4.65781 15.8882 4.84063 15.8125 5.03125 15.8125H7.90625C8.09687 15.8125 8.27969 15.8882 8.41448 16.023C8.54927 16.1578 8.625 16.3406 8.625 16.5312C8.625 16.7219 8.54927 16.9047 8.41448 17.0395C8.27969 17.1743 8.09687 17.25 7.90625 17.25H5.03125C4.84063 17.25 4.65781 17.1743 4.52302 17.0395C4.38823 16.9047 4.3125 16.7219 4.3125 16.5312ZM4.3125 13.6562C4.3125 13.4656 4.38823 13.2828 4.52302 13.148C4.65781 13.0132 4.84063 12.9375 5.03125 12.9375H12.2188C12.4094 12.9375 12.5922 13.0132 12.727 13.148C12.8618 13.2828 12.9375 13.4656 12.9375 13.6562C12.9375 13.8469 12.8618 14.0297 12.727 14.1645C12.5922 14.2993 12.4094 14.375 12.2188 14.375H5.03125C4.84063 14.375 4.65781 14.2993 4.52302 14.1645C4.38823 14.0297 4.3125 13.8469 4.3125 13.6562ZM4.3125 10.7812C4.3125 10.5906 4.38823 10.4078 4.52302 10.273C4.65781 10.1382 4.84063 10.0625 5.03125 10.0625H12.2188C12.4094 10.0625 12.5922 10.1382 12.727 10.273C12.8618 10.4078 12.9375 10.5906 12.9375 10.7812C12.9375 10.9719 12.8618 11.1547 12.727 11.2895C12.5922 11.4243 12.4094 11.5 12.2188 11.5H5.03125C4.84063 11.5 4.65781 11.4243 4.52302 11.2895C4.38823 11.1547 4.3125 10.9719 4.3125 10.7812Z" fill="#3D7BF2"/>
    </svg>

)

export default Pdf


