import { noop } from '../../utils';
import FinancialStrength1 from './FinancialStrength1';
import FinancialStrength2 from './FinancialStrength2';

export const schema = {
  page1: {
    component: FinancialStrength1,
    getPrevious: noop,
    getNext: () => 'page2',
    nextButton: 'Generate Report',
  },
  page2: {
    component: FinancialStrength2,
    getPrevious: () => 'page1',
    getNext: noop,
    nextButton: 'Done',
  },
  history: '/public-investments/financial-strength',
  hook: 'useFinancialStrength'
};
