import { noop } from '../../utils';
import EnterpriseValue from './EnterpriseValue';
import PrivateCompany1 from './PrivateCompany1';
import PrivateCompany2 from './PrivateCompany2';
import PrivateCompany3 from './PrivateCompany3';
import PrivateCompany4 from './PrivateCompany4';

export const schema = {
  page1: {
    component: PrivateCompany1,
    getPrevious: noop,
    getNext: () => 'page2',
    nextButton: 'Next',
  },
  page2: {
    component: PrivateCompany2,
    getPrevious: () => 'page1',
    getNext: formData =>
    formData?.calculateFinancialStrength
      ? 'page3'
      : 'enterpriseValue',
    onNext: async (formData, setFormData, requestHandler, RequestMethods) => {
      try {
        const response = await requestHandler(
            RequestMethods.Post,
          `/${formData.model}-private`,
          formData
        );
        setFormData({ ...formData, ...response?.data });
      } catch (error) {
        console.error(error);
      }
    },
    nextButton: 'Next',
  },
  page3: {
    component: PrivateCompany3,
    getPrevious: () => 'page2',
    getNext: () => 'report',
    onNext: async (formData, setFormData, requestHandler, RequestMethods) => {
      try {
        const response = await requestHandler(
          RequestMethods.Post,
          '/private-company',
          formData,
        );
        setFormData({ ...formData, ...response?.data });
      } catch (error) {
        console.error(error);
      }
    },
    nextButton: 'Generate Report',
  },
  report: {
    component: PrivateCompany4,
    getPrevious: () => 'page3',
    getNext: noop,
    nextButton: 'Done',
  },
  // only Enterprise Value
  enterpriseValue: {
    component: EnterpriseValue,
    getPrevious: () => 'page2',
    getNext: noop,
    nextButton: 'Done',
  },
  hook: 'usePrivateCompany',
  history: '/business-valuation',
};
