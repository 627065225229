import React from 'react';
import { InputSelect } from '../../components';
import {
  DiscountCashflowModel,
  CapitalizedEarningsModel,
  AssetBasedApproach,
} from './PrivateCompanyModels';
import { models } from '../definitions';
import { Row, Checkbox } from './style';
import { Container } from '../../components/containers';
import { Bold } from '../../components/elements';

const PrivateCompany1 = ({ data, setData, handleChange, handleCheckbox }) => (

    <div>
      <Row>
        <InputSelect
          label="Select the type of model"
          name="model"
          options={[ models.DCM.label, models.CEM.label, models.ABA.label ]}
          required
          value={data.model}
          onChange={e => setData({...data, model: e.target.value})}
        />
        <Checkbox>
          <Checkbox.Label>
            Calculate Financial Strength       
          <Checkbox.Input 
            name="calculateFinancialStrength"
            value={data.calculateFinancialStrength}
            onChange={handleCheckbox}
            aria-checked={data.calculateFinancialStrength}
          />
          <Checkbox.Switch />    
        </Checkbox.Label>
        </Checkbox>
      </Row>

      <Container top='2.5rem'>
        {data.model === models.DCM.name && (
          <DiscountCashflowModel data={data} handleChange={handleChange} />
        )}
        {data.model === models.CEM.name && (
          <CapitalizedEarningsModel data={data} handleChange={handleChange} />
        )}
        {data.model === models.ABA.name && (
          <AssetBasedApproach data={data} handleChange={handleChange} />
        )}
        {!data.model && <>
          <div>
            <Bold>{models.DCM.label}</Bold>
            <p>{models.DCM.info}</p>
          </div>
          <div>
            <Bold>{models.CEM.label}</Bold> 
            <p>{models.CEM.info}</p>
          </div>
          <div>
            <Bold>{models.ABA.label}</Bold> 
            <p>{models.ABA.info}</p>
          </div>
        </>}
      </Container>
    </div>

);


export default PrivateCompany1;
