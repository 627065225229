import styled from 'styled-components';

const Select = styled.select`
    padding: 0 0.75em;
    background-color: #ededed;
    background-image: none;
    -moz-appearance: none; 
	-webkit-appearance: none; 
	appearance: none;
`;

Select.Label = styled.label`
  font-size: 1em;
  color: #4d4d4d;
  font-weight: 500;
  display: inline-block;
  padding-bottom: 0.3em;
`;

export default Select;