import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '..';

import Footer from './style';

const FooterComponent = () => (

    <Footer>
      <Footer.Inner>
        <Footer.Column>
          <div>
            <Logo white />
            <Footer.Text small>
              Valuatr is an online financial calculator and investment aid based
              out of North America.
            </Footer.Text>
          </div>
          <div>
            <Footer.Title>Contact Us</Footer.Title>
            <a href="mailto:info@valuatr.ca">info@valuatr.ca</a>
          </div>
        </Footer.Column>
        <Footer.Column>
          <Footer.Nav>
            <div>
              <Footer.Title>Valuatr</Footer.Title>
              <Link to="/dashboard">Dashboard</Link>
              <Link to="/faqs">FAQs</Link>
            </div>
            <div>
              <Footer.Title>Company</Footer.Title>
              <Link to="/about">About Us</Link>
              <Link to="/contact">Contact Us</Link>
            </div>
            <div>
              <Footer.Title>Legal</Footer.Title>              
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </div>
          </Footer.Nav>
          <div>
            <Footer.Note>
              Data provided by&nbsp;
              <a href='https://financialmodelingprep.com/developer/docs/' target='_blank' rel='noreferrer'>
                Financial Modeling Prep
              </a>
            </Footer.Note>
            <Footer.Note>Copyright © 2021 Valuatr Inc. All Rights Reserved.</Footer.Note>
          </div>
        </Footer.Column>
      </Footer.Inner>
    </Footer>
);

export default FooterComponent;
