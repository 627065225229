import styled from 'styled-components';

const TextOpenSans = styled.p`
  font-size: ${props => (props.small && '1em') || '1.25em'};
  line-height: 1.6;
  font-weight: ${props => (props.bold ? 700 : 400)};
  color: ${props =>
    (props.white && '#FFF') || (props.dark && '#444') || '#626262'};
  margin-bottom: 0.8em;
  font-family: 'Open Sans', sans-serif;
`;

export default TextOpenSans;
