import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import {
  FinancialStrength,
  SharePriceValuation,
  StockOptionValuation,
  ErrorPage
} from '..';
import PublicInvestmentsLanding from './PublicInvestmentsLanding';
import { DashboardHeader } from '../../components';
import { DashboardMain as Main } from '../../components/containers';

const PublicInvestments = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <DashboardHeader title="Public Investments" back="/dashboard" />

        <Switch>
          <Route exact path={path} component={PublicInvestmentsLanding} />
          <Route
            path={`${path}/share-price-valuation`}
            component={SharePriceValuation}
          />
          <Route
            path={`${path}/stock-option-valuation`}
            component={StockOptionValuation}
          />
          <Route
            path={`${path}/financial-strength`}
            component={FinancialStrength}
          />
          <Route>
            <Main>
              <Main.Inner>
                <ErrorPage />
              </Main.Inner>
            </Main>
          </Route>
        </Switch>

    </>
  );
};

export default PublicInvestments;
