import React, { createContext, useContext, useState } from 'react';

const ProjectFinanceContext = createContext();

const useProjectFinance = _ => useContext(ProjectFinanceContext);

const ProjectFinanceContextProvider = ({ children }) => {
  const [data, setData] = useState({
    period: 'days',
    days: null,
    months: null,
    years: null,
    initialInvestment: null,
    requiredReturnOnEquity: null,
    betaRisk: null,
    riskFreeRate: null,
    marketReturn: null,
    weightOfEquity: null,
    weightOfDebt: null,
    costOfEquity: null,
    costOfDebt: null,
    taxRate: null,
    WACC: null,
    fixedCashFlow: 'Yes',
    fixedGrowth: 'Yes',
    cashFlowPerYear: null,
    cashFlowInYear1: null,
    growthRate: null,
    cashFlowYearwise: [],
  });

  return (
    <ProjectFinanceContext.Provider value={{ data, setData }}>
      {children}
    </ProjectFinanceContext.Provider>
  );
};

export { useProjectFinance, ProjectFinanceContextProvider };
