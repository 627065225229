import React, { useEffect } from 'react';
import { Input } from '../../components';
import { createDatalist } from '../../utils';
import { info } from '../definitions';
import { FieldsContainer as Container } from '../../components/containers';
import { Text } from '../../components/elements';

export const DiscountDividendModel = ({ data, setData }) => {

  useEffect(() => {
    if (data.DDM) {
      setData({ ...data, disableNext: false })
    } else {
      setData({ ...data, disableNext: true })
    }
    // eslint-disable-next-line
  }, []);

  return data.disableNext ?
    <Text>Sorry, we are unable to retrieve dividend statement for this company.</Text>
    : (
      <Container oneColumn>
        <Input {...info.dividendPerShare} value={data.dividendPerShare} readOnly />
        <Input {...info.growthRate} value={data.growthRate} readOnly />
      </Container>
    );
}

export const DiscountCashflowModel = ({ data, onChange, setData }) => {

  useEffect(() => {
    if (data.DCM) {
      setData({ ...data, disableNext: false })
    } else {
      setData({ ...data, disableNext: true })
    }
  // eslint-disable-next-line
  }, []);

  return data.disableNext ?
    <Text>Sorry, we are unable to retrieve data for this company.</Text>
    : (
      <Container>
        <Input
          {...info.terminalGrowthRate}
          value={data.terminalGrowthRate}
          onChange={onChange}
          required
          list='terminalGrowthRateOptions'
        />
        {createDatalist(1, 4, 1, 'terminalGrowthRateOptions')}
        <Input {...info.CCARate} readOnly value={data.CCARate} />
        <Input {...info.interestExpense} readOnly value={data.interestExpense} />
        <Input {...info.netIncome} readOnly value={data.netIncome} />
        <Input {...info.taxRate} readOnly value={data.taxRate} />
        <Input {...info.capitalExpenditure} readOnly value={data.capitalExpenditure} />
        <Input {...info.workingCapital} readOnly value={data.workingCapital} />
        <Input {...info.outstandingShares} readOnly value={data.outstandingShares} />
      </Container>
    );
}