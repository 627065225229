import React from 'react';
import { useHistory } from 'react-router-dom';
import { Logo, HeaderModal } from '..';
import { Button } from '../elements';
import { useAuth } from '../../context/User';
import HeaderNav from './HeaderNav';
import HeaderMobile from './HeaderMobile';
import { default as Styled } from './style';

const HeaderDefault = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
  
    return <>
      
        <Logo color="blue" />

        <HeaderModal>
            <HeaderMobile />
        </HeaderModal>

        <HeaderNav />
  
        <div className='desktop'>
            {currentUser
    
            ? <Button onClick={() => history.push('/dashboard')}>Dashboard</Button>
            
            : <Styled.Container>
                <Styled.Secondary onClick={() => history.push('/login')}>
                    Login
                </Styled.Secondary>
                <Styled.Button onClick={() => history.push('/sign-up')}>Sign Up</Styled.Button>
                </Styled.Container>
            }
        </div>
    </>
  };

  export default HeaderDefault;