import React from 'react';
import { Input, ContingentResult, EditTitle } from '../../components';
import { Container, FieldsContainer } from '../../components/containers';
import { info } from '../definitions';
import { H4 } from '../../components/elements';

const PrivateCompany4 = ({ data }) => <>

  {data.companyName?
    <FieldsContainer>
      <EditTitle
        value={data.companyName}
        readOnly
      />
    </FieldsContainer>

    : <Container top='2rem' />
    }

  <FieldsContainer oneColumn top>
    <Input
      {...info.enterpriseValue}
      value={
        data.model === 'discount-cashflow-model'? data['enterpriseValue-discount-cashflow-model'] : 
        data.model === 'capitalized-earnings-model'? data['enterpriseValue-capitalized-earnings-model'] 
        : data.enterpriseValue
      }
      readOnly
    />
  </FieldsContainer>

  <section>
    <H4>Profitability</H4>
    <FieldsContainer top twoColumns>
      <ContingentResult
        {...info.returnOnEquity}
        value={data.returnOnEquity}
        {...data.contingent.returnOnEquity}
      />
      <ContingentResult
        {...info.returnOnAssets}
        value={data.returnOnAssets}

      />
      <ContingentResult
        {...info.grossProfitMargin}
        value={data.grossProfitMargin}
        {...data.contingent.grossProfitMargin}
      />
      <ContingentResult
        {...info.netProfitMargin}
        value={data.netProfitMargin}
        {...data.contingent.netProfitMargin}
      />
    </FieldsContainer>
  </section>

  <section>
    <H4>Liquidity</H4>
    <FieldsContainer top>
      <ContingentResult
        {...info.currentRatio}
        value={data.currentRatio}
        {...data.contingent.currentRatio}
      />
      <ContingentResult
        {...info.quickRatio}
        value={data.quickRatio}
        {...data.contingent.quickRatio}
      />
    </FieldsContainer>
  </section>
  <section>
    <H4>Leverage</H4>
    <FieldsContainer top>
      <ContingentResult
        {...info.debtEquityRatio}
        value={data.debtEquityRatio}
        {...data.contingent.debtEquityRatio}
      />
      <ContingentResult
        {...info.debtToEBITDA}
        value={data.debtToEBITDA}
      />
    </FieldsContainer>
  </section>
  <section>
    <H4>Solvency</H4>
    <FieldsContainer top>
      <ContingentResult
        {...info.solvencyRatio}
        value={data.solvencyRatio}
        {...data.contingent.solvencyRatio}
      />
    </FieldsContainer>
  </section>

</>

export default PrivateCompany4;
