import styled from 'styled-components';

const Input = styled.textarea.attrs({
    rows: 5
})`
display: block;
  padding: 0 0.75em;
  width: 100%;
  border: none;
  background-color: #ededed;
  border-radius: 8px;
  border: 2px solid transparent;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
`;

Input.Container = styled.div`
    width: 100%;
    margin-bottom: 1em;
`;

Input.Label = styled.label`
  font-size: 1em;
  color: #4d4d4d;
  font-weight: 500;
  display: inline-block;
  padding-bottom: 0.3em;
`;

export default Input;