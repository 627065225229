import styled from 'styled-components';

const Link = styled.span`
    color: #3D7bf2;
    display: flex;
    align-items: center;
    margin-top: 3%;
    svg {
        margin-right: 0.5em;
    };
    &:hover {
        text-decoration: underline;
    };
`;

export default Link;