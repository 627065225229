import React, { useState, useEffect } from 'react'
import { Input, IntristicValueResult, RefreshPrice } from '../../components';
import { FieldsContainer } from '../../components/containers';
import { info, notesForSPV } from '../definitions';
import { RequestMethods, useRequest } from '../../utils/requests';

const IntristicValue = ({ data, setData, symbol }) => {
  const requestHandler = useRequest();
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {

    const getContingentResults = async _ => {
      try {
        const results = await requestHandler(
          RequestMethods.Post,
          `/current-price/${symbol}`,
          { intristicValue: data[`intristicValue-${data.model}`] }
        );

        setData({ ...data, ...results?.data });
      } catch (error) {
        console.error(error);
      }
    }
    getContingentResults();
  // eslint-disable-next-line
  }, [refresh]);

  const intristicValue = data[`intristicValue-${data.model}`];

  return <>

    <FieldsContainer twoColumns>
      <Input {...info.intristicValue} value={intristicValue} readOnly />
      <Input
        {...info.currentMarketPrice}
        value={data.currentMarketPrice}
        readOnly
      >
        <RefreshPrice onClick={() => setRefresh(!refresh)} />
      </Input>
      <Input
        {...info.priceToEarningsRatio}
        value={data.priceToEarningsRatio}
        readOnly
      />
      <Input
        {...info.earningsPerShare}
        value={data.earningsPerShare}
        readOnly
      />
    </FieldsContainer>
    <IntristicValueResult
      title='The share price is '
      contingentResult={data.contingentResult}
      negativeCashflow={intristicValue < 0 ? notesForSPV.negativeCashflow : false }
      externalFactors={
        intristicValue > 2 * data.currentMarketPrice || intristicValue <  0.3 * data.currentMarketPrice ? 
          notesForSPV.externalFactors : false
      }
    />
  </>
}

export default IntristicValue
