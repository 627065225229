import styled from 'styled-components';

const Label = styled.label`
  color: ${props => props.color || (props.gray && '#9D9D9D') || '#366eda'};
  font-size: 0.75em;
  font-weight: 400;
  display: block;
`;

export default Label;
