import styled from 'styled-components'

const FormMessage = styled.b`
    color: ${props => props.type === 'sucess'? '#27836d' : '#b50000'};
    display: block;
    margin-top: 1em;
    width: 100%;
`;

export default FormMessage
