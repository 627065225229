import React from 'react';
import { Page, Text, View, Document } from '@react-pdf/renderer';
import stylesPDF from './style';

const Heading = ({ children }) => <>
    <View style={stylesPDF.headingRow}>
        <Text>{children}</Text>
    </View>
</>;

const Row = ({ data, item, bold }) => {
    const [year1, year2, year3] = data;
    const title = bold? stylesPDF.rowTitleBold : stylesPDF.rowTitle;

    return (
        <View style={stylesPDF.row}>
            <Text style={title}>{item.title}</Text>
            <Text style={stylesPDF.rowCell}>{year1[item.api]}</Text>
            <Text style={stylesPDF.rowCell}>{year2[item.api]}</Text>
            <Text style={stylesPDF.rowCell}>{year3[item.api]}</Text>
        </View>
    )
};

const Container = ({ children, name, symbol, title }) => (
    <Document>
      <Page size="A4" style={stylesPDF.page}>

            <View>
                <Text style={stylesPDF.title}>{title}</Text>
            </View>

            <View style={stylesPDF.border} />
            <View style={stylesPDF.headingRow}>
                    <View style={stylesPDF.rowTitle}>
                        <Text style={stylesPDF.label}>Company Name</Text>
                        <Text style={stylesPDF.bold}>{name} {symbol}</Text>
                    </View>
                    <View style={stylesPDF.headingYear}>
                        <Text style={stylesPDF.label}>Year</Text>
                        <Text style={stylesPDF.bold}>2020</Text>
                    </View>
                    <View style={stylesPDF.headingYear}>
                        <Text style={stylesPDF.label}>Year</Text>
                        <Text style={stylesPDF.bold}>2019</Text>
                    </View>
                    <View style={stylesPDF.headingYear}>
                        <Text style={stylesPDF.label}>Year</Text>
                        <Text style={stylesPDF.bold}>2018</Text>
                    </View>
            </View>
            <View style={stylesPDF.border} />

            {children}

            <View style={stylesPDF.border} />
        </Page>
    </Document>
)

export { Heading, Row, Container };