import React from 'react';
import {
  Facebook,
  LinkedIn,
  Instagram,
  Twitter,
} from '../../assets/svgComponents';
import { Social } from './style';

const Socials = () => (
  <Social>
    <Social.Link
      href=""
      // target="_blank"
      rel="noreferrer"
      title="Coming soon!"
    >
      <Facebook />
    </Social.Link>
    <Social.Link
      href=""
      // target="_blank"
      rel="noreferrer"
      title="Coming soon!"
    >
      <LinkedIn />
    </Social.Link>
    <Social.Link
      href="https://www.instagram.com/valuatr.ca"
      target="_blank"
      rel="noreferrer"
      title="Instagram"
    >
      <Instagram />
    </Social.Link>
    <Social.Link
      href="https://twitter.com/ValuatrCanada"
      target="_blank"
      rel="noreferrer"
      title="Twitter"
    >
      <Twitter />
    </Social.Link>
  </Social>
);

export default Socials;
