import styled from 'styled-components';

const Modal = styled.div`
  font-family: 'Roboto',sans-serif;
  color: #4D4D4D;
  position: fixed;
  z-index: 100;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    font-size: 0.875em;
  }
`;

Modal.BackDrop = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 50%;
`;

Modal.Container = styled.div`
  border-radius: 11px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
  background-color: #FFF;
`;

Modal.ButtonClose = styled.button.attrs({
  title: 'Close',
  type: 'button'
})`
  position: absolute;
  top: 1%;
  right: 1%;
  font-size: 1.5em;
  color: #666666;
  min-width: 44px;
  min-height: 44px;
  display: block;
  border: none;
  background-color: transparent;
`;

export default Modal;
