import React, { useState } from 'react';
import { useNews } from '../../context';
import { InputSearch } from '../../components';
import { Text } from '../../components/elements';
import { SearchContainer, ArticleList } from './style';

const categories = [
    { label: 'News', value: 'News'},
    { label: 'Article', value: 'Article'},
    { label: 'Interviews', value: 'Interview'}
]

const NewsSearch = () => {
    const { setFilterTerm, setCategory, category } = useNews();
    const [ input, setInput ] = useState('');

    const handleChange = e => {
        setInput(e.target.value);
        setFilterTerm(e.target.value);
    }

    const handleSubmit = e => {
        e.preventDefault();
        setInput('');
    }

    const formReset = _ => {
        setCategory('')
        setFilterTerm('')
        setInput('')
    }

    return <>
       
        <form onSubmit={handleSubmit}>
            <Text font={1.5} color="#404040">
                Discover latest news, interviews and articles in the world of
                finance.
            </Text>
            <SearchContainer>
                <InputSearch onChange={handleChange} value={input} />
            </SearchContainer>
        </form>

        <ArticleList.Tabs>
            <ArticleList.Tab onClick={() => formReset()} active={!category}>
                All <span>&#8226;</span>
            </ArticleList.Tab>
            {categories.map( cat => (
                <ArticleList.Tab 
                    key={cat.value} 
                    onClick={() => setCategory(cat.value)}
                    active={category === cat.value}
                >
                    {cat.label} <span>&#8226;</span>
                </ArticleList.Tab>
            ))}
        </ArticleList.Tabs>
    </>
}

export default NewsSearch
