import React from 'react'
import { Button } from '../elements';
import Container from './style'

const Buttons = ({ handleBack, next, handleNext, disableNext }) => {
    const onNext = handleNext
        ? { onClick: handleNext }
        : { type: 'submit' }
        ;

    return (
        <Container>

            <Button onClick={handleBack} secondary>
                Back
            </Button>

            {next && !disableNext &&
                < Button {...onNext} type='submit'>
                    {next}
                </Button>
            }

        </Container >
    )
}

export default Buttons
