import styled from 'styled-components';

const colors = {
  News: '#1FC78A',
  Article: '#305FBB',
  Interview: '#02A9F7',
};

const Section = styled.div`
  background-color: ${props => props.dark && '#E5E5E5'};
  text-align: ${props => props.center && 'center'};
  padding-top: ${props => props.dark? '10%' : '4%'};
  @media only screen and (max-width: 600px) {
    padding-top: 10%;
  }
`;

const ArticleList = styled.section`
  display: grid;
  grid-gap: 2%;
  grid-template-columns: repeat(6, 1fr);
  @media only screen and (max-width: 600px) {
    display: block;
  }
  &.landing {
    @media only screen and (min-width: 601px) {
      article:first-of-type {
        p {
          max-height: 4.5em;
        } 
        a {
          border-radius: 8px;
        }
        h3 {
          font-size: 1.5em;
        }
      };
      article:first-of-type {
        grid-column: 1 /span 6;
      }
      article:nth-of-type(2n) {
        grid-column-start: 1;
        grid-column-end: 4;
      };
      article:nth-of-type(2n + 3) {
        grid-column-start: 4;
        grid-column-end: 7;
      };
    }
    @media only screen and (min-width: 800px) {
      article:first-of-type {
        grid-column: 1 /span 3;
      }
      article:nth-of-type(2),
      article:nth-of-type(3),
      article:nth-of-type(4),
      article:nth-of-type(5) {     
        padding: 0 5%;
        grid-column: 4 /span 3;
        grid-row-start: 1;
        overflow: hidden;
        height: 6em;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        > a {
          width: 25%;
          border-radius: 8px;
          padding-top: 0;
          margin-bottom: 0;
        }
        > div {
          width: 70%;
        }
        p, p + span {
          display: none;
        }
        h3 {
          font-size: 1em;
        }
      }
      article:nth-of-type(3) {
        margin-top: 7em;
      }
      article:nth-of-type(4) {
        margin-top: 14em;
      }
      article:nth-of-type(5) {
        margin-top: 21em;
      }
      article:nth-of-type(3n + 6) {
        grid-column: 1 /span 2;
      }
      article:nth-of-type(3n + 7) {
        grid-column: 3 /span 2;
      }
      article:nth-of-type(3n + 8) {
        grid-column: 5 /span 2;
      }
    }
  }
`;

const Article = styled.article`
@media only screen and (max-width: 600px) {
  margin-bottom: 3em;
}
@media only screen and (min-width: 601px) {
  &:nth-of-type(2n-1) {
    grid-column-start: 1;
    grid-column-end: 4;
  };
  &:nth-of-type(2n) {
    grid-column-start: 4;
    grid-column-end: 7;
  };
}
@media only screen and (min-width: 800px) {
    &:nth-of-type(3n+1) {
      grid-column-start: 1;
      grid-column-end: 3;
    };
    &:nth-of-type(3n+2) {
      grid-column-start: 3;
      grid-column-end: 5;
    };
    &:nth-of-type(3n+3) {
      grid-column-start: 5;
      grid-column-end: 7;
    };
  }
`;

const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 4% 0 8%;
`;

ArticleList.Tabs = styled.div`
  text-align: center;
  @media only screen and (max-width: 600px) {
    display: flex;
    justify-content: space-around;
  }
`;

ArticleList.Tab = styled.button`
  background-color: ${props => props.active? '#FFF' : 'transparent'};
  color: ${props => props.active? '#3F3F3F' : '#9B9B9B'};
  font-size: 1.25em;
  font-weight: 500;
  border: none;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0.2em 1.75em;
  text-align: center;
  cursor: pointer;
  span {
    color: #305FBB;
    line-height: 0.5;
    display: ${props => props.active? 'block' : 'none'}
  }
  @media only screen and (max-width: 600px) {
    font-size: 0.75em;
    padding: 0.2em 8px;
    width: 100%;
  }
`;

Article.Image = styled.a`
display: block;
  border-radius: 4px;
  padding-top: 45%;
  background-image: url('${props => props.src}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;  
  margin-bottom: 3%;
`;

Article.Title = styled.h3`
  margin-top: 0.2em;
  margin-bottom: 0.2em;
  font-size: 1.125em;
  line-height: 1.4;
  font-weight: 500;
  color: #152536;
  &:hover {
    text-decoration: underline;
  };
`;

Article.Text = styled.p`
overflow: hidden;
// max-height: 10vw;
max-width: 100%;
margin-bottom: 1em;
`;

Article.Category = styled.span`
    background-color: ${props => colors[props.cat]};
    font-size: 0.75em;
    color: #FFF;
    font-weight: bold;
    border-radius: 4px;
    padding: 0.2em 1em;
    display: inline-block;
`;

Article.Note = styled.span`
  font-size: 0.75em;
  color: #909090;
  margin-left: 0.5em;
`;

export { Section, SearchContainer, Article, ArticleList } ;
