import { noop } from '../../utils';
import SharePriceValuation1 from './SharePriceValuation1';
import SharePriceValuation2 from './SharePriceValuation2';
import SharePriceValuation3 from './SharePriceValuation3';

export const schema = {
  page1: {
    component: SharePriceValuation1,
    getPrevious: noop,
    getNext: () => 'page2',
    nextButton: 'Next',
  },
  page2: {
    component: SharePriceValuation2,
    getPrevious: () => 'page1',
    onNext: async (formData, setFormData, requestHandler, RequestMethods ) => {
      try {
        const getIntristicValue = requestHandler(
          RequestMethods.Post,
          `/${formData.model}`,
          formData
        );
        const getGraph = requestHandler(
          RequestMethods.Get,
          `companies/${formData.symbol}/price-eps-pe-yearly`
        )
        const [ intristicValue, graph] = 
          await Promise.allSettled([getIntristicValue, getGraph]);

        setFormData({ 
          ...formData, 
          ...intristicValue.value?.data, 
          graph: graph.value?.data 
        });
      } catch (error) {
        console.error(error);
      }
    },
    getNext: () => 'page3',
    nextButton: 'Generate Report',
  },
  page3: {
    component: SharePriceValuation3,
    getPrevious: () => 'page2',
    getNext: noop,
    nextButton: 'Done',
  },
  history: '/public-investments/share-price-valuation',
  hook: 'useSPV'
};