import React from 'react';
import { Input, InputTextarea } from '../../components';

const FormFields = ({ handleChange, formData }) => <>

  <Input 
    name="name" 
    required 
    type="text"
    label="Full Name"
    onChange={handleChange}
    value={formData.name}
    placeholder={null}
  />
  <Input 
    type="email" 
    name="email" 
    required 
    label="Email Address"
    onChange={handleChange}
    value={formData.email}
    placeholder={null}
    />
  <InputTextarea 
    name="message" 
    required 
    label="Message" 
    onChange={handleChange}
    value={formData.message}
    />

</>

export default FormFields;
