import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { Header, SimpleFormSchema } from '../../components';
import { schema } from './schema';
import { Main } from '../../components/containers';
import { H1, Text } from '../../components/elements';
import { signUpBg, signup } from '../../assets/images';
import { useAuth } from '../../context';
import { Card, Section } from '../login/style';

const SignUp = () => {
  const history = useHistory();
  const { currentUser } = useAuth();
  
  useEffect(() => {
    currentUser && history.push('/dashboard');
  // eslint-disable-next-line
  }, []);

  

  return  <>
    <Header loginPage={{ buttonText: 'Login', button: '/login', text:"Already have an account?"}} />
    <Main>
      <Section img={signUpBg}>
        <Main.Inner stretch>
          <Card>
            <Card.Content>
              <H1 large grey>
                Sign Up For Free
              </H1>
              <Text>Your step towards smarter investments.</Text>
              <SimpleFormSchema schema={schema} />
            </Card.Content>
            <Card.Image img={signup} />
          </Card>
        </Main.Inner>
      </Section>
    </Main>
  </>
}
export default SignUp;
