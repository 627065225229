import styled from 'styled-components';

const StyledLink = styled.span`
    color: #366EDA;
    font-weight: bold;
    &:hover {
        text-decoration: underline;
    }
    * {
        vertical-align: sub;
    }
`;

export default StyledLink;