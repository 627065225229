import React from 'react';
import { PrivateCompanyContextProvider } from '../../context';
import { TabsPublicInv, FormSchema, Breadcrumbs } from '../../components';
import { schema } from './schema';
import { DashboardMain as Main } from '../../components/containers';

const PrivateCompany = () => (

  <Main>
    <Main.Inner>
      <Main.Title>Business Valuation</Main.Title>
      <Breadcrumbs path='Business Valuation' url='/dashboard/business-valuation' />
      <TabsPublicInv option="business-valuation" />
      <PrivateCompanyContextProvider>   
        <FormSchema schema={schema} />
      </PrivateCompanyContextProvider>
    </Main.Inner>
  </Main>

);

export default PrivateCompany;
