import React from 'react';
import { Required } from '../elements';
import Input from './style';

const InpuTextarea = ({ name, label, ...rest }) => {
  const id2 = name || label?.replaceAll(' ', '-').toLowerCase();
  
  return (
    <Input.Container>
      <Input.Label htmlFor={id2}>
        {label}
        {rest.required && <Required />}
        </Input.Label>
      <Input id={id2} name={id2} {...rest} />
    </Input.Container>
  );
};

export default InpuTextarea;
