import React from 'react';
import { createDatalist } from '../../utils';
import { Input, EditTitle } from '../../components';
import { FieldsContainer } from '../../components/containers';
import { info } from '../definitions';

const PrivateCompany2 = ({ data, handleChange }) => <>

  <FieldsContainer>
    <EditTitle
      value={data.companyName}
      onChange={handleChange}
    />
  </FieldsContainer>

  <FieldsContainer>
    
    <Input
      {...info.portfolioRisk}
      value={data.portfolioRisk}
      required
      min={1}
      max={10}
      step={0.01}
      onChange={handleChange}
      list='portfolioRiskOptions'
    />
    {createDatalist(4,7,1,'portfolioRiskOptions')}
    <Input
      {...info.industryRisk}
      value={data.industryRisk}
      onChange={handleChange}
      min={1}
      max={10}
      step={0.01}
      required
      list='industryRiskOptions'
    />
    {createDatalist(1,5,1,'industryRiskOptions')}
    <Input
      {...info.companyRisk}
      value={data.companyRisk}
      onChange={handleChange}
      min={1}
      max={10}
      step={0.01}
      required
      list='companyRiskOptions'
    />
    {createDatalist(1,5,1,'companyRiskOptions')}
    <Input
      {...info.sizeRisk}
      value={data.sizeRisk}
      onChange={handleChange}
      min={1}
      max={10}
      step={0.01}
      required
      list='sizeRiskOptions'
    />
    {createDatalist(1,5,1,'sizeRiskOptions')}
    <Input
      {...info.riskFreeRate}
      value={data.riskFreeRate}
      onChange={handleChange}
      min={1}
      max={10}
      step={0.01}
      required
      list='riskFreeRateOptions'
    />
    {createDatalist(1,2,1,'riskFreeRateOptions')}
    <Input
    {...info.taxRate}
      value={data.taxRate}
      onChange={handleChange}
      min={1}
      max={50}
      step={0.01}
      required
    />
    <Input
    {...info.costOfDebt}
      value={data.costOfDebt}
      onChange={handleChange}
      required
    />
    <Input
      {...info.debtToEquity}
      value={data.debtToEquity}
      onChange={handleChange}
      required
    />
  </FieldsContainer>

</>

export default PrivateCompany2;
