import React, { useState } from 'react';
import { InputSelect } from '../../components';
import { Container } from '../../components/containers'
import { GraphContainer } from './style';
import { tenYoptions, datasetsDefault, datasetsPredictedDefault } from '../../utils/graphs';
import { Line } from 'react-chartjs-2';
import { Text } from '../../components/elements';

const selectOptions = [
  'Price per Share over Time',
  'Price to Earnings over Time',
  'Earnings per Share over Time'
];

const Graph = ({ data }) => {
  const [activeGraph, setActiveGraph] = useState('price-per-share-over-time');

  const schema = {
    // eslint-disable-next-line
    ['price-per-share-over-time']: data.priceLabels && data.prices ? {
      labels: data.priceLabels,
      datasets: [
        {
          ...datasetsDefault,
          label: 'Price per Share',
          data: data.prices
        },
        {
          ...datasetsPredictedDefault,
          data: data.pricesPredicted
        }
      ]
    } : null,
    // eslint-disable-next-line
    ['earnings-per-share-over-time']: data.labelsEPS && data.eps ? {
      labels: data.labelsEPS,
      datasets: [
        {
          ...datasetsDefault,
          label: 'Earnings per Share',
          data: data.eps
        },
        {
          ...datasetsPredictedDefault,
          data: data.epsPredicted
        }
      ]
    } : null,
    // eslint-disable-next-line
    ['price-to-earnings-over-time']: data.labelsPE && data.pe ? {
      labels: data.labelsPE,
      datasets: [
        {
          ...datasetsDefault,
          label: 'Price to Earnings',
          data: data.pe
        },
        {
          ...datasetsPredictedDefault,
          data: data.pePredicted
        }
      ]
    } : null
  }

  return (
    <GraphContainer>
      <InputSelect
        defaultValue={true}
        label="Select type of graph"
        id="type"
        options={selectOptions}
        onChange={e => setActiveGraph(e.target.value)}
      />

      {schema[activeGraph] ?
        <>
          <Container top='1.5rem' key={activeGraph}>
            <Line key={activeGraph} id={`graph-${activeGraph}`} data={schema[activeGraph]} options={tenYoptions} />
          </Container>
          {schema[activeGraph].datasets[1].data &&
            <GraphContainer.Note>
            Projections in green are based on the average growth rate of the past 10 years.
          </GraphContainer.Note>
          }
        </> :
        <Container top='1.5rem'>
          <Text>Graph unavailable.</Text>
        </Container>
      }


    </GraphContainer>
  )
}

export default Graph