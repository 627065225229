import React from 'react';
import { useFinancials } from '../../../context/Financials';
import { Table } from './style'

const Row = ({ item, bold, statement }) => {
    const { reports } = useFinancials();
    const [year1, year2, year3] = reports[statement];
  
    return (
      <Table.Row key={item.title}>
        <Table.Heading scope="row" bold={bold}>
          {item.title}
        </Table.Heading>
        <Table.Cell>
          {year1[item.api]}
        </Table.Cell>
        <Table.Cell>
          {year2[item.api]}
        </Table.Cell>
        <Table.Cell>
          {year3[item.api]}
        </Table.Cell>
      </Table.Row>
    )
  };

  export default Row;