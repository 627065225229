import React, { useState } from 'react';
import { Hidden } from '../elements';
import { Info as InfoIcon } from '../../assets/svgComponents';
import Info from './style';

const InfoPopup = ({ info }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Info className="info" onClick={() => setOpen(!open)}>
        <Info.Button onClick={() => setOpen(!open)}>
          <InfoIcon />
          <Hidden.Span>Info</Hidden.Span>
        </Info.Button>
        {open && (
          <Info.Popup onMouseLeave={() => setOpen(false)}>
            <Info.Tip />
            <p>{info}</p>
          </Info.Popup>
        )}
      </Info>
    </>
  );
};

export default InfoPopup;
