import React from 'react';
import { Input } from '../../components';
import { FieldsContainer } from '../../components/containers';
import { info } from '../definitions';
import Graph from './Graph';
import { Container } from './style';

const StockOptionValuation3 = ({ data }) => {

  return (
    <Container>
      <FieldsContainer twoColumns>
        <Input
          {...info.intristicValue}
          readOnly
          value={'$ ' + data.intristicValue}
          type='text'
        />
        <Input
          {...info.currentStockPrice}
          value={'$ ' + data.currentStockPrice}
          readOnly
          type='text'
        />
        <Input
          {...info.exercisePrice}
          value={'$ ' + data.exercisePrice}
          readOnly
          type='text'
        />
        <Input
          {...info.breakEvenPrice}
          readOnly
          value={'$ ' + data.breakEvenPrice}
          type='text'
        />
      </FieldsContainer>
      <Graph data={data} />
    </Container>
  );
};

export default StockOptionValuation3;
