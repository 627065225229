import React from 'react';
import { Image } from '../../components/elements';
import { Main } from '../../components/containers';
import { 
  smarterInvestments,
  accurateBussVal,
  valueOfProject
} from '../../assets/images';
import Home from './style';

const Section3 = () => {
  return (
    <Home.Section3>
      <div>
        <Main.InnerColumns reverse>
          <Main.Column>
            <Home.Title>Smarter Investments</Home.Title>
            <Home.Subtitle dark bold>
              Make better investment decisions by finding what matters.
            </Home.Subtitle>
            <Home.List>
              <li>Generate intrinsic value of shares.</li>
              <li>Calculate intrinsic value of stock options.</li>
              <li>
                Determine a companies financial strength with ratio analysis.
              </li>
            </Home.List>
          </Main.Column>
          <Main.Column>
            <Image src={smarterInvestments} alt="Preview of a Graph" />
          </Main.Column>
        </Main.InnerColumns>
      </div>
      <Home.Row blue>
        <Main.InnerColumns topPadding={2}>
          <Main.Column>
            <Home.Title>Accurate Business Valuations</Home.Title>
            <Home.Subtitle dark bold>
              Conduct accurate business valuations on public and private
              companies.
            </Home.Subtitle>
            <Home.List>
              <li>
                Search for a publicly traded company and find it's enterprise
                value based on its financials.
              </li>
              <li>
                Easily find enterprise value of private companies using
                financials.
              </li>
              <li>Choose between three different business valuation models.</li>
            </Home.List>
          </Main.Column>
          <Main.Column>
            <Image
              src={accurateBussVal}
              alt="Preview of Business Valuation Form"
            />
          </Main.Column>
        </Main.InnerColumns>
        </Home.Row>
        <Main.InnerColumns reverse bottomPadding={3}>
          <Main.Column>
            <Home.Title>Value of Projects</Home.Title>
            <Home.Subtitle dark bold>
              Determine the value of a project that you or your small business
              may undertake.
            </Home.Subtitle>
            <Home.List>
              <li>Determine if the project is providing value and how much.</li>
              <li>
                Calculates when you will be paid back by the project itself.
              </li>
              <li>Find out how efficient the project's cashflows are.</li>
            </Home.List>
          </Main.Column>
          <Main.Column>
            <Image
              src={valueOfProject}
              alt="Preview of Project Investment Efficiency Indicators"
            />
          </Main.Column>
        </Main.InnerColumns>
    </Home.Section3>
  );
};

export default Section3;
