import React from 'react';
import TableFrame from '../tableFrame/TableFrame';
import Row from '../tableFrame/Row';
import Heading from '../tableFrame/Heading';
import * as c from './schema';
import DownloadPDF from '../pdf/DownloadPDF';
import { default as PDF } from './BalanceSheetPDF';

const BalanceSheet = () => <>

  <TableFrame caption="Balance Sheet for last three years">

    <Heading>Assets</Heading>

    <Heading>Current Assets</Heading>
    {c.currentAssets.map(item => (
      <Row key={item.api} item={item} statement='balanceSheet' />
    ))}
    
    <Heading>Non Current Assets</Heading>
    {c.nonCurrentAssets.map(item => (
      <Row key={item.api} item={item} statement='balanceSheet' />
    ))}
    <Row item={c.totalAssets} statement='balanceSheet' />
    
    <Heading>Liabilities and Shareholder Equity</Heading>

    <Heading>Current Liabilities</Heading>
    {c.currentLiability.map(item => (
      <Row key={item.api} item={item} statement='balanceSheet' />
    ))}

    <Heading>Non Current Liabilities</Heading>
    {c.nonCurrentLiabilities.map(item => (
      <Row key={item.api} item={item} statement='balanceSheet' />
    ))}
    <Row item={c.totalLiabilities} statement='balanceSheet' />

    <Heading>Shareholder Equity</Heading>
    {c.shareholderEquity.map(item => (
      <Row key={item.api} item={item} statement='balanceSheet' />
    ))}
    <Row item={c.totalShareholderEquity} statement='balanceSheet' />
    <Row item={c.totalLiabilitiesAndShareholderEquity} statement='balanceSheet' />
    
  </TableFrame>

  <DownloadPDF pdf={PDF} title={'Balance Sheet'} statement='balanceSheet' />

</>

export default BalanceSheet;
