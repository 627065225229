import styled from 'styled-components';

const Main = styled.main`
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  padding-bottom: 12rem;
  padding-top: 6rem;
  color: #68717a;
  width: 85vw;
  margin-left: 15vw;
  background-color: ${props => (props.grey && '#F1F2F2') || '#FFF'};
  li::marker {
    color: transparent;
  };
  button {
    cursor: pointer;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin: auto;
    padding-top: 4rem;
    font-size: 0.875em;
  }
  @media only screen and (max-width: 800px) {
    background-color: #fff;
  }
`;

Main.Section = styled.div`
  background-color: ${props =>
    (props.dark && '#E5E5E5') || (props.white && '#FFF')};
`;

Main.SectionHalfWhite = styled.div`
  background-image: linear-gradient(
    180deg
    , #FFF 52%, #F1F2F2 52%
  );
  @media only screen and (max-width: 1024px) {
    background-image: linear-gradient(
      180deg
      , #FFF 62%, #F1F2F2 62%
    );
  }
  @media only screen and (max-width: 800px) {
    background-color: #fff;
    background-image: none;
  }
`;

Main.Inner = styled.div`
  padding-left: 5vw;
  padding-right: 5vw;
  width: 100%;
  max-width: ${props => props.narrow && '940px'};
`;

Main.Title = styled.h1`
font-family: 'Roboto', sans-sarif;
font-size: 1.75rem;
padding: 1em 0;
color: #152536;
font-weight: 500;
  @media only screen and (min-width: 1025px) {
    display: none;
  }
`;

export default Main;
