import React, { useState, useEffect } from 'react';
import { Hidden } from '../elements';
import MenuBars from './MenuBars';
import { Modal } from './style';

const HeaderModal = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    const buttons = document.getElementsByClassName('closeHeader');

    if (buttons.length === 2) {
      buttons[0].addEventListener('click', () => setIsOpen(false));
      buttons[1].addEventListener('click', () => setIsOpen(false));
    }

    const listener = e => {
        if (e.key === 'Escape') setIsOpen(false);
    };

    window.addEventListener('keydown', listener);
    return () => window.removeEventListener('keydown', listener);
  }, []);

  return (

        <>    
          <MenuBars onClick={() => setIsOpen(true)} />

          <Modal isOpen={isOpen}>
            <Modal.BackDrop onClick={() => setIsOpen(false)} />
            <Modal.ButtonClose onClick={() => setIsOpen(false)}>
                <Hidden.Span>Close</Hidden.Span>
                <span>&times;</span>
            </Modal.ButtonClose>
            <Modal.Container isOpen={isOpen}>
              {children}
            </Modal.Container>
          </Modal>
      </>
  );
}

export default HeaderModal;
