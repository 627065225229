import React from 'react';
import Explanation from './Explanation'
import { Container } from './style';

const ContingentResult = props => {

  const {
    info,
    label,
    value,
    grade,
    explanation,
    color
  } = props;

  return (
    <Container>
      <Container.Input label={label} value={value} info={info} readOnly />
      <Container.Grade.Container>
          {grade && 
            <Container.Grade color={color}>
              {grade}
            </Container.Grade>
          }
          {explanation && 
            <Explanation text={explanation} />
          }     
      </Container.Grade.Container>
    </Container>
  );
};

export default ContingentResult;
