import * as G from './';

const schema = {
    tabPanels: {
      '1 Day': G.Graph1day,
      '5 Days': G.Graph5days,
      '1 Month': G.Graph1month,
      '6 Months': G.Graph6months,
      '1 Year': G.Graph1year,
      '5 Years': G.Graph5years
    }
};

export { schema }