import React from 'react';
import { Input } from '../../components';

const FormFields = ({ handleChange, formData }) => <>

  <Input
    type="email"
    name="email"
    required
    label="Email Address"
    placeholder={null}
    onChange={handleChange}
    value={formData.email}
  />
  <Input
    type="password"
    name="password"
    required
    label="Password"
    placeholder={null}
    onChange={handleChange}
    value={formData.password}
  />
  {/* <InputCheckbox
    name="rememberMe"
    label="Remember me"
    onChange={handleChange}
    value={formData.rememberMe}
  /> */}

</>


export default FormFields;
