import React from 'react';
import { User } from '../../assets/svgComponents';

const ModalButton = ({ onClick }) => (
        
    <button 
        onClick={onClick}
        className='account'
        title='Account'
    >
        <User />
        <span>Account</span>
    </button>
)


export default ModalButton
