import React from 'react';
import Search from './style';

const InputSearch = ({ children, label, ...rest }) => (
  <>
    <Search.Label htmlFor="search">{label}</Search.Label>
    <Search.Wrapper>
      <Search.Icon />
      <Search.Input id="search" placeholder="Search" {...rest} />
      {children}
      <Search.Button type="submit">Search</Search.Button>
    </Search.Wrapper>
  </>
);

export default InputSearch;
