import React, { useState } from 'react';

const Tabs = ({ schema, ...rest }) => {
  const tabPanels = schema.tabPanels;
  const tabs = Object.keys(tabPanels);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const ActiveTabPanel = tabPanels[activeTab];

  const Styled = schema.style;

  return <>
    <Styled role="tablist">
      {tabs.map(tab => (
        <Styled.Tab
          role="tab"
          key={tab}
          active={activeTab === tab}
          onClick={() => setActiveTab(tab)}
        >
          {tab}
        </Styled.Tab>
      ))}
    </Styled>
    <div role="tabpanel">
      <ActiveTabPanel {...rest} />
    </div>
  </>
};

export default Tabs;
