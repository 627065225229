import styled from 'styled-components';
import { Input as DefaultInput } from '../';

const colors = {
    green: '#3F8F51',
    yellow: '#8F6F3F',
    red: '#AB1717'
}

const backgroundColors = {
    green: '#ABF2D0',
    yellow: '#FBF7A5',
    red: '#F2B7AB'
}

const Container = styled.div`
    margin-bottom: 1.5em;
    > div:first-of-type {
        margin-bottom: 0.5em;
    }
`;

Container.Input = styled(DefaultInput)`
    max-width: 200px!important;
    & + div {
        top: -0.1em;
        padding-top: 0;
    }
`;

Container.Grade = styled.span`
    color: ${props => colors[props.color]};
    background-color: ${props => backgroundColors[props.color]};
    font-size: 0.75em;
    padding: 0.2em 0.9em;
    border-radius: 7px;
    display: inline-block;
`;

Container.Grade.Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 200px;
`;

const Explain = styled.div`
    position: relative;
    background-color: #3D7BF2;
    border-radius: 7px;
    color: #fff;
`;

Explain.Button = styled.button`
    border: none;
    font-size: 0.75em;
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0.2em 0.9em 0.2em 0.5em;
`;

Explain.Text = styled.span`
    margin-left: 0.5em;
`;

Explain.Popup = styled.div`
    position: absolute;
    height: ${props => props.open? 'auto' : '1px'};
    width: ${props => props.open? '200px' : '1px'};
    background: #3d7bf2;
    font-size: 0.75em;
    z-index: 70;
    padding: ${props => props.open? '0.75em 1em' : '0'};
    overflow: hidden;
    border-radius: 4px;
    color: #fff;
`;

export { Explain, Container };