import React, { createContext, useContext, useState, useEffect } from 'react';
import { RequestMethods, useRequest } from '../utils/requests';

const NewsContext = createContext();

const useNews = _ => useContext(NewsContext);

const NewsContextProvider = ({ children }) => {
  const requestHandler = useRequest();
  const [news, setNews] = useState([]);
  const [ filterTerm, setFilterTerm ] = useState('');
  const [ category, setCategory ] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      const getNews = async _ => {
        const response = await requestHandler(RequestMethods.Get, '/news');
        setNews(response?.data || []);
      }
      getNews();
    } catch (err) {
      console.error(err);
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  // eslint-disable-next-line
  }, []);

  const filteredNews = news.filter( news => (
      ((news.title?.toLowerCase().includes(filterTerm.toLowerCase()) )
      || (news.text?.toLowerCase().includes(filterTerm.toLowerCase()) ))
      && news.category?.includes(category)
  ))

  return (
    <NewsContext.Provider value={{ 
        filteredNews, setFilterTerm, setCategory, category, filterTerm, loading
    }}>
      {children}
    </NewsContext.Provider>
  );
};

export { useNews, NewsContextProvider };
