import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { useCompanies, PublicCompanyContextProvider } from '../../context';
import PublicCompany from '../publicCompany/PublicCompany';
import { SearchCompanies, TabsPublicInv, Breadcrumbs } from '../../components';
import { DashboardMain as Main } from '../../components/containers';
import { ErrorPage } from '..';

const PublicCompanies = () => {
  const { companies } = useCompanies();
  let { path } = useRouteMatch();

  return (

    <Main>
      <Main.Inner>
        <Main.Title>Business Valuation</Main.Title>
        <Breadcrumbs path='Business Valuation' url='/dashboard/business-valuation' />

        <TabsPublicInv option="business-valuation" />


        <Switch>
          <Route exact path={path}>
            <SearchCompanies data={companies} back="business-valuation" />
          </Route>
          <Route exact path={`${path}/:symbol`}>
            <PublicCompanyContextProvider>
              <PublicCompany />
            </PublicCompanyContextProvider>
          </Route>
          <Route component={ErrorPage} />
        </Switch>

      </Main.Inner>
    </Main>
  );
};

export default PublicCompanies;
