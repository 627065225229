import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CompanyName, FormSchema, ReportNotFound } from '../../components';
import { Label } from '../../components/elements';
import { Container } from '../../components/containers';
import { schema } from './schema';
import { useCompanies, useSPV, useAuth } from '../../context';
import { RequestMethods, useRequest } from '../../utils/requests';
import Loading from '../../hoc/loading/Loading';

const SharePriceValuationCompany = ({ setLoading }) => {
  const requestHandler = useRequest();
  const { addToHistory } = useAuth();
  const { symbol } = useParams();
  const { data, setData } = useSPV();
  const { companies } = useCompanies();

  useEffect(() => {

    const getRequest = async _ => {  
      setLoading(true);
      try {
        const response = await requestHandler(
          RequestMethods.Get,
          `/share-price-valuation/${symbol}`
        );

        if (!response?.data?.name) {
          const company = companies.find(
            company => company.symbol === symbol
          );

          setData({ 
            name: company.name, 
            exchange: company.exchangeShortName,
            symbol,
            ...response?.data
          })
        } else {
          setData({ symbol, ...response?.data});

        }

        addToHistory({
          symbol,
          name: response?.data?.name,
          exchange: response?.data?.exchange,
          model: 'Share Price Valuation'
        });

      } catch (err) {
        setData(null);
        console.error(err);
      }

      setLoading(false);
    };
    
    getRequest();
  // eslint-disable-next-line
  }, [symbol, setData]);

  if (!data) return <ReportNotFound />;

  return <>
    
    <Container top='2rem' bottom='3rem'>
      <Label>Company Name</Label>
      <h3><CompanyName name={data.name} symbol={data.symbol} /></h3>
      <Label gray><em>Showing reports for year {data.date} </em></Label>
    </Container>
    <FormSchema schema={schema} />
   
</>

};

export default  Loading(SharePriceValuationCompany, `Loading company's reports...`);
