import styled from 'styled-components';

const Main = styled.main`
  font-family: 'Roboto', sans-serif;
  min-height: 100vh;
  line-height: 1.4;
  > section:first-child {
    padding-top: 9rem;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 0.875em;
  }
`;

Main.Section = styled.section`
  background-color: ${props => props.gray && '#FAFAFA'};
  padding-bottom: ${props => props.bottomPadding && '5%'};
`;

Main.SectionBg = styled.section`
  background-image: url(${props => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: ${props => props.center && 'center'};
`;
Main.Inner = styled.div`
  width: 85%;
  max-width: 1020px;
  margin: auto;
`;

Main.InnerColumns = styled(Main.Inner)`
align-items: ${props => props.stretch && 'stretch'};
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${props => props.reverse && 'row-reverse'};
  padding-top: ${props => props.topPadding && (props.topPadding + '%')};
  padding-bottom: ${props => props.bottomPadding && (props.bottomPadding + '%')};
  @media only screen and (max-width: 800px) {
    padding: 10% 0;
    img {
      max-width: 300px;
      width: 80%;
    }
  }
`;

Main.Column = styled.div`
  text-align: ${props => props.right && 'right'};
  width: 45%;
  padding: 3% 0;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

Main.UList = styled.ul`
  margin-bottom: 1em;
`;

Main.OList = styled.ol`
  margin-bottom: 1em;
`;

Main.ListItem = styled.li`
  font-size: 1em;
  line-height: 1.5;
  color: #4D4D4D;
  margin-left: 1.5em;
  margin-bottom: 0.25em;
}
`;

export default Main;
