import React from 'react'
import Modal from '../modal/Modal';
import ModalButton from './ModalButton';
import ModalContent from './ModalContent';

const ModalInviteFriend = () => (

    <Modal 
        content={ModalContent}
        button={ModalButton}
    />
)

export default ModalInviteFriend
