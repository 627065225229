export const notesForSPV = {
  negativeCashflow: 'This company has a negative intrinsic value and could be caused by a variety of reasons. These reasons include negative or little earnings, high capital expenditure relative to profit, low cost of equity, or a large change in working capital.',
  externalFactors: 'There are many factors that could be causing the intrinsic value to be so far off the market price. This company may not have certain metrics available to fetch. Try using a different Terminal Growth Rate or different valuation method.'
}

export const notesForPublicCompany = {
  negativeCashflow: 'This company is experiencing negative earnings which is leading to a skewed valuation. The intrinsic value may not be accurate because it had negative earnings.',
  externalFactors: 'There are many factors that could be causing the intrinsic value to be so far off the market price. This company may not have certain metrics available to fetch. Try using a different Terminal Growth Rate.'
}

export const info = {
  betaRisk: {
    label: 'Beta risk',
    name: 'betaRisk',
    info: 'Rate on an investment that bodes zero risk.',
  },
  WACC: {
    label: 'WACC %',
    name: 'wacc',
    info: 'Weighted Average Cost of Capital',
    percent: true
  },
  intristicValue: {
    label: 'Intristic value',
    name: 'intristicValue'
  },
  currentMarketPrice: {
    label: 'Current market price',
    name: 'currentMarketPrice'
  },
  priceToEarningsRatio: {
    label: 'Price to earning',
    name: 'priceToEarningsRatio',
    info: `Typically seen as the “(P/E) ratio”, this value gives the investor an idea of
        how much they are willing to paying for a company’s earnings. Helps
        determine whether the company is overvalued or undervalued.`,
  },
  returnOnEquity: {
    label: 'Return on equity %',
    name: 'returnOnEquity',
    info: `Tells the investor how effectively their money is being put to use. It 
        measures the profitability of a corporation in relation to stockholder’s equity.`,
  },
  currentRatio: {
    label: 'Current ratio',
    name: 'currentRatio',
    info: `A liquidity ratio that measures a company’s ability to pay short-term
        obligations or those due within one year. Tells investors how a company can
        maximize the current assets on its balance sheet to satisfy its current debt.
        (2:1 ratio is generally the accepted standard)- contingency results`,
  },
  quickRatio: {
    label: 'Quick ratio',
    name: 'quickRatio',
    info: `Indicates a company’s capacity to pay its current liabilities without needing to sell its inventory or get additional financing. (higher the ratio, the better a company’s liquidity and financial health) (lower the ratio, more likely the company will struggle to pay its debts)`,
  },
  grossProfitMargin: {
    label: 'Gross profit margin %',
    name: 'grossProfitMargin',
    info: `Profitability ratio that shows the company’s rate of profit after allowing for cost of sales. Higher the gross margin, the more capital a company retains on each dollar of sales. (used to pay off costs or satisfy debts)`,
    percent: true
  },
  netProfitMargin: {
    label: 'Net margin %',
    name: 'netProfitMargin',
    info: `It is The percentage of revenue left after all expenses have been deducted from sales. Reveals the amount of profit that a business can extract from its total sales.`,
    percent: true
  },
  returnOnAssets: {
    label: 'Return on assets %',
    percent: true,
    name: 'returnOnAssets',
    info: `This indicates how profitable a company is relative to its total assets. Gives investors an idea as to how efficient a company’s management is at using its assets to generate earnings.`,
  },
  earningsPerShare: {
    label: 'Earnings per Share',
    name: 'earningsPerShare',
    info: 'Typically seen as the “(P/E) ratio”, this value gives the investor an idea of how much they are willing to paying for a company’s earnings. Helps determine whether the company is overvalued or undervalued.',
  },
  debtEquityRatio: {
    label: 'Debt to Equity',
    name: 'debtEquityRatio',
    info:
      'This ratio shows if a company is in too much debt. As this increases, the chance of bankruptcy and financial risk also increases.',
  },
  solvencyRatio: {
    label: 'Solvency ratio %',
    name: 'solvencyRatio',
    info: 'This ratio measures the extent to which assets cover long term financial obligations.'
  },
  EBITDA: {
    label: 'EBITDA',
    name: 'EBITDA',
    info: 'Earnings before interest, taxes, depreciation and amortization.'
  },
  debtToEBITDA: {
    label: 'Debt to EBITDA',
    name: 'debtToEBITDA',
    info: 'Ratio measuring the amount of income generated and available to pay down debt before covering interest, taxes, depreciation, and amortization expenses.',
  },
  enterpriseValue: {
    label: 'Enterprise Value',
    name: 'enterpriseValue',
    info: 'A fair market value of what the business is worth, based on future cash flows, future dividends, and/or assets of the company.',
  },
  UCCTaxShield: {
    label: 'UCC tax shield',
    name: 'UCCTaxShield'
  },
  redundantAssets: {
    label: 'Redundant Assets',
    name: 'redundantAssets'
  },
  amortization: {
    label: 'Amortization',
    name: 'amortization'
  },
  totalDebt: {
    label: 'Debt',
    name: 'totalDebt'
  },
  currentMarketCap: {
    label: 'Current Market Cap',
    name: 'currentMarketCap',
    info: 'A measure of what the company is worth based on current share price multiplied by total shares that the company has issued.',
  },
  price: {
    label: 'Price',
    name: 'price'
  },
  exercisePrice: {
    label: 'Exercise Price',
    name: 'exercisePrice',
  },
  breakEvenPrice: {
    label: 'Break Even Price',
    name: 'breakEvenPrice'
  },
  currentStockPrice: {
    label: 'Current Stock Price',
    name: 'currentStockPrice'
  },
  netPresentValue: {
    label: 'Net Present Value',
    name: 'netPresentValue',
    info: 'NPV is the sum of all future cash flows (positive or negative) over the entire life of an investment discounted to the present. Used to help determine how much an investment, project, or any series of cash flows is worth.'
  },
  internalRateOfReturn: {
    label: 'Internal Rate of Return %',
    name: 'internalRateOfReturn',
    info: 'A metric used to estimate the profitability of potential investments. It is the annual rate of growth an investment is expected to generate.'
  },
  payBackPeriod: {
    label: 'Payback Period',
    info: 'Refers to the amount of time it takes to recover the cost of an investment. The length of time an investment reaches a break-even point. Shorter paybacks mean more attractive investments.',
    name: 'payBackPeriod'
  },
  returnOnInvestment: {
    label: 'Return on Investment %',
    info: 'Measures the gain or loss generated by an investment in relation to its initial cost. Allows the investor to gauge the efficiency and profitability of an investment.',
    name: 'returnOnInvestment'
  },
  profitabilityIndex: {
    label: 'Profitability Index',
    info: 'Indicates whether an investment should create or destroy company value. It is useful for ranking and choosing between projects when capital is rationed. ( a PI > 1 means the project generates value, PI < 1 destroys value, and PI = 1 breaks even).',
    name: 'profitabilityIndex'
  },
  holdingPeriodReturn: {
    label: 'Holding Period Return',
    info: 'The total return received from holding an asset or portfolio assets over a period of time. Useful for comparing returns between investments held for different periods of time.',
    name: 'holdingPeriodReturn'
  },
  dividendPerShare: {
    label: 'Dividend per share',
    name: 'dividendPerShare',
    currency: true
  },
  growthRate: {
    label: 'Growth rate %',
    name: 'growthRate',
    percent: true
  },
  terminalGrowthRate: {
    label: 'Terminal growth rate %',
    name: 'terminalGrowthRate',
    info: `This is the average rate the company is expected to grow every year until forever. 2%  for stable companies, 5% for high growth companies.`,
    percent: true
  },
  revenue: {
    label: 'Revenue',
    name: 'revenue',
    currency: true
  },
  netIncome: {
    label: 'Net Income',
    name: 'netIncome'
  },
  costOfGoodsSold: {
    label: 'Cost of goods sold',
    name: 'costOfGoodsSold',
    currency: true
  },
  CCARate: {
    label: 'CCA Rate',
    name: 'CCARate',
    percent: true
  },
  operatingExpenses: {
    label: 'Operating expenses',
    name: 'operatingExpenses',
    currency: true
  },
  interestExpense: {
    label: 'Interest expenses',
    name: 'interestExpense',
    currency: true
  },
  otherIncome: {
    label: 'Other Income',
    name: 'otherIncome',
    currency: true
  },
  otherExpenses: {
    label: 'Other expenses',
    name: 'otherExpenses',
    currency: true
  },
  taxRate: {
    label: 'Tax rate %',
    name: 'taxRate',
    info: 'Corporate tax rate is used. If the company is a sole proprietorship or partner then use individual tax rate.',
    percent: true
  },
  capitalExpenditure: {
    label: 'Capital expenditure',
    name: 'capitalExpenditure'
  },
  outstandingShares: {
    label: 'Outstanding shares',
    name: 'outstandingShares'
  },
  workingCapital: {
    label: 'Working capital',
    name: 'workingCapital'
  },
  riskFreeRate: {
    label: "Risk free rate %",
    name: 'riskFreeRate',
    info: 'Rate of a riskless investment.',
    percent: true
  },
  marketReturn: {
    label: "Market return %",
    name: 'marketReturn',
    percent: true
  },
  weightOfEquity: {
    label: "Weight of equity",
    name: 'weightOfEquity',
    info: 'Weight of equity is the full amount of equity divided by (equity plus debt)'
  },
  weightOfDebt: {
    label: "Weight of debt",
    name: 'weightOfDebt',
    info: 'Weight of debt is the weighting that debt has in the debt/equity ratio. This is not all liabilities but the long term bank debt and short term bank debt the company holds.'
  },
  costOfEquity: {
    label: "Cost of equity %",
    name: 'costOfEquity'
  },
  costOfDebt: {
    label: "Cost of debt %",
    name: 'costOfDebt',
    info: 'Cost of debt is the interest rate the company can receive from a loan or the dividend rate they pay on preferred shares.'
  },
  debtToEquity: {
    label: 'Debt to equity',
    name: 'debtToEquity'
  },
  currentLiabilities: {
    label: 'Current Liabilities',
    name: 'currentLiabilities'
  },
  nonCurrentLiabilities: {
    label: 'Non Current Liabilities',
    name: 'nonCurrentLiabilities'
  },
  earningsBeforeTax: {
    label: 'Earnings before Tax',
    name: 'earningsBeforeTax'
  },
  earningsBeforeTaxYear1: {
    label: 'Earnings before Tax year 1',
    name: 'earningsBeforeTaxYear1'
  },
  earningsBeforeTaxYear2: {
    label: 'Earnings before tax year 2',
    name: 'earningsBeforeTaxYear2'
  },
  earningsBeforeTaxYear3: {
    label: 'Earnings before tax year 3',
    name: 'earningsBeforeTaxYear3'
  },
  interestExpenseYear1: {
    label: "Interest expense year 1",
    name: "interestExpenseYear1"
  },
  interestExpenseYear2: {
    label: "Interest expense year 2",
    name: "interestExpenseYear2"
  },
  interestExpenseYear3: {
    label: "Interest expense year 3",
    name: "interestExpenseYear3"
  },
  amortizationYear1: {
    label: "Amortization year 1",
    name: "amortizationYear1"
  },
  amortizationYear2: {
    label: "Amortization year 2",
    name: "amortizationYear2"
  },
  amortizationYear3: {
    label: "Amortization year 3",
    name: "amortizationYear3"
  },
  nonCurrentAssets: {
    label: 'Non Current Assets',
    name: 'nonCurrentAssets'
  },
  currentAssets: {
    label: 'Current Assets',
    name: 'currentAssets'
  },
  open: {
    label: 'Open',
    name: 'open'
  },
  previousClose: {
    label: 'Previous close',
    name: 'previousClose'
  },
  dayHigh: {
    label: 'High',
    name: 'dayHigh'
  },
  dayLow: {
    label: 'Low',
    name: 'dayLow'
  },
  dividendYield: {
    label: "Dividend Yield",
    name: 'dividendYield'
  },
  portfolioRisk: {
    label: 'Portfolio Risk %',
    name: 'portfolioRisk',
    percent: true,
    info: 'Risk of owning an equity portfolio. If the market is strong then use 7%, if the market is weak use 4%.'
  },
  companyRisk: {
    label: 'Company Risk %',
    name: 'companyRisk',
    percent: true,
    info: 'The risk based on the operations and performance of the company. A growing profitable company with stable operations will be 1% and a company with fluctuating or negative performing profits will be 5%.'
  },
  sizeRisk: {
    label: 'Size Risk %',
    name: 'sizeRisk',
    percent: true,
    info: 'Risk a company has based on its size. A large incumbent corporation will have be 1% and a small business will be 5%.'
  },
  industryRisk: {
    label: 'Industry Risk %',
    name: 'industryRisk',
    percent: true,
    info: 'Risk of operating in a certain industry. A stable industry like the grocery industry will be 1% and a risky industry like new technology will be 5%.'
  },
  inventory: {
    label: 'Inventory',
    name: 'inventory'
  },
  longTermDebt: {
    label: 'Long Term Debt',
    name: 'longTermDebt'
  },
  shortTermDebt: {
    label: 'Short Term Debt',
    name: 'shortTermDebt'
  },
  bankIndebtedness: {
    label: 'Bank indebtedness',
    name: 'bankIndebtedness'
  },
  equityAttributableToShareholders: {
    label: 'Equity attributable to shareholders',
    name: 'equityAttributableToShareholders'
  },
  grossMargin: {
    label: 'Gross margin',
    name: 'grossMargin',
  },
  netProfit: {
    label: 'Net profit',
    name: 'netProfit'
  },
  totalAssets: {
    label: 'Total assets',
    name: 'totalAssets'
  }
};

export const models = {
  DDM: {
    label: 'Discount Dividend Model',
    name: 'discount-dividend-model',
    info: "uses the company's dividends to find intrinsic value."
  },
  DCM: {
    label: 'Discount Cashflow Model',
    name: 'discount-cashflow-model',
    info: "uses the company's future cash flows to find intrinsic value."
  },
  CEM: {
    label: 'Capitalized Earnings Model',
    name: 'capitalized-earnings-model',
    info: "uses the company's current earnings to find intrinsic value."
  },
  ABA: {
    label: 'Asset Based Approach',
    name: 'asset-based-approach',
    info: "uses company's net asset value."
  }
}