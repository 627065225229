import styled from 'styled-components';
import { Main } from '../../components/containers';
import { Link } from 'react-router-dom';

const Section = styled(Main.SectionBg)`
  min-height: 100vh;
`;

const Card = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  display: flex;
  text-align: center;
  align-items: stretch;
  overflow: hidden;
  min-height: 500px;
  @media only screen and (max-width: 1024px) {
    form button {
        margin-left: auto;
        margin-right: auto;
    }
  }
  @media only screen and (max-width: 600px) {
    min-height: 200px;
    padding: 0 5%;
    text-align: center;
    form {
      width: auto;
      flex-direction: column;
    }
    form div {
      max-width: 250px;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
    button {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

Card.Content = styled.div`
    width: 100%;
    padding: 5% 0;
`;

Card.Image = styled.div`
  background-image: url(${props => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  width: 60%;
  background-position: center;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`;

Card.Link = styled(Link).attrs({
  target: "_blank",
  rel: "noreferrer"
})`
  color: #366EDA;
  &:hover {
    text-decoration: underline;
  }
`;

const Container = styled.div`
display: flex;
margin-bottom: 1rem;
justify-content: space-between;
flex-wrap: wrap;
div {
    min-width: 166px;
    width: 48%;
};
`;

export { Container, Card, Section };
