import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Footer } from '../../components';
import { Main } from '../../components/containers';
import { H1, Text, StyledLink } from '../../components/elements';

const ErrorPage = () => <>

    <Header />
    <Main>
      <Main.Section>
        <Main.Inner>
          <H1>404 Error</H1>
          <Text dark> Sorry, this page is not found. </Text>
          <Link to="/"><StyledLink>Go Home</StyledLink></Link>
        </Main.Inner>
      </Main.Section>
    </Main>
    <Footer />

</>

export default ErrorPage;
