import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../context/User';
import HeaderNav from './HeaderNav';
import { ArrowRight, User } from '../../assets/svgComponents';
import Header from './style';
import Socials from '../../pages/contact/Socials';

const HeaderMobile = () => {
    const { currentUser } = useAuth();
    const history = useHistory();
  
    function handleClick() {
      const url = currentUser? '/dashboard' : '/login';
      history.push(url);
    }

    return (
    
      <Header.Mobile>
          <Header.Mobile.Button onClick={handleClick}>
            {currentUser
              ? <> <span>Dashboard</span> <ArrowRight white /></>
              : <><User /> <span>Login / Sign Up</span> </> 
            }
          </Header.Mobile.Button>
          <HeaderNav mobile />
          <Header.Mobile.Line />
          <Socials />
      </Header.Mobile>
    );
  };

  export default HeaderMobile;
