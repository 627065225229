import React from 'react';
import TableFrame from '../tableFrame/TableFrame';
import Heading from '../tableFrame/Heading';
import Row from '../tableFrame/Row';
import * as schema from './schema';
import DownloadPDF from '../pdf/DownloadPDF';
import { default as PDF } from './IncomeStatementPDF';

const IncomeStatement = () => <>

  <TableFrame caption="Income statement for last three years">

    <Row item={schema.totalRevenue} bold={true} statement='incomeStatement' />
    <Row item={schema.costOfRevenue} bold={true} statement='incomeStatement' />
    <Row item={schema.grossProfit} bold={true} statement='incomeStatement' />

    <Heading>Operating Expenses</Heading>
    {schema.operatingExpenses.map((item, key) => (
      <Row key={key} item={item} statement='incomeStatement' />
    ))}

    <Heading>Operating Income or Loss</Heading>
    {schema.operatingIncomeOrLoss.map((item, key) => (
      <Row key={key} item={item} statement='incomeStatement' />
    ))}

    <Row item={schema.earningsBeforeITDA} bold={true} statement='incomeStatement' />
  </TableFrame>

  <DownloadPDF pdf={PDF} title={'Income statement'} statement='incomeStatement' />
</>

export default IncomeStatement;
