import styled from 'styled-components';

const Heading = styled.span`
  font-family: 'Roboto', sans-sarif;
  font-size: 1.75rem;
  color: #152536;
  font-weight: 500;
`;

export default Heading;
