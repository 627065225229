import React from 'react';
import HeaderLogin from './HeaderLogin';
import HeaderDefault from './HeaderDefault';
import { default as Styled } from './style';

const Header = ({ loginPage }) => (

    <Styled>
      <Styled.Inner loginPage>

        {loginPage
        ? <HeaderLogin {...loginPage} />           
        : <HeaderDefault />
        }   

      </Styled.Inner>
    </Styled>
);


export default Header;
