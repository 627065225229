import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs } from '../../components';
import { DashboardCard as Card, DashboardMain as Main } from '../../components/containers';
import { Text, Image } from '../../components/elements';
import { publicCompany, privateCompany } from '../../assets/images';

const Landing = () => (

  <Main grey>
    <Main.SectionHalfWhite>
      <Main.Inner>
        <Main.Title>Business Valuation</Main.Title>
        <Breadcrumbs path='Business Valuation' url='/dashboard/business-valuation' />
      
        <Text large dark>
          What kind of company do you want to find the valuation of?
        </Text>
        <Card.Container>
          <Card>
            <Link to="/dashboard/business-valuation/public-companies">
              <Image src={publicCompany} alt="" width="100%" />
              <Card.Text>
                <Card.Title>Public Company</Card.Title>
                <Text small>
                  Find the enterprise value of a publicly listed company easily just
                  by searching it.
                </Text>
              </Card.Text>
            </Link>
          </Card>
          <Card>
            <Link to="/dashboard/business-valuation/private-companies">
              <Image src={privateCompany} alt="" width="100%" />
              <Card.Text>
                <Card.Title>Private Company</Card.Title>
                <Text small>
                  Find the enterprise value of a private company by inputing
                  financials to generate accurate enterprise value.
                </Text>
              </Card.Text>
            </Link>
          </Card>
        </Card.Container>
    
      </Main.Inner>
    </Main.SectionHalfWhite>
  </Main>
)
export default Landing;
