import React from 'react';
import { Logo, ModalInviteFriend } from '..';
import { Home, Financials, News, History } from '../../assets/svgComponents';
import { default as Styled } from './style';

const Aside = () => (
  <Styled>
    <Styled.Logo>
      <Logo white />
    </Styled.Logo>
    <nav>
      <ul>
        <Styled.ListItem>
          <Styled.NavLink to="/dashboard">
            <Styled.Link>
              <Home />
              Dashboard
            </Styled.Link>
          </Styled.NavLink>
        </Styled.ListItem>
        <Styled.ListItem>
          <Styled.NavLink to="/financials">
            <Styled.Link>
              <Financials />
              Financials
            </Styled.Link>
          </Styled.NavLink>
        </Styled.ListItem>
        <Styled.ListItem>
          <Styled.NavLink to="/news">
            <Styled.Link>
              <News />
              News
            </Styled.Link>
          </Styled.NavLink>
        </Styled.ListItem>
        <Styled.ListItem>
          <Styled.NavLink to="/history">
            <Styled.Link>
              <History />
              History
            </Styled.Link>
          </Styled.NavLink>
        </Styled.ListItem>
      </ul>
    </nav>
    <ModalInviteFriend />
  </Styled>
);

export default Aside;
