import React from 'react';
import { HistoryContextProvider } from '../../context';
import HistoryTableHead from './HistoryTableHead';
import HistoryTableBody from './HistoryTableBody';
import HistorySearch from './HistorySearch';
import { Aside, DashboardHeader } from '../../components';
import { DashboardMain as Main } from '../../components/containers';
import { Section, Table } from './style';

const History = () => {

  return (
    <>
      <Aside />

      <DashboardHeader title="History" />

      <Main grey>
        <Main.Inner>
          <Main.Title>History</Main.Title>
        </Main.Inner>
        <HistoryContextProvider>

          <Section>
            <Main.Inner>
              <HistorySearch />
            </Main.Inner>
          </Section>

          <Main.Inner>
            <Table>            
              <Table.Head>
                <HistoryTableHead />
              </Table.Head>  
              <Table.Body>
                <HistoryTableBody />
              </Table.Body>             
            </Table>
          </Main.Inner>

        </HistoryContextProvider>
      </Main>
    </>
  );
};

export default History;
