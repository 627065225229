import React, { createContext, useState, useEffect, useContext } from 'react';
import { requestClient, useRequest, RequestMethods } from '../utils/requests';
import { StorageKeys } from '../utils/storageKeys';
import { parseStringifiedJSON, timestampToDate } from '../utils';

const UserContext = createContext();

const useAuth = _ => useContext(UserContext);

const UserContextProvider = ({ children }) => {
  const requestHandler = useRequest();
  const user = localStorage.getItem(StorageKeys.User);
  const token = localStorage.getItem(StorageKeys.Token);
  const [currentUser, setCurrentUser] = useState(parseStringifiedJSON(user, null));

  useEffect(() => {
    if (!user && token) {
      requestClient.get('/users/me').then(response => {
        setCurrentUser(response?.data);
      });
    }
    if (user && !token) {
      requestClient.post('/login').then(response => {
        setCurrentUser(response?.data?.user);
      });
    }

  }, [token, user]);

  const addToHistory = async company => {
    if (!company.name || !company.symbol) return;

    const today = Date.now();
    const date = timestampToDate(today, 'long');

    try {
      const response = await requestHandler(
        RequestMethods.Patch, 
        '/users/history',
        { company: { ...company, date }, email: currentUser.email }
      );

      setCurrentUser(response?.data?.user);
      localStorage.setItem(StorageKeys.User, JSON.stringify(response?.data?.user));
    } catch (err) {
      console.error(err);
    }
  };

  const deleteFromHistory = async selection => {
      try {
            const response = await requestHandler(
              RequestMethods.Patch,
              '/users/history/delete',
              { selection, email: currentUser.email }
            );
              setCurrentUser(response?.data?.user);
              localStorage.setItem(StorageKeys.User, JSON.stringify(response?.data?.user));
      } catch (err) {
          console.error(err);
      }
  }

  const handleSignOut = async () => {
    await requestHandler(RequestMethods.Post, '/users/logout');
    setCurrentUser();
    localStorage.removeItem(StorageKeys.Token);
    localStorage.removeItem(StorageKeys.User);
  }

  return (
    <UserContext.Provider value={{ 
      currentUser, 
      setCurrentUser, 
      addToHistory, 
      deleteFromHistory,
      handleSignOut
    }}>
      {children}
    </UserContext.Provider>
  );
};

export { useAuth, UserContextProvider };
