import React from 'react';
import { Input, InputSelect } from '../../components';
import { Container, FieldsContainer } from '../../components/containers';
import { info } from '../definitions';

const StockOptionValuation1 = ({ data, handleChange }) => {

  return <>
    <Container bottom='2.5em'>
      <InputSelect
        label="Select the type of options contract"
        name="type"
        options={['Call option', 'Put option']}
        value={data.type}
        required
        onChange={handleChange}
      />
    </Container>

    <FieldsContainer>
      <Input
        {...info.currentStockPrice}
        value={data.currentStockPrice}
        required
        onChange={handleChange}
      />
      <Input
        {...info.exercisePrice}
        value={data.exercisePrice}
        required
        onChange={handleChange}
      />
      <Input
        label="Units Per Contract"
        name="unitsPerContract"
        value={data.unitsPerContract}
        required
        onChange={handleChange}
        step='1'
      />
      <Input
        label="Days until expiry"
        name="daysUntilExpiry"
        value={data.daysUntilExpiry}
        min={1}
        step='1'
        required
        onChange={handleChange}
      />
      <Input
        {...info.riskFreeRate}
        value={data.riskFreeRate}
        min='0'
        max='5'
        step='0.01'
        required
        onChange={handleChange}
      />
      <Input
        label='Price per Option'
        name='pricePerOption'
        value={data.pricePerOption}
        required
        onChange={handleChange}
      />
    </FieldsContainer>
  </>
};

export default StockOptionValuation1;
