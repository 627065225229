import React from 'react'
import GraphSchema from './GraphSchema';
import { optionsDefault, tooltipsDefault, scalesDefault, datasetsDefault } from '../../../utils/graphs';

const options = {
    ...optionsDefault,
    tooltips: tooltipsDefault,
    scales: scalesDefault
}

const Graph6Months = ({ data, setData }) => (
    
    <GraphSchema
        api='prices-daily?months=6'
        label='6 Months'
        data={data}
        setData={setData}
        options={options}
        datasets={datasetsDefault}
    />
)

export default Graph6Months
