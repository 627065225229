import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCompanies, useFinancialStrength, useAuth } from '../../context';
import { FormSchema, CompanyName, ReportNotFound } from '../../components';
import { Label } from '../../components/elements';
import { schema } from './schema';
import { RequestMethods, useRequest } from '../../utils/requests';
import Loading from '../../hoc/loading/Loading';
import { Container } from '../../components/containers';

const FinancialStrengthCompany = ({ setLoading }) => {
  const requestHandler = useRequest();
  const { symbol } = useParams();
  const { data, setData } = useFinancialStrength();
  const { companies } = useCompanies();
  const { addToHistory } = useAuth();

  useEffect(() => {

    const getRequest = async _ => { 
      setLoading(true);
      try {
        const response = await requestHandler(
          RequestMethods.Get,
          `/financial-strength/${symbol}`
        );

        if (!response?.data) throw new Error('No available data.');

        if (!response?.data?.name) {
          const company = companies.find(
            company => company.symbol === symbol
          );
          setData({ 
            name: company.name, 
            exchange: company.exchangeShortName,
            symbol,
            ...response?.data
          })
        } else {
          setData({ symbol, ...response?.data});
        }

        addToHistory({
          symbol,
          name: response?.data?.name,
          exchange: response?.data?.exchange,
          model: 'Financial Strength'
        });

      } catch (err) {
        setData(null);
        console.error(err);
      }
      setLoading(false);
    }

    getRequest();
// eslint-disable-next-line
  }, [symbol, setData]);

  if (!data) return <ReportNotFound />;

  return <>

    <Container top='2rem' bottom='0.5rem'>
      <Label>Company Name</Label>
      <h3><CompanyName name={data.name} symbol={data.symbol} /></h3>
      <Label gray><em>Showing reports for year {data.date}</em></Label>
    </Container>
    <FormSchema schema={schema} data={data} />
  
  </>
};

export default Loading(FinancialStrengthCompany, `Loading company's reports...`);
