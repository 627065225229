import axios from 'axios';
import HttpStatus from 'http-status';
import { useHistory } from 'react-router-dom';
import { StorageKeys } from './storageKeys';

class RequestClient {
  constructor() {
    const token = localStorage.getItem(StorageKeys.Token);

    this.axiosClient = axios.create({
      baseURL: '/api',
      headers: token ? { Authorization: token } : undefined,
    });
  }

  get baseHeaders() {
    const token = localStorage.getItem(StorageKeys.Token);
    return token ? { Authorization: token } : undefined;
  }

  delete(url, config = {}) {
    return this.axiosClient.delete(url, {
      ...config,
      headers: {
        ...this.baseHeaders,
        ...config.headers,
      },
    });
  }

  get(url, config = {}) {
    return this.axiosClient.get(url, {
      ...config,
      headers: {
        ...this.baseHeaders,
        ...config.headers,
      },
    });
  }

  patch(url, body, config = {}) {
    return this.axiosClient.patch(url, body, {
      ...config,
      headers: {
        ...this.baseHeaders,
        ...config.headers,
      },
    });
  }

  post(url, body, config = {}) {
    return this.axiosClient.post(url, body, {
      ...config,
      headers: {
        ...this.baseHeaders,
        ...config.headers,
      },
    });
  }

  put(url, body, config = {}) {
    return this.axiosClient.put(url, body, {
      ...config,
      headers: {
        ...this.baseHeaders,
        ...config.headers,
      },
    });
  }
}

const requestClient = new RequestClient();

/**
 * utility hook that wraps request client methods to provide auth support
 *
 * every request made using this hook will automatically refresh user auth token
 *
 * if an 401 error is thrown by the server, the user will be redirected to the login page automatically
 *
 * EXAMPLE USAGE
 * ```
 * const requestHandler = useRequest()
 *
 * requestHandler(RequestMethods.Get, '/users/me')
 * ```
 * @param {Function} request
 * @param  {...any} options
 * @returns AxiosResponse
 */
const useRequest = () => {
  const history = useHistory();
  
  const makeRequest = async (requestMethod, ...options) => {
    try {
      const response = await requestClient[requestMethod](...options);  
      return response;
    } catch (err) {
      if (err.response.status === HttpStatus.UNAUTHORIZED) {
        localStorage.removeItem(StorageKeys.Token);
        localStorage.removeItem(StorageKeys.User);
        history.push('/login');
        return;
      }
      throw err;
    }
  };

  return makeRequest;
};

const RequestMethods = {
  Delete: 'delete',
  Get: 'get',
  Patch: 'patch',
  Post: 'post',
  Put: 'put',
};

export {
  requestClient,
  useRequest,
  RequestMethods,
};
