import FormFields from './FormFields';
import { requestClient } from '../../utils/requests';
import { StorageKeys } from '../../utils/storageKeys';

export const schema = {
  buttonText: 'Sign Up',
  buttonStyle: { long: true },
  formStyle: { width: 505 },
  formFields: FormFields,
  validation: ({ password, confirmPassword }) => {
    if(password !== confirmPassword) {
      return 'Passwords do not match';
    } else if (password.length < 6) {
      return 'Password must be at least 6 characters long';
    } else if (password.toLowerCase().includes('password')) {
      return "Password cannot contain a word 'password'"
    }
  },
  apiCall: async (formData, setCurrentUser) => {

    try {
      const response = await requestClient.post('/users', formData)
      localStorage.setItem(StorageKeys.User, JSON.stringify(response?.data?.user));
      localStorage.setItem(StorageKeys.Token, `Bearer ${response?.data?.token}`);
      setCurrentUser(response?.data?.user);

      return 'Successfully signed up';     
    } catch (err) {
      console.log(err);
      return null
    }
  },
  default: {
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    acceptTerms: false,
  },
  history: '/dasboard'
};
