import styled from 'styled-components';

const Button = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  background-color: ${props => props.secondary ? '#FFF' : '#366eda'};
  border: 1px solid #366eda;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  font-size: 1em;
  color: ${props => props.secondary ? '#366eda' : '#FFF'};
  padding: 0.5em 1em;
  cursor: pointer;
  min-width: ${props => props.long ? '158px' : '86px'};
  &:hover {
    box-shadow: none;
  }
`;

export default Button;
