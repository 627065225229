import React, { useState } from 'react';
import { Portal } from 'react-portal';
import { BackDrop, Loader } from './style';

const Loading = (WrappedComponent, loadingMessage) => {

    function HOC(props) {
        const [loading, setLoading] = useState(false);

        const setLoadingState = isComponentLoading => {
            setLoading(isComponentLoading);
        };

        return <>

            {loading && 
                <Portal>
                    <BackDrop>
                        <Loader />
                    {loadingMessage}
                    </BackDrop>
                </Portal>
            }
            <WrappedComponent 
                { ...props} 
                setLoading={setLoadingState} 
                loading={loading}
            />
        </>
    }

    return HOC;
}

export default Loading
