import styled from 'styled-components';

const Button = styled.button`
    background-color: transparent;
    border: none;
    padding: 10px 5px;
    position: absolute;
    bottom: 0;
    left: 170px;
    color: #afceed;
`;

export default Button;